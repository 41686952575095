import React, { createRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import siteLogo from "../assets/images/logo.png";
// import darkLogo from "../assets/images/dark-logo.svg";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { forgotPasswordAction } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { toast } from "react-toastify";
import { encryptData } from "../../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";

const ForgotPassword = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      useemailrname: {
        required: true,
        type: "email",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await forgotPasswordAction(formData);
      if (res) {
        if (res && res.user_id) {
          const encObj = {
            user_id: res.user_id,
            email: formData.email,
          };
          const encryptedData = encryptData(encObj);

          if (encryptedData) {
            navigate(`/verify-otp/${encryptedData}`);
          }
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      }
    } else {
      setErrorState(errors);
    }
  };

  return (
    <>
      <div className="signUp-wrapper user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="container">
          <div className="row justify-content-center auth_log_wrapper">
            <div className="col-sm-12 col-lg-7   col-xl-7">
              <form
                action="#"
                className="signUp-form bg-light-theme  pt-5 pb-5  pr-4 pl-4 input-white"
              >
                <h2 className="form-title text-center">
                  {t("forgot_password")}
                </h2>
                <div className="row pb-3">
                  <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center">{errorResponse}</p>
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                    <InputGroupComponent className="form-group pos_rel login_group_wrapper">
                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        placeHolder={t("email")}
                        inputClassName="form-control lgn_input"
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                      />
                      <i className="uil uil-envelope lgn_icon"></i>
                      <InvalidFeedback>{errorState.email}</InvalidFeedback>
                    </InputGroupComponent>
                  </div>
                </div>

                <div className="mb-0 mb-0 text-center">
                  <ButtonComponent
                    className="vs-btn vs-style1 form_button forgot_next"
                    type="submit"
                    text={t("next")}
                    loader={loader}
                    onClick={handleSubmit}
                  />
                </div>
                {/* <div className="row">
                  <div className="col-sm-12 col-lg-12  forgotpassword_btn">
                    <Link className="" to="/login">
                      Login
                    </Link>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
