import { GET_LANGAGES, SET_CURRENT_LANGAGES } from '../type'

const inital = {
    langages: [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
            id: 1
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
            id: 2
        },
    ],
    currentLangage: 1

}

const LangagesReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_LANGAGES:
            return {
                ...state,
                langages: action.payload,
            }
        case SET_CURRENT_LANGAGES:
            return {
                ...state,
                curentLangage: action.payload,
            }

        default:
            return state;
    }
}
export default LangagesReducer