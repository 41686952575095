import flower from "../../../assets/img/appointment/flower.png";
function Orders() {
  return (
    <section
      className="vs-appointment-wrapper vs-appointment-layout1 position-relative space-md-bottom"
      id="contact"
    >
      <div className="shape1 position-absolute ani-moving-y">
        <img src={flower} alt="Flowers" />
      </div>
      <div className="shape2 position-absolute ani-moving-y">
        <img src={flower} alt="Flowers" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.3s">
            <form
              action="#"
              className="appointment-form-style1 input-white bg-light-theme px-60 py-70 mb-30"
            >
              <div className="form-title media mb-20">
                <div className="media-icon mt-1 mr-3 d-none d-lg-inline-block">
                  <span className="icon-btn bg-theme text-white">
                    <i className="text-lg fal fa-calendar-alt"></i>
                  </span>
                </div>
                <div className="media-body">
                  <p className="mb-1">Get relax any day & any Time</p>
                  <h2 className="form-title">Make Appointment</h2>
                </div>
              </div>
              <div className="row gutters-20 ">
                <div className="form-group col-md-6">
                  {/* <input type="text" className="form-control" placeholder="First name"> */}
                </div>
                <div className="form-group col-md-6">
                  {/* <input type="text" className="form-control" placeholder="Last name"> */}
                </div>

                <div className="form-group col-md-12 col-xl-12">
                  {/* <input type="text" className="form-control dateTime-pick" placeholder="Select Date & Time"> */}
                  <i className="fal fa-calendar-alt"></i>
                </div>
                <div className="form-group col-12 mb-0 text-center">
                  <button type="submit" className="vs-btn wave-style1">
                    Make Appointment<i className="far fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Orders;
