import roseImage from "../../../assets/img/banner/rose.png";
import SliderSlick from "react-slick";
import { t } from "i18next";
import { Link } from "react-router-dom";

function SliderComponent2(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <>
      <section className="vs-hero-wrapper vs-hero-layout1 bg-light-theme slider_wrapper ">
        <div className="container-data container">
          <div className="row">
            <div className="col-sm-12 slider_col">
              <div className="vs-hero-carousel">
                <SliderSlick {...settings}>
                  {props.banner.length &&
                    props.banner.map((ban, i) => {
                      return (
                        <div className="slider_wrapper" id="container-header">
                          <div className="row ">
                            <div className="col-md-12 col-lg-4 p-4 slider-app-c banner_text">
                              <h5 className="ls-layer  slider-title">
                                {" "}
                                {ban.title}
                              </h5>

                              <div className="hero-btn ls-l c-btn-make-appointment slider-btn">
                                <div className="service-category btn-make-appointment">
                                  <Link
                                    to={"/booking"}
                                    state={{ from: "/booking" }}
                                  >
                                    <span className="vs-btn-text">
                                      {" "}
                                      {t("make_an_appointment")}
                                    </span>
                                    <span className="vs-btn-icon">
                                      <i className="far fa-arrow-right"></i>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-8 position-relative banner_img_wrapper">
                              <img
                                src={
                                  process.env.REACT_APP_API_BASIC_URL +
                                  "/" +
                                  ban.image
                                }
                                className="banner_img"
                                alt="Spa Girl"
                              />
                              <img
                                className="ls-l rose-img-1"
                                src={roseImage}
                                alt="Rose Icons"
                                style={{ width: "60px", height: "60px" }}
                              />

                              <img
                                className="ls-l rose-img-2"
                                src={roseImage}
                                alt="Rose Icons"
                                style={{ width: "60px", height: "60px" }}
                              />
                              <div className="ls-l hide-i hide-m w-hours-content d-none">
                                <p className="w-hours-txt">Working Hours</p>

                                <p className="w-hours-icon">
                                  <i className="fa-3x fal fa-clock"></i>
                                </p>
                                <table className="table-working-hours">
                                  <tbody>
                                    {props.workingHours.length &&
                                      props.workingHours.map((work, j) => {
                                        return (
                                          <tr className="" key={j}>
                                            <td>{work.day}</td>
                                            <td>{work.hours}</td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            key={i}
                            className="slider"
                            style={{
                              height: "1000px",
                              width: "1000px",
                              minHeight: "800px",
                              position: "relative",
                            }}
                          >
                            <div className="position-relative">
                              <div
                                className="ls-l bg-theme c-img-slider"
                                style={{
                                  top: "-130px",
                                  opacity: "0.1",
                                  borderRadius: "50%",
                                }}
                              ></div>
                              <div
                                className="ls-layer"
                                style={{
                                  top: "0",
                                  right: "0",
                                  zIndex: "1",
                                  width: "600px",
                                  height: "600px",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASIC_URL +
                                    "/" +
                                    ban.image
                                  }
                                  className="ls-l img-slider"
                                  alt="Spa Girl"
                                  style={{
                                    top: "100px",
                                    zIndex: "1",
                                    width: "600px",
                                    height: "600px",
                                  }}
                                />
                              </div>
                            </div>
                            <h5
                              style={{
                                margin: "0px",
                                fontSize: "30px",
                                width: "358px",
                                textAlign: "left",
                                whiteSpace: "normal",
                              }}
                              className="ls-layer hide-i hide-m banner-title font-b"
                            >
                              {" "}
                              {ban.title}
                            </h5>

                            <div
                              className="hero-btn ls-l hide-i hide-m c-btn-make-appointment"
                              style={{
                                width: "350px",
                                fontSize: "14px",
                                padding: "7px",
                                paddingLeft: "40px",
                                overflow: "hidden",
                              }}
                            >
                              <div className="service-category btn-make-appointment">
                                <a href="#">Make Appointment</a>
                              </div>
                            </div>

                            <div
                              className="bg-theme hero-box1 ls-l hide-i hide-m c-w-hours"
                              style={{
                                width: "180px",
                                height: "210px",
                                zIndex: "2",
                              }}
                            ></div>
                            <div
                              className="bg-theme hero-box1 ls-l c-w-hours-m"
                              style={{ zIndex: "5" }}
                            >
                              <p
                                className="ls-l text-font2 d-inline-block font-l"
                                style={{
                                  color: "#ffffff",
                                  width: "90px",
                                  textAlign: "left",
                                  zIndex: "3",
                                }}
                              >
                                Working Hours
                              </p>
                              <i
                                style={{ color: "#ffffff" }}
                                className="fa-4x fal fa-clock"
                              ></i>
                            </div>
                            <img
                              className="ls-l rose-img-1"
                              src={roseImage}
                              alt="Rose Icons"
                              style={{ width: "60px", height: "60px" }}
                            />

                            <img
                              className="ls-l rose-img-2"
                              src={roseImage}
                              alt="Rose Icons"
                              style={{ width: "60px", height: "60px" }}
                            />

                            <div
                              className="ls-l hide-i hide-m w-hours-content"
                              style={{ width: "180px", zIndex: "3" }}
                            >
                              <p
                                className="ls-l text-font2 d-inline-block hide-i hide-m w-hours-txt"
                                style={{
                                  fontSize: "19px",
                                  whiteSpace: "normal",
                                  color: "#ffffff",
                                  width: "90px",
                                  textAlign: "left",
                                  zIndex: "3",
                                  lineHeight: "20px",
                                }}
                              >
                                Working Hours
                              </p>

                              <p
                                className="ls-l text-font2 d-inline-block hide-i hide-m w-hours-icon"
                                style={{
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                  color: "#ffffff",
                                  width: "75px",
                                  textAlign: "left",
                                  zIndex: "3",
                                  lineHeight: "25px",
                                }}
                              >
                                <i className="fa-3x fal fa-clock"></i>
                              </p>
                              <table className="table-working-hours">
                                <tbody>
                                  {props.workingHours.length &&
                                    props.workingHours.map((work, j) => {
                                      return (
                                        <tr
                                          style={{
                                            fontSize: "11px",
                                            color: "#ffffff",
                                            width: "180px",
                                            zIndex: "3",
                                          }}
                                          className=""
                                          key={j}
                                        >
                                          <td>{work.day}</td>
                                          <td>{work.hours}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div> */}
                        </div>
                      );
                    })}
                </SliderSlick>
                {/* <div className="custom-div hide-l show-m">
                  <div className="container background-white">
                    <h5 className="ls-layer banner-title-m banner-title-m-h font-b">
                      {" "}
                      {props.banner[0].title} with
                    </h5>

                    <h3 className="ls-layer banner-logo-text-m font-b">
                      Hand and Stone Spa
                    </h3>

                    <div className="form-group col-12 mb-0 text-center c-btn-make-appointment-m mt-5 mb-5">
                      <a
                        href={"/booking"}
                        // type="submit"
                        className="vs-btn wave-style1 btn-make-appointment-m shadow-box"
                      >
                        <span className="vs-btn-text">Make Appointment</span>
                        <span className="vs-btn-icon">
                          <i className="far fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SliderComponent2;
