import { useState, useEffect, createRef } from "react";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import Preloader from "../../layout/preloader/Preloader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getUserSession } from "../../../auth";
import logoutIcon from "../../../assets/img/account/logout-icn.png";
import { useTranslation } from "react-i18next";
import AppointmentAccount from "./AppointmentAccount";
import MyCartAccount from "./MyCartAccount";
import MyOrdersAccount from "./MyOrdersAccount";
import DetailsAccount from "./DetailsAccount";
import useGetDataPrivate from "../../..//hooks/useGetDataPrivate";

import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
} from "../../../auth";

import i18next, { t } from "i18next";
function Profile() {
  const userSession = getUserSession();
  // const [isLoading, setIsLoading] = useState(false);
  const [userType, SetType] = useState(0);
  const [userdata, SetUserdata] = useState({});
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  let tab = location.state?.tab || "";
  const [tabIndex, setTabIndex] = useState(tab ? tab : 0);
  const { error, isLoad, data } = useGetDataPrivate("profile");
  const updateUser = (dat) => {
    SetUserdata(dat);
  };
  useEffect(() => {
    SetType(userSession.userType);
    if (!isAuthenticated()) {
      //navigate("/login");
      navigate("/login", {
        replace: true,
        state: {
          from,
          tab,
        },
      });
    }
  }, [isLoad, userSession]);
  useEffect(() => {
    SetUserdata(data);
  }, [isLoad, data]);

  return (
    <>
      {isLoad ? (
        <Preloader />
      ) : (
        <>
          <section className="vs-about-wrapper vs-about-layout1 position-relative space space-services-m hide-m hide-i my_account_wrapper">
            <div className="container">
              <div className="row mt-5 mb-4">
                <div className="col-md-3 font-b myaccount_name">
                  {userdata && userdata.firstName && userdata.lastName
                    ? userdata.firstName + " " + userdata.lastName
                    : ""}
                </div>
                <div className="col-md-9 text-right myaccount_logout">
                  <Link
                    className="vs-btn vs-style1 btn-book-now-service pr-0 pl-0 align-self-center logout-btn-account"
                    to="/logout"
                  >
                    <img
                      className="vs-btn-text icon-logout-btn d-inline-block"
                      width="25px"
                      src={logoutIcon}
                    />
                    <span className="vs-btn-text text-logout-btn d-inline-block">
                      {/* <NavLink to="/logout">{t("logout")}</NavLink> */}
                      {t("logout")}
                    </span>
                  </Link>
                </div>
              </div>
              <Tabs
                className=""
                focusTabOnClick={false}
                selectedIndex={userType == 2 ? 2 : tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList className="nav tabs-style1 tab-has-arrow">
                  <Tab
                    disabled={userType == 2}
                    className="col-md-3 service-nav "
                  >
                    <a
                      className={
                        "w-100 d-flex justify-content-center --" + userType
                      }
                    >
                      {t("my_appointment")}
                    </a>
                  </Tab>
                  <Tab
                    disabled={userType == 2}
                    className="col-md-3 service-nav"
                  >
                    <a className={"w-100 d-flex justify-content-center"}>
                      {t("my_orders")}
                    </a>
                  </Tab>
                  <Tab className="col-md-3 service-nav">
                    <a className="w-100 d-flex justify-content-center">
                      {t("my_cart")}
                    </a>
                  </Tab>
                  <Tab
                    disabled={userType == 2}
                    className="col-md-3 service-nav"
                  >
                    <a className="w-100 d-flex justify-content-center">
                      {t("my_account")}
                    </a>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative service-tab-container-1`}
                  >
                    <AppointmentAccount />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative service-tab-container-2`}
                  >
                    <MyOrdersAccount />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative mycart_container service-tab-container-3`}
                  >
                    <MyCartAccount />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className={` mt-5 d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative service-tab-container-4`}
                  >
                    <DetailsAccount
                      accountDetails={userdata}
                      error={error}
                      isLoading={isLoad}
                      updateUser={updateUser}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </section>
          <section className="vs-about-wrapper vs-about-layout1 position-relative space space-services-m hide-l show-m profile_data">
            <div className="container">
              <Accordion>
                <AccordionItem className="accordion-item ">
                  {({ open }) => (
                    <>
                      <AccordionHeader
                        as={"div"}
                        className={`accordion-header accordion-header-account ${
                          open || tab == 1 ? "accordion-header-active" : ""
                        }`}
                      >
                        <div className="accordion-title accordion-title-account font-b p-4">
                          <span className="ml-30">My Appointment</span>
                        </div>
                      </AccordionHeader>

                      <AccordionBody>
                        <div className="accordion-body">
                          <div
                            className={`d-flex pt-5 pb-5 service-tab-container position-relative service-tab-container-2 mt-5 mb-4 service-tab-container-m-a`}
                          >
                            <AppointmentAccount />
                          </div>
                        </div>
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem className="accordion-item">
                  {({ open }) => (
                    <>
                      <AccordionHeader
                        as={"div"}
                        className={`accordion-header accordion-header-account ${
                          open || tab == 0 ? "accordion-header-active" : ""
                        }`}
                      >
                        <div className="accordion-title accordion-title-account font-b p-4">
                          <span className="ml-30">My Orders</span>
                        </div>
                      </AccordionHeader>

                      <AccordionBody>
                        <div className="accordion-body">
                          <div
                            className={`d-flex pt-5 pb-5 service-tab-container position-relative service-tab-container-4 mt-5 mb-4 service-tab-container-m-a`}
                          >
                            <MyOrdersAccount />
                          </div>
                        </div>
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem className="accordion-item">
                  {({ open }) => (
                    <>
                      <AccordionHeader
                        as={"div"}
                        className={`accordion-header accordion-header-account ${
                          open || tab == 2 ? "accordion-header-active" : ""
                        }`}
                      >
                        <div className="accordion-title accordion-title-account font-b p-4">
                          <span className="ml-30">My Cart</span>
                        </div>
                      </AccordionHeader>
                      <AccordionBody>
                        <div className="accordion-body">
                          <div
                            className={`d-flex pt-5 pb-5 service-tab-container position-relative service-tab-container-4 mt-5 mb-4 service-tab-container-m-a`}
                          >
                            <MyCartAccount />
                          </div>
                        </div>
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem className="accordion-item">
                  {({ open }) => (
                    <>
                      <AccordionHeader
                        as={"div"}
                        className={`accordion-header accordion-header-account ${
                          open || tab == 3 ? "accordion-header-active" : ""
                        }`}
                      >
                        <div className="accordion-title accordion-title-account font-b p-4">
                          <span className="ml-30">Account</span>
                        </div>
                      </AccordionHeader>

                      <AccordionBody>
                        <div className="accordion-body">
                          <div
                            className={`d-flex pt-5 pb-5  service-tab-container position-relative service-tab-container-4 mt-5 mb-4 service-tab-container-m-a`}
                          >
                            <DetailsAccount />
                          </div>
                        </div>
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </div>
          </section>
        </>
      )}
    </>
  );
}
export default Profile;
