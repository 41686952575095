const sampleTypes = [
  "email",
  "mobile",
  "email|mobile",
  "number",
  "text",
  "alpha|numeric",
];

const sampleOptions = {
  userName: {
    required: true,
    type: "email|mobile",
    min: "",
    max: "",
  },
  password: {
    type: "password",
    required: true,
    min: "6",
    max: "16",
  },
  confirmPassword: {
    required: true,
    isEqualToField: "password",
  },
};

const sampleData = {
  userName: "aliashik@wakralab.com",
  password: "ABC123456",
};

export const validateForm = (options = {}, data = {}) => {
  const errors = {};

  data &&
    Object.keys(data).forEach((key, index) => {
      if (options[key]) {
        let errorMsg = "";

        errorMsg = validate(data[key], options[key]);

        errors[key] = errorMsg;
        //check is equal
        if (options[key] && options[key].isEqualToField) {
          if (data[key] !== data[options[key].isEqualToField]) {
            errors[key] = "Mismatch";
          }
        }
      }
    });

  for (let key in errors) {
    if (errors[key] !== null && errors[key] !== "")
      return { isValid: false, errors };
  }
  return { isValid: true, errors };
};

const validate = (item, rules) => {
  if (rules.required == true) {
    if (!item) {
      return "This field is required";
    }
  }
  if (rules.type) {
    switch (rules.type) {
      case "email":
        if (!validateEmail(item)) {
          return "Please enter a valid email";
        }

        break;
      case "mobile":
        if (!validateMobile(item)) {
          return "Please enter a valid phone number";
        }

        break;
      case "email|mobile":
        if (!validateMobile(item) && !validateEmail(item)) {
          return "Please enter a valid input";
        }

        break;
      case "password":
        if (!validatePassword(item)) {
          return "Please enter a valid password (Minimum 6 and maximum 20 characters, alpha numeric)";
        }

        break;
      case "number":
        if (!validateNumber(item)) {
          return "Please enter a valid number";
        }

        break;
      case "confirmpassword":
        if (!validateNumber(item)) {
          return "Please enter a valid number";
        }

        break;
      // case "text":
      //   if (!item) return "This field is required";

      //   break;
      // case "textarea":
      //   if (!item) return "This field is required";

      //   break;
      case "alpha|numeric":
        if (!validateAlphaNumeric(item)) {
          return "Invalid entry. (must be alpha-numeric)";
        }
        break;

      default:
        break;
    }
    return "";
  }
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const validateMobile = (number) => {
  const re = /^[0-9]{1,9}$/;
  return re.test(number);
};
const validatePassword = (text) => {
  const re = /^[a-zA-Z0-9!@#$%^&*=]{6,20}$/;
  return re.test(text);
};
const validateAlphaNumeric = (text) => {
  const re = /^[a-z0-9]+$/i;
  return re.test(text);
};
const validateNumber = (num) => {
  const re = /^-?\d+\.?\d*$/;
  return re.test(num);
};
