import React, { createRef, useEffect } from "react";
import logo from "../../../assets/img/logo/logoHandStone.png";
import useGetData from "../../../hooks/useGetData";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllWorkingHours } from "../../../redux/actions/workingHoursAction";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
import { validateForm } from "../../../services/FormValidation";
import { publicInstance } from "../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";

const Footer = () => {
  const dispatch = useDispatch();
  // const inputRef = createRef();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllWorkingHours(projectLanguage.id));
  }, [projectLanguage]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");

  const [loader, setLoader] = useState(false);
  const workingHours = useSelector(
    (state) => state.allWorkingHours.working_hours
  );
  // const [myLanguage, setMyLanguage] = useState({});

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    // inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      name: {
        required: true,
        type: "text",
      },
      email: {
        required: true,
        type: "email",
      },
      phone: {
        required: true,
        type: "text",
      },
      comment: {
        required: false,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      return await publicInstance
        .post("add-review", formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setFormData({
              ...formData,
              email: "",
              phone: "",
              comment: "",
            });

            return res.data;
          } else {
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    } else {
      setErrorState(errors);
    }
  };
  const { error, isLoading, data } = useGetData(
    "footer-data",
    projectLanguage.id
  );
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <footer className="footer-wrapper footer-layout1 ">
          <div className="container user-select-none">
            <div className="footer-widget footer-widget-layout1 pt-100 pb-70">
              <div className="row justify-content-xl-center ">
                <div className="col-sm-12 col-lg-4 col-xl-4 col-md-4 c-footer col-12 mb-sm-3 footer_logo">
                  <div className="  vs-widget-contact pl-0">
                    <div className="vs-widget-about">
                      <div className="widget-about-logo mb-20 mb-lg-25">
                        <NavLink end to="/">
                          <img src={logo} alt="Hand & Stone SPA" />
                        </NavLink>
                      </div>
                      <p className="widget-about-text mb-20 font-l ">
                        {data.footerData.description}
                      </p>
                      <div className="social-links text-lg-right d-lg-inline-block">
                        <ul className=" float-left mt-3">
                          <li className="mr-3">
                            <a href={data.footerData.facebook} target="new">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="mr-3">
                            <a target="new" href={data.footerData.instagramm}>
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12 c-footer">
                  <div className="vs-widget-contact d-flex justify-content-center flex-column text-center">
                    <div className="vs-widget-contact-content">
                      <h3 className="mb-15 font-b text-left-m">
                        {t("working_hours")}
                      </h3>
                      <table className="table-footer text-left-m">
                        <tbody>
                          {workingHours.map((workHour, i) => {
                            return (
                              <tr key={i}>
                                <td>{workHour.day}</td>
                                <td>{workHour.hours}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12">
                  <div className="vs-widget-contact pr-0">
                    <h3 className="font-b"> {t("share_experience")}</h3>
                    <div className="vs-widget-contact-content">
                      <form className="" onSubmit={handleSubmit}>
                        <InputGroupComponent className="form-group  footer_feedback_form_item">
                          <div className="row">
                            <div className="col-3">
                              <label className="label-share-exp">
                                {t("name")}:
                              </label>
                            </div>
                            <div className="col-auto flex-grow-1">
                              <InputComponent
                                // inputRef={inputRef}
                                id="name"
                                name="name"
                                type="text"
                                // placeHolder="Email"
                                inputClassName="input-share-exp"
                                isInvalid={errorState.name ? true : false}
                                onChange={handleInputChange}
                                value={formData.name}
                              />
                            </div>
                          </div>
                          {/* <i className='uil uil-mobile-android-alt lgn_icon'></i> */}
                          <InvalidFeedback>{errorState.name}</InvalidFeedback>
                        </InputGroupComponent>
                        <InputGroupComponent className="form-group  footer_feedback_form_item">
                          <div className="row">
                            <div className="col-3">
                              <label className="label-share-exp">
                                {t("email")}:
                              </label>
                            </div>
                            <div className="col-auto flex-grow-1">
                              <InputComponent
                                // inputRef={inputRef}
                                id="email"
                                name="email"
                                type="email"
                                // placeHolder="Email"
                                inputClassName="input-share-exp"
                                isInvalid={errorState.email ? true : false}
                                onChange={handleInputChange}
                                value={formData.email}
                              />
                            </div>
                          </div>
                          {/* <i className='uil uil-mobile-android-alt lgn_icon'></i> */}
                          <InvalidFeedback>{errorState.email}</InvalidFeedback>
                        </InputGroupComponent>

                        <InputGroupComponent className="form-group   footer_feedback_form_item">
                          <div className="row">
                            <div className="col-3">
                              <label className="label-share-exp">
                                {t("phone")}:
                              </label>
                            </div>
                            <div className="col-auto flex-grow-1">
                              <InputComponent
                                // inputRef={inputRef}
                                id="phone"
                                name="phone"
                                type="text"
                                // placeHolder="Email"
                                inputClassName="input-share-exp"
                                isInvalid={errorState.phone ? true : false}
                                onChange={handleInputChange}
                                value={formData.phone}
                              />
                            </div>
                          </div>
                          <InvalidFeedback>{errorState.phone}</InvalidFeedback>
                        </InputGroupComponent>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-3">
                              <label className="label-share-exp">
                                {" "}
                                {t("message")}:
                              </label>
                            </div>
                            <div className="col-auto flex-grow-1">
                              <textarea
                                className="input-share-exp"
                                // placeholder="Write Message"
                                value={formData.comment}
                                isInvalid={errorState.comment ? true : false}
                                rows="3"
                                name={"comment"}
                                onChange={handleInputChange}
                                style={{ minHeight: "90px" }}
                              />
                            </div>
                          </div>
                          <InvalidFeedback>
                            {errorState.comment}
                          </InvalidFeedback>
                        </div>

                        <div className="blog-category ">
                          {/* <a href="#" className="btn-send float-right">
                            Send
                          </a> */}
                          <ButtonComponent
                            className=" btn-send float-right"
                            type="submit"
                            text={t("send")}
                          />
                        </div>
                        <div className="clear-both"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container user-select-none">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="widget-about-text text-left">
                  Made with🤍 since 2019 . Contabo
                </h4>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
