import { useTranslation } from "react-i18next";

export const OrderItem = (props) => {
  const {
    availability,
    duplicate,
    id,
    title,
    items,
    delLoader,
    countLoader,
    updateCount,
    quantity,
    product_id,
    maximumOrderQuantity,
    price,
    deleteOrderItem,
    date,
    booking_slot,
    type,
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={"cart-item " + (availability === 0 ? "not_available" : "")}
      key={id}
    >
      <div className="cart-text">
        <div className="cart-heading">
          <h4>{title}</h4>
          {availability === 0 ? (
            <p className="product_not_stock">{t("item_not_available_now")}</p>
          ) : (
            ""
          )}
          {duplicate === 0 ? (
            <p className="product_not_stock">{t("item_duplicated")}</p>
          ) : (
            ""
          )}
          {items && items.length > 0 ? (
            <ul className="kggrm-now checkout-atr-list">
              {items.map(({ title }, idx) => (
                <li key={`${title}_${idx}`}>{title}</li>
              ))}
            </ul>
          ) : (
            ""
          )}
          {type && type == 2 ? (
            <ul>
              <li>
                {t("date")} : {date}
              </li>
              <li>
                {t("time")} : {booking_slot}
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
        <div className="qty-group">
          <div className="quantity buttons_added">
            <input
              type="button"
              value="-"
              className="minus minus-btn"
              onClick={() =>
                updateCount({
                  id,
                  product_id,
                  quantity: quantity - 1,
                })
              }
              disabled={quantity <= 1}
            />
            {countLoader === id ? (
              <span className="spinner-border" />
            ) : (
              <input
                type="number"
                step="1"
                name="quantity"
                readOnly
                value={quantity}
                className="input-text qty text"
              />
              // <p className="input-text qty text">{quantity}</p>
            )}
            <input
              type="button"
              value="+"
              className="plus plus-btn"
              onClick={() =>
                updateCount({
                  id,
                  product_id,
                  quantity: quantity + 1,
                })
              }
              disabled={quantity >= maximumOrderQuantity}
            />
          </div>
        </div>
        <div className="cart-item-price">
          <>{price} QAR</>
        </div>
        <button
          onClick={() => deleteOrderItem({ id, product_id, quantity })}
          type="button"
          className="cart-close-btn"
        >
          {delLoader === id ? "x" : "x"}
        </button>
      </div>
    </div>
  );
};
