import logo from "../../../assets/img/logo/logoHandStone.png";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
function Preloader() {
  return (
    <div className="preloader">
      {/* <button className="vs-btn vs-style1 preloaderCls">Cancel Preloader </button> */}
      <div className="preloader-inner">
        <div className="loader-logo">
          <img src={logo} alt="Hand & Stone SPA logo" />
        </div>
        <div className="preloader-box">
          {/* <div className="letter">L</div>
                <div className="letter">O</div>
                <div className="letter">A</div>
                <div className="letter">D</div>
                <div className="letter">I</div>
                <div className="letter">N</div>
                <div className="letter">G</div> */}
          <img src={spinnerImg} alt="Hand & Stone SPA Loader" />
        </div>
      </div>
    </div>
  );
}

export default Preloader;
