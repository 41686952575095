import logo from "../../../assets/img/logo/logoHandStone.png";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Router from "../../../routes";
import env from "react-dotenv";
import { getUserSession } from "../../../auth";
import HeaderUserDropDown from "./HeaderUserDropDown";
import MobileMenuDropDown from "./MobileMenuDropDown";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { langagesAction } from "../../../redux/actions/langagesAction";
import { toggleCartAction } from "../../../redux/actions/uiActions";
import CartDrawer from "../../pages/cart/CartDrawer";
import Cart from "../../pages/cart/Cart";

import {
  deleteCart,
  getCartItems,
  modifyCart,
  updateCart,
} from "../../../redux/actions/cartActions";
function Header() {
  const dispatch = useDispatch();

  const languages = useSelector((state) => state.allLangages.langages);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const inactiveLanguage = languages.find(
    (l) => l.code !== currentLanguageCode
  );

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const [countState, setCount] = useState(0);
  const [myLanguage, setMylanguage] = useState({});
  const [cartData, setCartData] = useState({});
  const userSession = getUserSession();
  useEffect(() => {
    if (userSession && userSession.authToken) {
      dispatch(getCartItems(currentLanguage.id));
    }
  }, [currentLanguage]);
  const handleCartToggle = (e) => {
    e.preventDefault();
    dispatch(toggleCartAction());
  };
  const myCartData =
    useSelector((state) => state.cartReducer.result.value) || {};
  const { cartItems } = myCartData;
  let cartCount = 0;
  if (cartItems) {
    cartCount = cartItems.length;
  }
  useEffect(() => {
    setCount(cartCount);
  }, [cartCount]);
  useEffect(() => {
    if (myCartData) {
      setCartData(myCartData);
    }
  }, [myCartData]);
  useEffect(() => {
    setMylanguage(currentLanguage);
  }, [currentLanguage]);
  // }, [myCartData, cartCount]);

  const toggleLangage = () => {
    i18next.changeLanguage(inactiveLanguage.code);
    const currentLanguage = languages.find(
      (l) => l.code === cookies.get("i18next")
    );
    // setMylanguage(currentLanguage.id);
    dispatch(langagesAction(currentLanguage.id));
    document.title = t("handstone_title");
    document.body.dir = currentLanguage.id == 2 ? "rtl" : "ltr";
    console.log(myLanguage);
    // window.location.reload(false);
  };
  const handleOpenDropDownProfile = () => {
    setOpen(!open);
  };

  const toggleMenuMobile = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  const handleCloseMenuMobile = () => {
    setOpenMobileMenu(false);
  };

  const handleCloseDropDownProfile = () => {
    setOpen(false);
  };
  //console.log("Session Is Set " + userSession.authToken);

  return (
    <>
      <Router />

      <div className="sticky-header-wrap sticky-header py-1 py-sm-2 py-lg-1">
        <div className="container position-relative">
          <div className="row align-items-center d-flex">
            <div className="col-5 col-md-3 col-sm-3">
              <div className="logo ">
                <NavLink end to="/">
                  <img src={logo} alt="Hand and Stone SPA" />
                </NavLink>
              </div>
            </div>
            <div className="col-7 col-md-9 text-right position-static">
              <nav className="main-menu menu-sticky1 d-none d-lg-block link-inherit mobile-menu-active">
                <ul>
                  <li className="nav-menu">
                    <NavLink end to="/">
                      {t("home")}
                    </NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/aboutus">{t("about_us")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/services">{t("services")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/gifts">{t("gifts")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    {/* <NavLink to="/products-we-use">
                      {t("products_we_use")}
                    </NavLink> */}
                    <NavLink to="/booking">{t("booking")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/contact">{t("contact")}</NavLink>
                  </li>
                </ul>
              </nav>
              <MobileMenuDropDown
                isLoggedIn={userSession && userSession.authToken ? true : false}
                email={(userSession && userSession.email) || ""}
                userName={(userSession && userSession.firstName) || ""}
                isGuest={
                  (userSession && userSession.userType) === 2 ? true : false
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"header_cart order-1"}>
        <a
          href="/"
          className="cart__btn hover-btn pull-bs-canvas-left"
          title="Cart"
          onClick={handleCartToggle}
        >
          <span className="fa fa-angle-left"></span>
          <span>
            {t("my_cart")} - {countState + " "}
          </span>
          {/* <img src={cartIcon} alt="cart" /> */}
          {/* <span className="noti_count2"> - {countState + " "} </span> */}
        </a>
      </div>
      {/* <CartDrawer
        handleCartToggle={handleCartToggle}
        cartCount={countState}
        data={cartData}
      /> */}
      <Cart />

      <header className="header-wrapper header-layout1 position-absolute py-3 py-lg-0">
        <div className="container-fluid container-header position-relative">
          <div className="row align-items-center mt-3  justify-content-between">
            <div className="col-6 col-lg-2 pt-3">
              <div className="header-logo">
                <NavLink end to="/">
                  <img
                    src={logo}
                    title="Hand & Stone - Spa Beauty"
                    alt="Hand & Stone - Spa Beauty"
                  />
                </NavLink>
              </div>
            </div>
            <div className="col-lg-7 position-static text-right d-lg-block d-none">
              <nav className="main-menu menu-style2">
                <ul>
                  <li className="nav-menu">
                    <NavLink end to="/">
                      {t("home")}
                    </NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/aboutus">{t("about_us")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/services">{t("services")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink
                      state={{
                        from: "/gifts",
                      }}
                      to="/gifts"
                    >
                      {t("gifts")}
                    </NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/booking">{t("booking")}</NavLink>
                  </li>
                  <li className="nav-menu">
                    <NavLink to="/contact">{t("contact")}</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-2 col-lg-3  d-lg-block d-none pr-4">
              <div className="d-flex c-social-login pr-1">
                <div className="header-btn text-right">
                  <a href="#" className="">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="">
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
                <div className="d-flex log-lng-c">
                  <div className="">
                    <HeaderUserDropDown
                      isLoggedIn={
                        userSession && userSession.authToken ? true : false
                      }
                      email={(userSession && userSession.email) || ""}
                      userName={(userSession && userSession.firstName) || ""}
                      isGuest={
                        (userSession && userSession.userType) === 2
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="h-line"></div>
                  <div className="ml-3 language_wrapper">
                    <div onClick={handleCloseDropDownProfile} className="">
                      <a
                        onClick={toggleLangage}
                        className="pink-pointer font-ar lang-menu"
                      >
                        {inactiveLanguage.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 d-block d-lg-none text-right mobile-menu-container pl-0 pr-0 menu_wrapping">
              <div onClick={handleCloseDropDownProfile} className="lng-m">
                <a
                  onClick={toggleLangage}
                  className="pink-pointer font-ar lang-menu"
                >
                  {inactiveLanguage.name}
                </a>
              </div>
              <div className="d-inline-block mb-0  d-inline-block mb-0 d-lg-none  hide-m hide-i">
                <HeaderUserDropDown
                  isLoggedIn={
                    userSession && userSession.authToken ? true : false
                  }
                  email={(userSession && userSession.email) || ""}
                  userName={(userSession && userSession.firstName) || ""}
                  isGuest={
                    (userSession && userSession.userType) === 2 ? true : false
                  }
                />
              </div>

              <div className="text-right c-social-login d-inline-block mb-0 c-social-login-m d-block d-lg-none w-100">
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-instagram ml-2"></i>
                </a>
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-facebook-f ml-2"></i>
                </a>
                <a href="#" className="ml-1 mr-1">
                  <i className="fab fa-twitter ml-2"></i>
                </a>
                <MobileMenuDropDown
                  isLoggedIn={
                    userSession && userSession.authToken ? true : false
                  }
                  email={(userSession && userSession.email) || ""}
                  userName={(userSession && userSession.firstName) || ""}
                  isGuest={
                    (userSession && userSession.userType) === 2 ? true : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
