import { SET_CURRENT_LANGAGES , GET_ERROR } from '../type'

export const langagesAction = (idLang) => async (dispatch) => {
    try {
        dispatch({
            type: SET_CURRENT_LANGAGES,
            payload: idLang
        })
        

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
   
}


