import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { trimStringSmall,trimStringExtraSmall } from "../helpers/helperFunctions";
import {
  trimStringSmall,
  trimStringExtraSmall,
} from "../../../helpers/helperFunctions";
import i18next from "i18next";

const HeaderUserDropDown = ({ isLoggedIn, userName, isGuest, email }) => {
  const { t } = useTranslation();
  const [userMenuExpand, setUserMenuExpand] = useState(false);
  const authMenu = [
    {
      tab: 3,
      name: t("my_account"),
      url: "/profile",
      icon: "far fa-user",
    },
    {
      tab: 1,
      name: t("my_orders"),
      url: "/profile",
      icon: "far fa-shopping-bag",
    },
    {
      tab: 0,
      name: t("appointments"),
      url: "/profile",
      icon: "far fa-calendar-check",
    },
  ];
  const unAuthMenu = [
    {
      tab: 0,
      name: t("login"),
      url: "/login",
      icon: "far fa-user",
    },
    {
      tab: 0,
      name: t("register"),
      url: "/register",
      icon: "far fa-user",
    },
    // {
    //   name: "Faq",
    //   url: "/faq",
    //   icon: "uil-info-circle icon__1",
    // },
  ];

  const guestMenu = [
    // {
    //   name: "Logout",
    //   url: "/logout",
    //   icon: "uil-lock-alt icon__1",
    // },
    // {
    //   name: "Faq",
    //   url: "/faq",
    //   icon: "uil-info-circle icon__1",
    // },
  ];
  const renderMenuItems = () => {
    if (isLoggedIn && !isGuest) {
      return authMenu.map(({ name, url, icon, tab }) => (
        <>
          <li className="menu-item mb-1">
            <NavLink
              key={name}
              to={url}
              state={{
                tab: tab,
                from: "/profile",
              }}
              onClick={handleCloseDropDownProfile}
            >
              {" "}
              <i className={icon}></i> {name}
            </NavLink>
          </li>
        </>
      ));
    }
    if (isLoggedIn && isGuest) {
      return guestMenu.map(({ name, url, icon }) => (
        <li className="menu-item mb-1">
          <NavLink key={name} to={url} onClick={handleCloseDropDownProfile}>
            {" "}
            {/* Guest */}
            <i className={icon}></i> {name}
          </NavLink>
        </li>
      ));
    }

    return unAuthMenu.map(({ name, url, icon }) => (
      <li className="menu-item mb-1">
        <NavLink key={name} to={url} onClick={handleCloseDropDownProfile}>
          {" "}
          <i className={icon}></i> {name}
        </NavLink>
      </li>
    ));
  };
  const [open, setOpen] = useState(false);
  const handleCloseDropDownProfile = () => {
    console.log("test");
    setOpen(false);
  };
  const handleOpenDropDownProfile = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="position-relative">
        <a
          onClick={handleOpenDropDownProfile}
          className="btn-login pink-pointer mr-3 mobile_login  
          "
        >
          <i className="fa fa-user "></i>
          {"  "}{" "}
          <span className="login_btn_txt">
            {isLoggedIn ? trimStringExtraSmall(userName) : t("login")}
          </span>
        </a>

        {open ? (
          <div className="position-absolute drop-down">
            <ul className="menu">
              {isLoggedIn && !isGuest ? (
                <li className="menu-item menu-item-header pb-2">
                  <b>{userName}</b>
                  <p className="user-mail">{email}</p>
                </li>
              ) : isLoggedIn && isGuest ? (
                <li className="menu-item menu-item-header pb-2">
                  <b className="text-center">{t("guest")}</b>
                  <p className="user-mail"></p>
                </li>
              ) : (
                ""
              )}

              {renderMenuItems()}

              {isLoggedIn ? (
                <>
                  <li className="menu-item menu-item-footer">
                    <div
                      // onClick={handleCloseDropDownProfile}
                      className="service-category logout-btn"
                    >
                      {/* <a href="/logout">Logout</a> */}
                      <Link className="" to="/logout">
                        {t("logout")}
                      </Link>
                    </div>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </div>
        ) : null}
        <Link
          to={"/profile"}
          state={{
            tab: 2,
            from: "/profile",
          }}
        >
          {/* <span className="mak-app-text">
                        {t("make_an_appointment")}
                      </span>
                      <span className="mak-app-i-c vs-btn-icon hide-l show-m show-i">
                        <i class="far fa-arrow-right"></i>
                      </span> */}
          <i
            // onClick={handleCloseDropDownProfile}
            className="far fa-shopping-cart mr-3 ml-3 pink-pointer "
          ></i>
        </Link>
      </div>
    </>
  );
};

export default HeaderUserDropDown;
