// main search
export const MAIN_SEARCH_ACTION_START = "MAIN_SEARCH_ACTION_START";
export const MAIN_SEARCH_ACTION_SUCCESS = "MAIN_SEARCH_ACTION_SUCCESS";
export const MAIN_SEARCH_ACTION_FAILURE = "MAIN_SEARCH_ACTION_FAILURE";
export const MAIN_SEARCH_ACTION_CLEAR = "MAIN_SEARCH_ACTION_CLEAR";

// side drawers
export const TOGGLE_FILTER_DRAWER = "TOGGLE_FILTER_DRAWER";
export const TOGGLE_CART_DRAWER = "TOGGLE_CART_DRAWER";

// load more
export const LOAD_MORE = "LOAD_MORE";
export const LOAD_MORE_CLEAR = "LOAD_MORE_CLEAR";
export const LOAD_MORE_ACTION_SUCCESS = "LOAD_MORE_ACTION_SUCCESS";

// product details page
export const GET_DETAILS_START = "GET_DETAILS_START";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_FAILURE = "GET_DETAILS_FAILURE";
export const GET_DETAILS_CLEAR = "GET_DETAILS_CLEAR";
export const CHANGE_DETAILS_ATTRIBUTES = "CHANGE_DETAILS_ATTRIBUTES";
export const DETAILS_NOT_AVAILABLE = "DETAILS_NOT_AVAILABLE";

// cart
export const GET_CART_ITEMS_START = "GET_CART_ITEMS_START";
export const GET_CART_ITEMS_SUCCESS = "GET_CART_ITEMS_SUCCESS";
export const GET_CART_ITEMS_FAILURE = "GET_CART_ITEMS_FAILURE";

export const UPDATE_CART = "UPDATE_CART";

export const CHANGE_CART_ITEM_COUNT = "CHANGE_CART_ITEM_COUNT";
export const CLEAR_CART = "CLEAR_CART";


// order
export const GET_ORDER_ITEMS_START = "GET_ORDER_ITEMS_START";
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS";
export const GET_ORDER_ITEMS_FAILURE = "GET_ORDER_ITEMS_FAILURE";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const CHANGE_ORDER_ITEM_COUNT = "CHANGE_ORDER_ITEM_COUNT";
export const CLEAR_ORDER = "CLEAR_ORDER";

// checkout
export const CHECKOUT_START = "CHECKOUT_START";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
export const CHECKOUT_ADD_ADDRESS = "CHECKOUT_ADD_ADDRESS";
export const CHECKOUT_EDIT_ADDRESS = "CHECKOUT_EDIT_ADDRESS";
export const CHECKOUT_UPDATE_ADDRESS = "CHECKOUT_UPDATE_ADDRESS";
export const CHECKOUT_UPDATE_PAYMENTS = "CHECKOUT_UPDATE_PAYMENTS";
export const CHECKOUT_CLEAR = "CHECKOUT_CLEAR";
export const CREATE_CHECKOUT_START = "CREATE_CHECKOUT_START";
export const CREATE_CHECKOUT_SUCCESS = "CREATE_CHECKOUT_SUCCESS";
export const CREATE_CHECKOUT_FAILURE = "CREATE_CHECKOUT_FAILURE";

// master
export const STATES_FETCH_START = "STATES_FETCH_START";
export const STATES_FETCH_SUCCESS = "STATES_FETCH_SUCCESS";
export const STATES_FETCH_FAILURE = "STATES_FETCH_FAILURE";

export const CITIES_FETCH_START = "CITIES_FETCH_START";
export const CITIES_FETCH_SUCCESS = "CITIES_FETCH_SUCCESS";
export const CITIES_FETCH_FAILURE = "CITIES_FETCH_FAILURE";

export const MASTER_DATA_CLEAR = "MASTER_DATA_CLEAR";

//order

export const ORDER_DATA_UPDATE = "ORDER_DATA_UPDATE";
export const ORDER_DATA_CLEAR = "ORDER_DATA_CLEAR";

// wishlist
export const GET_WISHLIST_START = "GET_WISHLIST_START";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURE";

export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";

export const CLEAR_SEO = "CLEAR_SEO";
export const GET_SEO_FAILURE = "GET_SEO_FAILURE";
export const GET_SEO_START = "GET_SEO_START";
export const GET_SEO_SUCCESS = "GET_SEO_SUCCESS";
export const UPDATE_SEO = "UPDATE_SEO";
