import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import Preloader from "../../layout/preloader/Preloader";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
function PrivacyPolicy() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { error, isLoading, data } = useGetDataPrivate(
    "privacy-policy",
    projectLanguage.id
  );

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div id="aboutus">
            <section className="user-form-wrap space pb-5 static_page_wrapper">
              <div className="gift-wrapper-data">
                <div className="container">
                  <div className="gift_head">
                    <div className="row gift_head_row">
                      <div className="col-xs-12 col-md-12 col-lg-12 gift_head_text">
                        <div className="gift_head_left">
                          <>
                            <h1>
                              <span>{data.title}</span>
                            </h1>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            />

                            {/* <p>{data.description}</p> */}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span className="bottom_cover"></span> */}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default PrivacyPolicy;
