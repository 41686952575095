import followImg1 from "../../../assets/img/follow us/follow1.png";
import followImg2 from "../../../assets/img/follow us/follow2.png";
import followImg3 from "../../../assets/img/follow us/follow2.png";
import followImg4 from "../../../assets/img/follow us/follow3.png";
import followImg5 from "../../../assets/img/follow us/follow3.png";
import followImg6 from "../../../assets/img/follow us/follow3.png";
import followImg7 from "../../../assets/img/follow us/follow3.png";
import SliderSlick from "react-slick";
import { t } from "i18next";
import useGetData from "../../../hooks/useGetData";

export default function FollowUs() {
  const { error, isLoading, data } = useGetData("instagramm");

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-right btn-next-follow"}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-left btn-prev-follow"}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        // <Preloader />
        <></>
      ) : (
        <>
          <section className="vs-subscribe-wrapper vs-subscribe-layout1 position-relative bg-light-theme pt-50 pb-80 homeFollowUs">
            <div className="col-sm-12 col-xs-12 text-center">
              <div className="arrow_down_wrapper mb-50">
                <i className="fa-2x fab fa-instagram mr-3"></i>
                <span className="font-b flw-us-text">{t("follow_us")}</span>
              </div>
            </div>
            <div className="position-relative container">
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <SliderSlick {...settings} className="text-center">
                    {data &&
                      data.length &&
                      data.map((item, index) => (
                        <a
                          href="https://www.instagram.com/handandstone.qa"
                          target="_blank"
                        >
                          <img
                            src={item.image}
                            className="slide-item-follow"
                            alt="Shape BG"
                          />
                        </a>
                      ))}
                  </SliderSlick>
                </div>
                <div className="col-sm-12 col-xs-12 text-center">
                  <div className="arrow_down_wrapper mt-5">
                    <a
                      href="https://www.instagram.com/handandstone.qa"
                      target="_blank"
                    >
                      <i className="fa-2x far fa-long-arrow-down"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
