import BannerPage from "../../layout/banner/BannerPage";
import FollowUs from "../../pages/follow us/FollowUs";
import useGetData from "../../../hooks/useGetData";
import Preloader from "../../layout/preloader/Preloader";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
function AboutUs() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const subtitle = "Sometimes the most productive thing you can do is Relax.";
  const showButton = false;
  const { error, isLoading, data } = useGetData("about-us", projectLanguage.id);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div id="aboutus">
            <BannerPage
              title={data.title}
              image={process.env.REACT_APP_API_BASIC_URL + "/" + data.image}
              subtitle={data.subtitle}
              description={data.description}
              showButton={showButton}
            />

            {/* <section
            className="vs-about-wrapper vs-about-layout1 position-relative space"
            id="about"
          >
            <div className="container"></div>
          </section> */}

            <FollowUs />
          </div>
        </>
      )}
    </>
  );
}

export default AboutUs;
