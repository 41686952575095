import {
    CREATE_CHECKOUT_FAILURE,
    CREATE_CHECKOUT_START,
    CREATE_CHECKOUT_SUCCESS,
  } from "../constants";
  
  const checkoutReducer = (
    state = { loader: false, result: {}, error: false },
    action
  ) => {
    const { type, payload } = action;
    switch (type) {
      case CREATE_CHECKOUT_START:
        return {
          loader: true,
          result: state.result,
          error: false,
        };
  
      case CREATE_CHECKOUT_SUCCESS:
        return {
          loader: false,
          result: payload,
          error: false,
        };
      case CREATE_CHECKOUT_FAILURE:
        return {
          loader: false,
          result: {},
          error: true,
        };
      default:
        return state;
    }
  };
  export default checkoutReducer;
  