import { useState, useEffect } from "react";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import BannerPage from "../../layout/banner/BannerPage";
import Preloader from "../../layout/preloader/Preloader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import { useDispatch, useSelector } from "react-redux";

import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import SliderSlick from "react-slick";
import ServiceItem from "./ServiceItem";
import cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  const [myLanguage, setMyLanguage] = useState(1);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const navigate = useNavigate();
  // useEffect(() => {
  //     if (!isAuthenticated()) {
  //         navigate("/login");
  //         toast.error(
  //             "Unauthorized"
  //         );
  //     }
  // })
  const {
    errorServicePage,
    isLoadingServicePage,
    data: ServicePage,
  } = useGetDataPrivate("service-page", projectLanguage.id);
  const [tabIndex, setTabIndex] = useState(0);

  const subtitle = null;
  const showButton = true;
  // useEffect(() => {
  //   setMyLanguage(projectLanguage.id);
  // }, [projectLanguage]);
  const { error, isLoading, data } = useGetDataPrivate(
    "services",
    projectLanguage.id
  );
  return (
    <>
      {isLoading || isLoadingServicePage ? (
        <Preloader />
      ) : (
        <>
          <BannerPage
            title={ServicePage.title}
            image={
              process.env.REACT_APP_API_BASIC_URL + "/" + ServicePage.image
            }
            subtitle={subtitle}
            description={ServicePage.description}
            showButton={showButton}
          />
          <section className="vs-about-wrapper vs-about-layout1 position-relative space space-services-m hide-m hide-i">
            <div className="container">
              <Tabs
                className=""
                focusTabOnClick={false}
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList className="nav tabs-style1 tab-has-arrow">
                  {data.map((service, i) => {
                    return (
                      <Tab className="col-md-3 service-nav" key={i}>
                        <a className="w-100 d-flex">
                          <span className="mr-4 align-self-center">
                            {tabIndex == i ? (
                              <img
                                width="50px"
                                height="50px"
                                src={
                                  process.env.REACT_APP_API_BASIC_URL +
                                  "/" +
                                  service.icon_hover
                                }
                              />
                            ) : (
                              <img
                                width="50px"
                                height="50px"
                                src={
                                  process.env.REACT_APP_API_BASIC_URL +
                                  "/" +
                                  service.icon
                                }
                              />
                            )}
                          </span>
                          <span>{service.title}</span>
                        </a>
                        <span className="arrow"></span>
                      </Tab>
                    );
                  })}
                </TabList>
                {data.map((service, i) => {
                  return (
                    <TabPanel key={i}>
                      <ServiceItem items={service.items} />
                    </TabPanel>
                  );
                })}
              </Tabs>
            </div>
          </section>

          <section className="vs-about-wrapper vs-about-layout1 position-relative space space-services-m hide-l show-m">
            <div className="container">
              <Accordion>
                {data.map((service, i) => {
                  return (
                    <AccordionItem className="accordion-item" key={service.id}>
                      {({ open }) => (
                        <>
                          <AccordionHeader
                            as={"div"}
                            className={`accordion-header  ${
                              open ? "accordion-header-active" : ""
                            }`}
                          >
                            <div className="accordion-title accordion-title-service row p-4">
                              <div className="col-2">
                                {open ? (
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={
                                      process.env.REACT_APP_API_BASIC_URL +
                                      "/" +
                                      service.icon_hover
                                    }
                                  />
                                ) : (
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={
                                      process.env.REACT_APP_API_BASIC_URL +
                                      "/" +
                                      service.icon
                                    }
                                  />
                                )}
                              </div>
                              <div className="col-8 ">
                                <span className="col-12 d-inline-block pr-0 pl-0 font-b">
                                  {" "}
                                  {service.title}
                                </span>
                              </div>
                              <div className="col-2">
                                <i
                                  className={`far chevron-service  
                                                    ${
                                                      open
                                                        ? "accordion-active fa-chevron-up color-white"
                                                        : "fa-chevron-down"
                                                    }`}
                                ></i>
                              </div>
                            </div>
                          </AccordionHeader>

                          <AccordionBody>
                            <ServiceItem items={service.items} />

                            {/* <div className="accordion-body">
                              <div
                                className={`d-flex pt-5 pb-5 pl-4 pr-4 service-tab-container position-relative service-tab-container-4-m mt-5 mb-4 service-tab-container-m`}
                              >
                                <div className="col-3 p-0">
                                  <img
                                    src={
                                      process.env.REACT_APP_API_BASIC_URL +
                                      "/" +
                                      service.image
                                    }
                                  />
                                </div>
                                <div className="col-9 d-flex flex-column ml-4">
                                  <div className="title-service-m font-b mb-2">
                                    {service.title}
                                  </div>
                                  <div className="description-service-m mb-4">
                                    {service.description}
                                  </div>
                                  <div className="time-service">
                                    <i className="far fa-clock mr-1"></i>
                                    {service.time}
                                  </div>
                                  <div className="text-center pr-0 pl-0 d-flex justify-content-end">
                                    <a
                                      href="#"
                                      className="vs-btn vs-style1 btn-book-now-service pr-0 pl-0 "
                                    >
                                      <span className="vs-btn-text">
                                        Book Now
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </AccordionBody>
                        </>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Services;
