import { useState } from "react";
import flower from "../../../assets/img/appointment/flower.png";
import appointmentIcon from "../../../assets/img/appointment/appointment-icon.png";
import Service from "../service/Service";
import { t } from "i18next";
import { Link } from "react-router-dom";
import React, { createRef, useEffect } from "react";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
function Appointment(props) {
  const inputRef = createRef();

  // console.log(props.serviceList)
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const openNiceSelect = (e) => {
    e.target.classList.toggle("open");
  };
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    service: "",
  });
  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };
  const selectService = (e) => {
    const divParent = e.target.parentElement.parentElement;
    const current = document.getElementById("current");
    let service_id = e.target.getAttribute("sid");
    setFormData({
      ...formData,
      service: service_id,
    });
    current.innerHTML = e.target.innerText;
    divParent.classList.remove("open");
    //handle select
  };

  return (
    <section
      className="user-select-none vs-appointment-wrapper vs-appointment-layout1 position-relative space-md-bottom mt-m-40"
      id="contact"
    >
      <div className="container pl-80 pr-80 container-m">
        <div className="row">
          {/* <div className=" position-absolute hide-i hide-m" style={{zIndex: "-999"}}>
              <img src={flower} alt="Flowers" />       
              </div> */}

          <div
            className="col-md-6 col-lg-6 col-xl-6 wow fadeInUp app-home-c-m"
            data-wow-delay="0.3s"
          >
            <form
              action="#"
              className="appointment-form-style1 input-white bg-light-theme px-40  mb-30"
            >
              <div className="form-title media mb-20">
                <div className="media-body text-center">
                  {/* <i className="fal fa-4x fa-calendar-alt text-center color-pink mb-3"></i> */}
                  <img className="mb-3 pt-30" src={appointmentIcon} />

                  <h2 className="form-title font-b">
                    {props.makeAppointment.title}
                  </h2>
                  <p className="">{props.makeAppointment.description}</p>
                </div>
              </div>
              <div className="row">
                {/* <InputGroupComponent className="form-group pos_rel col-md-6 col-6">
                  <InputComponent
                    inputRef={inputRef}
                    name="email"
                    type="email"
                    placeHolder={t("email")}
                    inputClassName="form-control"
                    isInvalid={errorState.email ? true : false}
                    onChange={handleInputChange}
                    value={formData.email}
                    />
                  <InvalidFeedback>{errorState.email}</InvalidFeedback>
                </InputGroupComponent> */}
                {/* // placeHolder="Email" */}
                {/* // id="email" */}
                {/* <i className='uil uil-mobile-android-alt lgn_icon'></i> */}
                {/* 
                <InputGroupComponent className="form-group col-md-6 col-6">
                  <InputComponent
                    inputRef={inputRef}
                    // id="phone"
                    name="phone"
                    type="text"
                    placeHolder={t("phone")}
                    inputClassName="form-control"
                    isInvalid={errorState.phone ? true : false}
                    onChange={handleInputChange}
                    value={formData.phone}
                  />
                  <InvalidFeedback>{errorState.phone}</InvalidFeedback>
                </InputGroupComponent> */}

                <div className="form-group col-md-12 col-xl-12 select-box-area col-sm-12">
                  <div
                    className="nice-select "
                    id="nice-select"
                    onClick={openNiceSelect}
                  >
                    <span className="current" id="current">
                      {t("select_service")}{" "}
                    </span>
                    <ul className="list">
                      {props.serviceListAll.map((service) => {
                        return (
                          <li
                            onClick={selectService}
                            className="option"
                            sid={service.id}
                            key={service.id}
                          >
                            {service.title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row gutters-20">
                <div className="col-12 text-center mt-m-0 mt-20 mb-20">
                  <div className="service-category btn-make-appointment btn-make-appointment-a btn-make-app-home-m">
                    <Link
                      to={"/booking/" + formData.service}
                      state={{
                        phone: formData.phone,
                        email: formData.email,
                        from: "/booking/" + formData.service,
                      }}
                    >
                      <span className="mak-app-text">
                        {t("make_an_appointment")}
                      </span>
                      <span className="mak-app-i-c vs-btn-icon hide-l show-m show-i">
                        <i class="far fa-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            className="col-md-6 col-lg-6 col-xl-6  wow fadeInUp animated services-home-c"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "c",
            }}
          >
            <div className="row">
              {props.homePageServices.map((service, i) => {
                return (
                  <Service
                    key={i}
                    title={service.title}
                    description={service.description}
                    image={service.image}
                    price={service.price}
                    time={service.time}
                    id={service.id}
                    index={i}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Appointment;
