import { toast } from "react-toastify";
import { publicInstance } from "../../axios";
import axios from "axios";
import { getLocalSession, getUserSession } from "../../auth/index.js";
import { ACCESS_TOKEN, API_BASE_PATH } from "../../helpers/config.js";
import {
  CHANGE_CART_ITEM_COUNT,
  CLEAR_CART,
  GET_CART_ITEMS_FAILURE,
  GET_CART_ITEMS_START,
  GET_CART_ITEMS_SUCCESS,
  UPDATE_CART,
} from "../constants";

export const getCartItems = (lang) => (dispatch) => {
  console.log(getLocalSession());
  dispatch({
    type: GET_CART_ITEMS_START,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Ashik: "*",
      accessToken: ACCESS_TOKEN,
      sessionToken: getLocalSession(),
      authToken: getUserSession().authToken || null,
      lang: lang,
    },
  };

  axios
    .get("cart", config, {})
    .then((res) => {
      if (res.data && res.data.status) {
        dispatch({
          type: GET_CART_ITEMS_SUCCESS,
          payload: res.data.data || [],
        });

        return res.data.data.value;
      } else {
        dispatch({
          type: GET_CART_ITEMS_FAILURE,
          payload: [],
        });

        return [];
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_CART_ITEMS_FAILURE,
        payload: [],
      });
      return [];
    });
};
export const addToCartAction = async (data) => {
  return await publicInstance
    .post(`cart`, data)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        // toast.success(res.data.message);

        return res.data.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);
        return res.data.data;
      } else {
        toast.error(res.data.message);
        return {};
      }
    })
    .catch((error) => {
      toast.error("API stopped responding. Please try later");
      return {};
    });
};
export const buyNowCartAction = async (data) => {
  return await publicInstance
    .post(`cart`, data)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success(res.data.message);

        return res.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);

        return res.data;
      } else {
        toast.error(res.data.message);
        return {};
      }
    })
    .catch((error) => {
      toast.error("API stopped responding. Please try later");
      return {};
    });
};
export const deleteCart = async (data) => {
  const postData = { ...data };
  return await publicInstance
    .delete(`cart?id=${data.id}`)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success("Item removed from cart!");

        return res.data.data;
      } else {
        toast.error("Could not remove item!. Please try later");
        return {};
      }
    })
    .catch((error) => {
      return {};
    });
};
export const modifyCart = async (data) => {
  const postData = { ...data, status: 1 };
  return await publicInstance
    .put(`cart`, postData)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success("Cart Updated Successfully");
        return res.data.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);

        return res.data.data;
      } else {
        toast.error("Something went wrong!. Please try later");
        return {};
      }
    })
    .catch((error) => {
      return {};
    });
};

export const updateCart = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CART,
    payload: data,
  });
};
export const clearCart = (id) => (dispatch) => {
  dispatch({
    type: CLEAR_CART,
  });
};
export const changeCartItemCount =
  ({ productId, count }) =>
  (dispatch) => {
    dispatch({
      type: CHANGE_CART_ITEM_COUNT,
      payload: { productId, count },
    });
  };
