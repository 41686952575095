import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { OrderDrawer } from "../checkoutOrder";
import cookies from "js-cookie";
import {
  buyNowOrderAction,
  getOrderItems,
} from "../../../../redux/actions/orderActions";
export const OrderSummary = (props) => {
  const {
    formData,
    handleCheckbox,
    handleCheckout,
    handleCoupon,
    errorState,
    coupon,
    loader,
  } = props;
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  useEffect(() => {
    dispatch(getOrderItems(coupon, projectLanguage.id));
  }, [projectLanguage]);
  const myOrderData =
    useSelector((state) => state.orderReducer.result.value) || {};

  console.log(myOrderData);
  const [checkout, setCheckout] = useState({});
  const updateCheckout = (myOrderData) => {
    setCheckout(myOrderData);
  };
  const { cartItems } = myOrderData;
  useEffect(() => {
    updateCheckout(myOrderData);
  }, [myOrderData, projectLanguage]);

  const orderCount = cartItems?.length ?? 0;
  return (
    <OrderDrawer
      orderCount={orderCount}
      data={checkout}
      updateCheckout={updateCheckout}
      loader={loader}
      {...{
        handleCoupon,
        formData,
        handleCheckbox,
        handleCheckout,
        errorState,
        // loader,
      }}
    />
  );
};
