import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
// import siteLogo from "../assets/images/logo.png";
// import darkLogo from "../assets/images/dark-logo.svg";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { registerAction, isAuthenticated } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import { useTranslation } from "react-i18next";

import { setUserSession, verifyOtpAction } from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
const Register = (props) => {
  const { t } = useTranslation();

  // console.log(props);
  let navigate = useNavigate();
  let location = useLocation();
  // const { from } = location.state;
  let from = location.state?.from || "/home";
  let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    newsLetter: false,
    termPrivacy: false,
    email: "",
    mobileNumber: "",
    password: "",
    userType: 1,
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const handleConfirmPasswordView = () => {
    if (confirmPasswordType == "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIconClass("fa fa-eye");
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIconClass("fa fa-eye-slash");
    }
  };
  const [newsletterCheckBox, setNewsletterCheckBox] = useState(false);
  const [termsPrivacyCheckBox, setTermsPrivacyCheckBox] = useState(false);

  // useEffect(() => {
  //   console.log("Hey Checkbox Checked ?", newsletterCheckBox);
  //   console.log("Hey Checkbox Checked ?", termsPrivacyCheckBox);
  // }, [newsletterCheckBox, termsPrivacyCheckBox]);

  const handleCheckbox = (e) => {
    if (e.target.name == "newsLetter") {
      setNewsletterCheckBox(!newsletterCheckBox);
      setFormData({
        ...formData,
        newsLetter: !newsletterCheckBox,
      });
    }
    if (e.target.name == "termPrivacy") {
      let statusnow = !termsPrivacyCheckBox;
      setTermsPrivacyCheckBox(statusnow);
      setFormData({
        ...formData,
        termPrivacy: statusnow,
      });
    }
  };
  // useEffect(() => {
  //   inputRef && inputRef.current && inputRef.current.focus();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setErrorState({
        ...errorState,
        confirmPassword: "Password and Confirm Password must be same.",
      });
      setLoader(false);
    } else {
      const validateOptions = {
        firstName: {
          required: true,
          type: "text",
        },
        lastName: {
          required: true,
          type: "text",
        },
        newsLetter: {
          required: false,
          type: "checkbox",
        },
        email: {
          required: true,
          type: "email",
        },
        mobileNumber: {
          required: true,
          type: "mobile",
        },
        password: {
          required: true,
          type: "password",
        },
        confirmPassword: {
          required: true,
          type: "password",
        },
        termPrivacy: {
          required: true,
          type: "checkbox",
        },
      };

      const { isValid, errors } = validateForm(validateOptions, formData);

      if (isValid) {
        console.log(newsletterCheckBox);

        setLoader(true);
        const res = await registerAction(formData);
        console.log(res);
        console.log(res.userId);
        if (res && res.userId) {
          const encryptedData = encryptData(res);
          console.log(encryptedData);
          if (encryptedData) {
            setUserSession(res);
            toast.success("Registered Successfully");
            // navigate("/home");
            // navigate("/account-details");
            // window.location.href = "/home";
            window.location.href = from;

            // navigate("/home", { replace: true });
            // history.push("/home");
          }
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      } else {
        setErrorState(errors);
        console.log(errors);
        setLoader(false);
      }
    }
  };
  return (
    <>
      <div className="signUp-wrapper user-form-wrap space pb-5">
        <div className="bgcircle"></div>

        <div className="container">
          <div className="row justify-content-center auth_register_wrapper">
            <div className="col-lg-10 col-xl-12">
              <form
                action="#"
                className="signUp-form bg-light-theme p-sm-5 p-3 input-white"
              >
                <h2 className="form-title text-center">{t("signup")}</h2>
                <div className="row pb-3">
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel">
                      <InputComponent
                        inputRef={inputRef}
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeHolder={t("first_name")}
                        inputClassName="form-control"
                        isInvalid={errorState.firstName ? true : false}
                        onChange={handleInputChange}
                        value={formData.firstName}
                      />
                      {/* <i className="uil uil-user-circle lgn_icon"></i> */}
                      <InvalidFeedback>{errorState.firstName}</InvalidFeedback>
                    </InputGroupComponent>

                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            First Name
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel">
                      <InputComponent
                        inputRef={inputRef}
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeHolder={t("last_name")}
                        inputClassName="form-control"
                        isInvalid={errorState.lastName ? true : false}
                        onChange={handleInputChange}
                        value={formData.lastName}
                      />
                      {/* <i className="uil uil-user-circle lgn_icon"></i> */}
                      <InvalidFeedback>{errorState.lastName}</InvalidFeedback>
                    </InputGroupComponent>

                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            Last Name
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel">
                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        placeHolder={t("email")}
                        inputClassName="form-control lgn_input"
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                      />
                      <i className="uil uil-envelope lgn_icon"></i>
                      <InvalidFeedback>{errorState.email}</InvalidFeedback>
                    </InputGroupComponent>

                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            Email
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel checkout-login">
                      <InputComponent
                        id="mobileNumber"
                        name="mobileNumber"
                        type="mobileNumber"
                        placeHolder={t("phone")}
                        inputClassName="form-control"
                        isInvalid={errorState.mobileNumber ? true : false}
                        onChange={handleInputChange}
                        value={formData.mobileNumber}
                      />
                      <i className="uil uil-mobile-android-alt lgn_icon"></i>
                      <InvalidFeedback>
                        {errorState.mobileNumber}
                      </InvalidFeedback>
                    </InputGroupComponent>

                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            Phone Number
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel ">
                      <InputComponent
                        id="password"
                        name="password"
                        type={passwordType}
                        placeHolder={t("password")}
                        inputClassName="form-control lgn_input"
                        isInvalid={errorState.password ? true : false}
                        onChange={handleInputChange}
                        value={formData.password}
                      />
                      <i
                        className={passwordIconClass}
                        onClick={handlePasswordView}
                      ></i>
                      <i className="uil uil-padlock lgn_icon"></i>
                      <InvalidFeedback>{errorState.password}</InvalidFeedback>
                    </InputGroupComponent>
                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            Password
                          </label>{" "}
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                    <InputGroupComponent className="form-group pos_rel ">
                      <InputComponent
                        id="confirmpassword"
                        name="confirmPassword"
                        type={confirmPasswordType}
                        placeHolder={t("confirm_password")}
                        inputClassName="form-control lgn_input"
                        isInvalid={errorState.confirmPassword ? true : false}
                        onChange={handleInputChange}
                        value={formData.confirmPassword}
                      />
                      <i
                        className={confirmPasswordIconClass}
                        onClick={handleConfirmPasswordView}
                      ></i>
                      <i className="uil uil-padlock lgn_icon"></i>
                      <InvalidFeedback>
                        {errorState.confirmPassword}
                      </InvalidFeedback>
                    </InputGroupComponent>
                    {/* <div className="form-group">
                          <label for="signUpUserName" className="sr-only">
                            Confirm Password
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Confirm Password"
                            id="signUpUserName"
                            name="signUpUserName"
                          />
                        </div> */}
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center">{errorResponse}</p>
                    )}
                  </div>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    name="newsLetter"
                    id="newsLetter"
                    value={formData.newsLetter}
                    checked={newsletterCheckBox}
                    onChange={handleCheckbox}
                  />{" "}
                  <label for="newsLetter">{t("i_want_sent_email")}</label>
                </div>
                <div className="position-relative ">
                  <input
                    type="checkbox"
                    name="termPrivacy"
                    id="termPrivacy"
                    className={errorState.termPrivacy ? "is-invalid" : ""}
                    value={formData.termPrivacy}
                    checked={termsPrivacyCheckBox}
                    onChange={handleCheckbox}
                    isInvalid={errorState.termPrivacy ? true : false}
                  />{" "}
                  <InvalidFeedback>{errorState.termPrivacy}</InvalidFeedback>
                  <label for="termPrivacy">
                    {t("i_agree_to_all")}{" "}
                    <Link target={"new"} to="/terms-conditions">
                      {t("terms_condition")}{" "}
                    </Link>
                    {", "}{" "}
                    <Link target={"new"} to="/privacy-policy">
                      {t("privacy_policy")}{" "}
                    </Link>
                  </label>
                </div>

                <div className="mb-0 text-center">
                  {/* <button
                        className="vs-btn vs-style1  border-0 form_button"
                        type="submit"
                      >
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-shape"></span>{" "}
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-text">Create an Account</span>
                      </button> */}
                  <ButtonComponent
                    className="vs-btn vs-style1  form_button"
                    type="submit"
                    text={t("create_an_account")}
                    loader={loader}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
              <div className="mb-0 mt-5 text-center">
                <div className="bottom-links pt-3">
                  {t("already_have_account")}{" "}
                  <a href="/login">{t("signin")} </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
