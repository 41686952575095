import classNames from "classnames";
import React from "react";

const InputComponent = ({
  name,
  type,
  id,
  value,
  onChange,
  isInvalid = false,
  placeHolder,
  inputClassName,
  inputRef,
  readonly,
}) => {
  return (
    <input
      ref={inputRef}
      value={value}
      id={id}
      name={name}
      type={type}
      readOnly={readonly}
      onChange={onChange}
      placeholder={placeHolder}
      className={classNames(inputClassName, { "is-invalid": isInvalid })}
    />
  );
};

export default InputComponent;
