import React, { useState } from "react";
import { createRef, useEffect } from "react";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
function ProductItems(props) {
  const [activeTab, setActiveTab] = useState("cust0");
  const [customPkgItems, setCustomPkgItems] = useState([]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <section className="product_we_use_product-wrapper">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 secondery_nav">
            <Accordion className="hide-l hide-m">
              {props.products.length &&
                props.products.map((customeData, index) => (
                  <AccordionItem
                    key={index}
                    isActive={index === 0 ? true : false}
                  >
                    {({ open }) => (
                      <>
                        <AccordionHeader className={"hsw" + index}>
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.image
                            }
                            className="ls-l img-slider imgPackage"
                            alt={"Spa Girl " + index}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.hoverImage
                            }
                            className="ls-l img-slider imgPackageHover"
                            alt={"Spa Girl " + index}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />

                          <h4
                            className={`accordion-title ${
                              open ? "accordion-active" : ""
                            }`}
                          >
                            {customeData.title}
                          </h4>

                          <i
                            className={`fa-2x far float-right mr-4 
                                                    ${
                                                      open
                                                        ? "accordion-active fa-chevron-up color-white"
                                                        : "fa-chevron-down"
                                                    }`}
                          ></i>
                        </AccordionHeader>

                        <AccordionBody>
                          <div className="accordion-body">
                            <TabPane tabId={"cust" + index}>
                              <div className="custom-contents pWeUse">
                                <Row>
                                  {customeData.items &&
                                  customeData.items.length > 0
                                    ? customeData.items.map(
                                        ({ image, title, id }, idx) => (
                                          <Col className="col-12 col-sm-6 col-md-6 col-lg-2 text-center">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_API_BASIC_URL +
                                                "/" +
                                                image
                                              }
                                              className="ls-l img-slider imgCustomPackageItems"
                                              alt={"Spa Girl " + idx}
                                            />
                                            <h5> {title}</h5>
                                          </Col>
                                        )
                                      )
                                    : ""}
                                </Row>
                              </div>
                            </TabPane>
                          </div>
                        </AccordionBody>
                      </>
                    )}
                  </AccordionItem>
                ))}
            </Accordion>

            <Nav tabs className="pWeUse  ">
              {props.products.length &&
                props.products.map((customeData, i) => {
                  return (
                    <>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "cust" + i,
                          })}
                          onClick={() => {
                            toggle("cust" + i);
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.image
                            }
                            className="ls-l img-slider imgPackage"
                            alt={"Spa Girl " + i}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.hoverImage
                            }
                            className="ls-l img-slider imgPackageHover"
                            alt={"Spa Girl " + i}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />

                          <h4>{customeData.title}</h4>
                          <span></span>
                        </NavLink>
                      </NavItem>
                    </>
                  );
                })}
            </Nav>

            <TabContent activeTab={activeTab} id="myTabContentProductWeUse">
              {/* <i className="fa fa-caret-up"></i> */}

              {props.products.length &&
                props.products.map((customeData, i) => {
                  return (
                    <>
                      <TabPane tabId={"cust" + i}>
                        <Row>
                          <Col sm="12">
                            <div className="custom-contents">
                              <>
                                <div className="row align-items-center mb-3 cus_item_row">
                                  {customeData.items &&
                                  customeData.items.length > 0
                                    ? customeData.items.map(
                                        ({ image, title, id }, idx) => (
                                          <div
                                            key={"cusItem" + idx}
                                            className="col-4 col-sm-4 col-md-4 col-lg-3 cust_items"
                                          >
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_API_BASIC_URL +
                                                "/" +
                                                image
                                              }
                                              className="ls-l img-slider imgCustomPackageItems"
                                              alt={"Spa Girl " + i}
                                            />
                                            <h5 className="mt-3"> {title}</h5>
                                          </div>
                                        )
                                      )
                                    : ""}
                                </div>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </>
                  );
                })}
            </TabContent>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductItems;
