import { useState, useEffect, createRef, useRef } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import imgService from "../../../assets/img/booking/service1.png";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import Preloader from "../../layout/preloader/Preloader";
import DatePicker from "react-datepicker";
import { privateInstance } from "../../../axios";
import "react-datepicker/dist/react-datepicker.css";
import "react-tabs/style/react-tabs.css";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import Cart from "../cart/Cart";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import SliderSlick from "react-slick";
import Slider from "react-slick";

import {
  addToCartAction,
  updateCart,
} from "../../..//redux/actions/cartActions";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

function Booking() {
  const { t } = useTranslation();

  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  // console.log(from);
  let { type, id } = useParams();

  const serviceRowRef = createRef();
  const {
    error,
    isLoading,
    data: serviceList,
  } = useGetDataPrivate("services", projectLanguage.id);

  const getIndex = (array) => {
    var dIndex = 0;

    array.forEach((element, indexed) => {
      if (element.id == type) {
        dIndex = indexed;
      }
    });
    return dIndex;
  };
  const [tabIndex, setTabIndex] = useState(0);
  const indexNo = getIndex(serviceList);

  const [msgSlotResponse, setMsgSlotResponse] = useState(null);
  const [isSlotReady, setIsSlotReady] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [currentDate, SetCurrentDate] = useState(
    new Date().toLocaleDateString("zh-Hans-CN").replaceAll("/", "-")
  );
  const [currentServiceType, setCurrentServiceType] = useState(type || 0);
  // if (!isLoading) {
  //   setCurrentServiceType(serviceList[0].id);
  // }
  const [currentServiceItem, setCurrentServiceItem] = useState({});
  const [currentTimeSlotIndex, setCurrentTimeSlotIndex] = useState();
  const [timeSlot, setTimeSlot] = useState([]);
  const [currentTimeSlotKey, setCurrentTimeSlotKey] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const [loader, setLoader] = useState(false);
  const [addToCartLoader, setAddToCartLoader] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    quantity: 1,
    date: currentDate,
    booking_slot: "",
    type: 2,
    gift_type: 2,
    serviceId: [],
    gift_prepackage_id: "",
    gift_from: "",
    gift_to: "",
    gift_cake_massage: "",
    gift_card_mesage: "",
    gift_comments: "",
    gift_address: "",
  });

  // ---------------------------------------------------------------

  useEffect(() => {
    console.log(indexNo);
    setTabIndex(indexNo);

    // setTimeout(() => {
    //   if (!isLoading) {
    //     setCurrentServiceItem({
    //       id: serviceList[0].items[0].id,
    //       title: serviceList[0].items[0].title,
    //       index: 0,
    //     });
    //     setCurrentServiceType({
    //       id: serviceList[0].id,
    //       title: serviceList[0].title,
    //     });

    //     setFormData({
    //       ...formData,
    //       serviceId: [serviceList[0].items[0].id],
    //     });
    //     document.documentElement.style.setProperty(
    //       "--border-color-before",
    //       "transparent transparent #fde9ea transparent"
    //     );
    //   }
    // }, 2000);
  }, [isLoading, indexNo]);

  useEffect(() => {
    getTimeSLot(currentDate, currentServiceType);
  }, [currentServiceType]);

  useEffect(() => {
    if (!isLoading && !type) {
      setCurrentServiceType(serviceList[0].id);
    }
  }, [serviceList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if (
      !formData.serviceId ||
      formData.serviceId.length == 0 ||
      !currentServiceItem.id
    ) {
      toast.error("Choose atleast one service");
      return false;
    }

    if (
      formData.booking_slot === null ||
      formData.booking_slot == "" ||
      currentTimeSlotKey === null
    ) {
      toast.error("Please choose a time slot");
      return false;
    }

    setLoader(true);
    setAddToCartLoader(true);
    setFormData({
      ...formData,
      quantity:
        (cartItemCountState && cartItemCountState[formData.serviceId]) || 1,
    });

    const addToCartResponse = await addToCartAction(formData);
    if (addToCartResponse) {
      setAddToCartLoader(false);
      setLoader(false);
      if (addToCartResponse.value) {
        dispatch(updateCart(addToCartResponse));
        toast.success("Added to cart Successfully");
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------------

  const handleClickTimeSlot = (indexTimeSlot, key, value, disable) => {
    if (!disable) {
      setFormData({
        ...formData,
        booking_slot: key,
      });
      setCurrentTimeSlotIndex(indexTimeSlot);
      setCurrentTimeSlotKey(value);
    }
  };

  const getTimeSLot = async (date, idService) => {
    if (currentServiceType) {
      setIsSlotReady(false);

      return await privateInstance
        .post("time-slots", {
          date: date,
          id: idService,
        })
        .then((res) => {
          if (res.data.status === 200) {
            setIsSlotReady(true);
            setTimeSlot(res.data.data.value);

            setMsgSlotResponse(null);
          } else if (res.data.status === 415) {
            setMsgSlotResponse(res.data.message);

            setTimeSlot([]);
            setIsSlotReady(true);
          } else {
            setTimeSlot([]);
            setIsSlotReady(true);
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };

  const handleChangeDate = (date) => {
    const d = new Date(date)
      .toLocaleDateString("zh-Hans-CN")
      .replaceAll("/", "-");
    setCurrentTimeSlotIndex(null);
    setCurrentTimeSlotKey(null);
    SetCurrentDate(d);
    setFormData({
      ...formData,
      date: d,
    });

    if (currentServiceType) {
      getTimeSLot(d, currentServiceType);
    }
  };

  const handleSelectTab = (index) => {
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({});
    setTabIndex(index);

    // getTimeSLot(currentDate, currentServiceType);
    document.documentElement.style.setProperty(
      "--border-color-before",
      "transparent transparent #fff transparent"
    );
  };

  const handleClickService = (id, title, index) => {
    console.log(id);
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({ id: id, title: title, index: index });
    // getTimeSLot(currentDate, currentServiceType);

    setFormData({
      ...formData,
      serviceId: [id],
    });
  };
  const handleClickServiceM = (id, title, index) => {
    if (index == 0) {
      document.documentElement.style.setProperty(
        "--border-color-before",
        "transparent transparent #fde9ea transparent"
      );
    } else {
      document.documentElement.style.setProperty(
        "--border-color-before",
        "transparent transparent #fff transparent"
      );
    }
    setCurrentTimeSlotKey(null);
    setCurrentTimeSlotIndex(null);
    setCurrentServiceItem({ id: id, title: title, index: index });
    // getTimeSLot(currentDate, currentServiceType);
    setFormData({
      ...formData,
      serviceId: [id],
    });
  };
  const slider = useRef(null);

  const prevArrow = (
    <button
      className="custom-arrow custom-prev-arrow"
      onClick={() => slider.current.slickPrev()}
    >
      <i className="fas fa-chevron-left"></i>
    </button>
  );

  const nextArrow = (
    <button
      className="custom-arrow custom-next-arrow"
      onClick={() => slider.current.slickNext()}
    >
      <i className="fas fa-chevron-right"></i>
    </button>
  );
  const cartItemCountState = useSelector((state) => state.cartItemCountReducer);
  const settings = {
    dots: false,
    infinite: true,

    // autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    autoplay: false,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    // autoplaySpeed: 10000,
  };
  const tabSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    // focusOnSelect: true,
    arrows: true,
  };

  const sliderSettings = {
    // add any necessary settings for the slider
  };
  const tabSlider = useRef(null);

  const handleTabSelect = (index) => {
    alert(index);
    setTabIndex(index);
    tabSlider.current.slickGoTo(index);
  };
  // const handleSelectTab = (index) => {
  //   setCurrentTimeSlotKey(null);
  //   setCurrentTimeSlotIndex(null);
  //   setCurrentServiceItem({});
  //   setTabIndex(index);

  //   // getTimeSLot(currentDate, currentServiceType);
  //   document.documentElement.style.setProperty(
  //     "--border-color-before",
  //     "transparent transparent #fff transparent"
  //   );
  // };

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <section className="vs-blog-wrapper vs-blog-layout1 link-inherit bg-light-theme space-top space-md-bottom booking-page-wrapper">
          <div className="container pl-40 pr-40">
            <div className="bgcircle"></div>
            <div className="row col-12 pl-60 pt-4 pb-4 font-b booking-page-title-c">
              <div className="booking-page-title">{t("booking_details")}</div>
              {/* <div className="position-fixed my-cart-btn-m hide-l show-m show-i"><i className="far fa-chevron-left position-relative"></i> My Cart</div> */}
            </div>
            <div className="row justify-content-center booking_wrapper">
              <div className="col-sm-12 col-lg-7 col-xl-7 bg-white">
                <div className="pb-5 pr-4 pl-4 d-flex flex-column booking-c-m">
                  <div className="pt-4 pb-4 text-center booking-title">
                    {t("appointment_details")}
                  </div>

                  {/* <SliderSlick {...settings}>
                    <p>Option One</p>
                    <p>Option Two</p>
                    <p>Option Three</p>
                    <p>Option Four</p>
                    <p>Option Five</p>
                    <p>Option six</p>
                  </SliderSlick> */}
                  <Tabs
                    selectedTabClassName={"react-tabs__tab-booking--selected"}
                    className=""
                    focusTabOnClick={true}
                    selectedIndex={tabIndex}

                    // onSelect={handleTabSelect} selectedIndex={activeTab}
                  >
                    <div className={"tab-slider-container "}>
                      <SliderSlick {...settings} ref={tabSlider}>
                        {serviceList.map((service, i) => {
                          return (
                            <Tab
                              className={
                                i == tabIndex
                                  ? "col tab-booking-c-m react-tabs__tab-booking--selected"
                                  : "col tab-booking-c-m"
                              }
                              key={i}
                              onClick={() => {
                                handleSelectTab(i);
                                setCurrentServiceType(service.id);
                                SetCurrentDate(
                                  new Date()
                                    .toLocaleDateString("zh-Hans-CN")
                                    .replaceAll("/", "-")
                                );
                              }}
                            >
                              <div className="service-tab-c p-0">
                                <img
                                  className="img-booking-service-tab"
                                  // src={service.image}
                                  src={
                                    process.env.REACT_APP_API_BASIC_URL +
                                    "/" +
                                    service.image
                                  }
                                />
                                <div className="text-center service-tab-title pl-10 pr-10">
                                  {service.title}
                                </div>
                              </div>
                            </Tab>
                          );
                        })}
                      </SliderSlick>
                    </div>
                    {/* <TabList className=" nav tabs-style1 tab-has-arrow tab-list-booking ashikkk">
                      {serviceList.map((service, i) => {
                        return (
                          <Tab
                            className="col tab-booking-c-m"
                            key={i}
                            onClick={() => {
                              setCurrentServiceType(service.id);
                              SetCurrentDate(
                                new Date()
                                  .toLocaleDateString("zh-Hans-CN")
                                  .replaceAll("/", "-")
                              );
                            }}
                          >
                            <div className="service-tab-c p-0">
                              <img
                                className="img-booking-service-tab"
                                // src={service.image}
                                src={
                                  process.env.REACT_APP_API_BASIC_URL +
                                  "/" +
                                  service.image
                                }
                              />
                              <div className="text-center service-tab-title pl-10 pr-10">
                                {service.title}
                              </div>
                            </div>
                          </Tab>
                        );
                      })}
                    </TabList> */}

                    {serviceList.map((service, i) => {
                      return (
                        <TabPanel key={service.id}>
                          {/* ---------------------------(MOBILE)--------------------------------- */}

                          <div className="col-md-12  mt-3 hide-l show-m show-i tab-booking-c-m">
                            <div
                              className={`col-md-12 service-list-c service-list-c-${
                                i + 1
                              } d-flex flex-column service-list-c-m`}
                              ref={serviceRowRef}
                            >
                              {service.items.map((item, idx) => {
                                return (
                                  <div
                                    id="service-item-booking-row"
                                    key={item.id}
                                    className={`col-md-12 position-relative service-item-booking-row ${
                                      idx == currentServiceItem.index
                                        ? "service-item-booking-selected-m"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleClickServiceM(
                                        item.id,
                                        item.title,
                                        idx
                                      )
                                    }
                                  >
                                    <div className="service-item-booking d-flex">
                                      <div className="pl-1 pr-1 col-2">
                                        <img
                                          width="40px"
                                          height="40px"
                                          src={
                                            process.env
                                              .REACT_APP_API_BASIC_URL +
                                            "/" +
                                            item.icon
                                          }
                                        />
                                      </div>
                                      <div className="col-7 pt-2 pr-0 pl-2 service-title-booking-m">
                                        {item.title}
                                      </div>
                                      <div className="col-3 pl-0 pr-2  pt-2 text-right">
                                        <button className="price-service-booking mb-2">
                                          QR. {item.price}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* ---------------------------------------------------------------------- */}
                          <div className="col-md-12  mt-3 hide-m hide-i">
                            <div
                              className={`row service-list-c service-list-c-${
                                i + 1
                              } d-flex`}
                            >
                              {service.items.map((item, idx) => {
                                return (
                                  <div
                                    className="col-md-3 position-relative"
                                    key={item.id}
                                    onClick={() =>
                                      handleClickService(
                                        item.id,
                                        item.title,
                                        idx
                                      )
                                    }
                                  >
                                    <div
                                      className={`service-item-booking d-flex flex-column justify-content-center text-center ${
                                        idx == currentServiceItem.index
                                          ? "service-item-booking-selected"
                                          : ""
                                      }`}
                                    >
                                      <div className="p-1 icon-service-c">
                                        <img
                                          width="40px"
                                          height="40px"
                                          src={
                                            process.env
                                              .REACT_APP_API_BASIC_URL +
                                            "/" +
                                            item.icon
                                          }
                                        />
                                      </div>
                                      <div className="service-title-booking">
                                        {item.title}
                                      </div>
                                      <div>
                                        <button className="price-service-booking mb-2">
                                          QR. {item.price}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-md-12 mt-4 ">
                            <div className="row">
                              <div className="col-sm-7 col-md-7 col-7 datepicker-c">
                                <DatePicker
                                  dateFormat="yyyy-m-d, h:mm aa"
                                  minDate={new Date()}
                                  inline
                                  onChange={(date) => handleChangeDate(date)}
                                />
                              </div>
                              <div className="col-sm-5 col-md-5 col-5 time-slot-c">
                                {isSlotReady ? (
                                  <div>
                                    <div className="text-center msg-slot-res">
                                      {msgSlotResponse}
                                    </div>

                                    {timeSlot.map((time, i) => {
                                      return (
                                        <>
                                          {time.disable ? (
                                            <>
                                              {/* <ReactTooltip
                                                border={true}
                                                borderColor="#f47b80"
                                                textColor="#f47b80"
                                                effect="solid"
                                                borderClass="tooltip-border"
                                                delayHide={1000}
                                                className="tooltip-unvailable"
                                                place="right"
                                                type="light"
                                                event="click"
                                                offset={{ left: 0 }}
                                              /> */}
                                              <div
                                                data-tip="Unvailable Timeslot"
                                                className={`time-slot-item ${
                                                  time.disable
                                                    ? "time-slot-item-disabled"
                                                    : ""
                                                } `}
                                                key={i}
                                              >
                                                {time.value}{" "}
                                                <span className="not_avail">
                                                  {t("not_available")}
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handleClickTimeSlot(
                                                  i,
                                                  time.key,
                                                  time.value,
                                                  time.disable
                                                )
                                              }
                                              className={`time-slot-item ${
                                                time.disable
                                                  ? "time-slot-item-disabled"
                                                  : ""
                                              } ${
                                                i == currentTimeSlotIndex
                                                  ? "active-slot"
                                                  : ""
                                              } `}
                                              key={i}
                                            >
                                              {time.value}{" "}
                                              <span className="avail">
                                                {t("available")}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <span>
                                    <img
                                      src={spinnerImg}
                                      alt="Hand & Stone SPA Loader"
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      );
                    })}
                  </Tabs>

                  <div className="col-md-12 col-12 result-booking-c">
                    <div className="bg-light-booking selected-service-booking-c pt-20 pb-20 pl-20 pr-20 mt-4 mb-2 d-felx">
                      <div className="row">
                        <div className="col-sm-12 col-md-5  pr-2">
                          {t("service")} : {currentServiceItem.title}{" "}
                        </div>
                        <div className="col-sm-12 col-md-3  pr-2">
                          {t("date")} : {currentDate}
                        </div>
                        <div className="col-sm-12 col-md-4  pr-2">
                          {t("time")} : {currentTimeSlotKey}
                        </div>
                      </div>
                    </div>
                    <p className="trasport_desclaimer">
                      Note: Transportation charges 50 QAR applicable for the
                      bookings
                    </p>
                  </div>

                  <div className="col-md-12">
                    <div className="service-category btn-add-booking font-l text-center">
                      <a onClick={handleSubmit}> {t("add_to_cart")} </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 col-xl-4 hide-m hide-i cart_wrapper">
                <Cart />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Booking;
