import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// import cartIcon from "../assets/img/cart.png";
import cartIcon from "../../../assets/img/cart.png";
import cookies from "js-cookie";
// import cartIcon from "../assets/images/cart.png";
// import emptyCartIcon from "../assets/images/empty-cart.png";
import { toggleCartAction } from "../../../redux/actions/uiActions";
import { trimString } from "../../../helpers/helperFunctions";
import { toast } from "react-toastify";
import {
  deleteCart,
  getCartItems,
  modifyCart,
  updateCart,
} from "../../../redux/actions/cartActions";
import { BACKEND_BASE_PATH } from "../../../helpers/config";
import { isAuthenticated } from "../../../auth";
// import { checkCartProceed } from "../services/HomePageService";
import i18next, { t } from "i18next";

const Cart = (props) => {
  const dispatch = useDispatch();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [countState, setCount] = useState(0);
  const [cartData, setCartData] = useState({});
  useEffect(() => {
    dispatch(getCartItems(projectLanguage.id));
  }, [projectLanguage]);
  const handleCartToggle = (e) => {
    e.preventDefault();
    dispatch(toggleCartAction());
  };
  // const myCartData = {};
  const myCartData =
    useSelector((state) => state.cartReducer.result.value) || {};
  const { cartItems } = myCartData;
  let cartCount = 0;
  if (cartItems) {
    cartCount = cartItems.length;
  }

  useEffect(() => {
    setCount(cartCount);
    setCartData(myCartData);
  }, [myCartData, cartCount]);

  return (
    <>
      {/* <div className={"header_cart order-1"}>
        <a
          href="/"
          className="cart__btn hover-btn pull-bs-canvas-left"
          title="Cart"
          onClick={handleCartToggle}
        >
          <span className="fa fa-angle-left"></span> My Cart
          (
          <span className="noti_count2">{countState}</span>)
        </a>
      </div> */}
      {/* <img src={cartIcon} alt="cart" /> */}

      <CartDrawer
        handleCartToggle={handleCartToggle}
        cartCount={countState}
        data={cartData}
      />
    </>
  );
};

const CartDrawer = ({ handleCartToggle, cartCount, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sideDrawerReducer.cartDraw);
  const { subTotal, totalSaving, cartItems, totalAvailability } = data;
  const [delLoader, setDelLoader] = useState("");
  const [countLoader, setCountLoader] = useState("");
  const deleteCartItem = async (data) => {
    setDelLoader(data.id);
    const delRes = await deleteCart(data);
    if (delRes) {
      dispatch(updateCart(delRes));
    }
    setDelLoader("");
  };
  const updateCount = async (data) => {
    setCountLoader(data.id);
    const delRes = await modifyCart(data);
    if (delRes) {
      dispatch(updateCart(delRes));
    }
    setCountLoader("");
  };
  const handleCheckout = async (e) => {
    e.preventDefault();
    handleCartToggle(e);
    navigate("/checkout");
    // if (totalAvailability > 0) {
    //   toast.error(
    //     "One Or More Product Not In Stock .Remove the product before Proceed to Checkout"
    //   );
    //   return false;
    // } else {
    //   handleCartToggle(e);
    //   const cartProceed = await checkCartProceed(); //TOP, MIDDLE, BOTTOM, category id, page( 1=home, 2=category page)
    //   if (cartProceed.status == 300) {
    //     toast.error(
    //       "One Or More Product Not In Stock .Remove the product before Proceed to Checkout"
    //     );
    //   } else if (cartProceed.status == 475) {
    //     toast.error(cartProceed.message);
    //   } else if (cartProceed.status == 200) {
    //     if (isAuthenticated()) {
    //       history.push("/checkout");
    //     } else {
    //       history.push({
    //         pathname: "/login",
    //         state: { callBackUrl: "/checkout" },
    //       });
    //     }
    //   }
    // }
  };

  return (
    <div
      className={classNames(
        "cart_component bs-canvas bs-canvas-left position-fixed bg-cart h-100 pb-3",
        { "mr-0": show }
      )}
    >
      <div className="bs-canvas-header side-cart-header p-3 ">
        <h3 className="d-inline-block  main-cart-title">
          {" "}
          <button
            type="button"
            className="bs-canvas-close close"
            aria-label="Close"
            onClick={handleCartToggle}
          >
            <i className="fa fa-angle-right"></i>
          </button>{" "}
          <span>{t("my_cart")}</span>
        </h3>
      </div>
      <div className="bs-canvas-body">
        {cartCount === 0 ? (
          <div className="empty-cart">
            {/* <img src={emptyCartIcon} alt="empty" /> */}

            <h3>{t("shopping_cart_empty")}</h3>
          </div>
        ) : (
          <div className="side-cart-items">
            {cartItems &&
              cartItems.map(
                ({
                  id,
                  image,
                  type,
                  gift_type,
                  title,
                  price,
                  display_price,
                  product_id,
                  quantity,
                  slug,
                  availability,
                  options,
                  status,
                  maximumOrderQuantity,
                  items,
                  date,
                  booking_slot,
                }) => (
                  <div
                    className={
                      "cart-item " + (availability == 0 ? "not_available" : "")
                    }
                    key={id}
                  >
                    <div className="cart-text">
                      {availability == 0 ? (
                        <p className="product_not_stock">
                          This product not in stock
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="cart-heading">
                        <h4>{title}</h4>
                        {items && items.length > 0 ? (
                          <ul className="kggrm-now checkout-atr-list">
                            {items.map(({ title }, idx) => (
                              <li key={`${title}_${idx}`}>{title}</li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                        {type && type == 2 ? (
                          <ul>
                            <li>Date : {date}</li>
                            <li>Time : {booking_slot}</li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="qty-group">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            value="-"
                            className="minus minus-btn"
                            onClick={() =>
                              updateCount({
                                id,
                                product_id,
                                quantity: quantity - 1,
                              })
                            }
                            disabled={quantity <= 1}
                          />
                          {countLoader === id ? (
                            <span className="spinner-border" />
                          ) : (
                            <input
                              type="number"
                              step="1"
                              disabled={true}
                              name="quantity"
                              value={quantity}
                              className="input-text qty text"
                            />
                            // <p className="input-text qty text">{quantity}</p>
                          )}
                          <input
                            type="button"
                            value="+"
                            className="plus plus-btn"
                            onClick={() =>
                              updateCount({
                                id,
                                product_id,
                                quantity: quantity + 1,
                              })
                            }
                            disabled={quantity >= maximumOrderQuantity}
                          />
                        </div>
                      </div>
                      <div className="cart-item-price">
                        <>{price} QAR</>
                      </div>
                      <button
                        onClick={() =>
                          deleteCartItem({ id, product_id, quantity })
                        }
                        type="button"
                        className="cart-close-btn nooooooooooooooon"
                      >
                        {delLoader === id ? "x" : "x"}
                      </button>
                    </div>
                  </div>
                )
              )}
          </div>
        )}
      </div>

      {cartCount > 0 ? (
        <div className="bs-canvas-footer p-3">
          <div className="main-total-cart">
            <h2>{t("total")}</h2>
            <h2>{subTotal} QAR</h2>
          </div>
          <div className="checkout-cart text-center service-category btn-add-booking">
            <Link
              onClick={handleCheckout}
              to="/checkout"
              className="cart-checkout-btn hover-btn"
            >
              {t("checkout")}
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Cart;
