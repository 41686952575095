import { toast } from "react-toastify";
import { publicInstance } from "../../axios";

import {
  CHANGE_ORDER_ITEM_COUNT,
  CLEAR_ORDER,
  CREATE_CHECKOUT_FAILURE,
  CREATE_CHECKOUT_START,
  CREATE_CHECKOUT_SUCCESS,
  GET_ORDER_ITEMS_FAILURE,
  GET_ORDER_ITEMS_START,
  GET_ORDER_ITEMS_SUCCESS,
  UPDATE_ORDER,
} from "../constants";
import axios from "axios";
import { getLocalSession, getUserSession } from "../../auth/index.js";
import { ACCESS_TOKEN, API_BASE_PATH } from "../../helpers/config.js";
export const getOrderItems = (coupon, lang) => (dispatch) => {
  dispatch({
    type: GET_ORDER_ITEMS_START,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Ashik: "*",
      accessToken: ACCESS_TOKEN,
      sessionToken: getLocalSession(),
      authToken: getUserSession().authToken || null,
      lang: lang,
    },
  };

  axios
    .post("checkout", { coupon }, config)

    .then((res) => {
      if (res.data && res.data.status === 412) {
        toast.error(res.data.message);
        dispatch({
          type: GET_ORDER_ITEMS_FAILURE,
          payload: [],
        });
        return [];
      }
      if (res.data && res.data.status) {
        dispatch({
          type: GET_ORDER_ITEMS_SUCCESS,
          payload: res.data.data || [],
        });
        if (res.data.status === 458) {
          toast.error(res.data.message);
        }

        return res.data.data.value;
      }
      dispatch({
        type: GET_ORDER_ITEMS_FAILURE,
        payload: [],
      });
      return [];
    })
    .catch((error) => {
      dispatch({
        type: GET_ORDER_ITEMS_FAILURE,
        payload: [],
      });
      return [];
    });
};

export const buyNowOrderAction = (data) => (dispatch) => {
  dispatch({
    type: CREATE_CHECKOUT_START,
  });
  publicInstance
    .post(`order`, data)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success(res.data.message);
        dispatch({
          type: CREATE_CHECKOUT_SUCCESS,
          payload: res.data.data.value,
        });
        return res.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);
        dispatch({
          type: CREATE_CHECKOUT_FAILURE,
          payload: [],
        });
        return res.data;
      } else {
        toast.error(res.data.message);
        dispatch({
          type: CREATE_CHECKOUT_FAILURE,
          payload: [],
        });
        return 87;
      }
    })
    .catch((error) => {
      toast.error("API stopped responding. Please try later");
      return {};
    });
};

// TODO: Not yeat in use
export const addToOrderAction = async (data) => {
  return await publicInstance
    .post(`order`, data)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success(res.data.message);

        return res.data.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);

        return res.data.data;
      } else {
        toast.error(res.data.message);
        return {};
      }
    })
    .catch((error) => {
      toast.error("API stopped responding. Please try later");
      return {};
    });
};

export const deleteOrder = async (data) => {
  return await publicInstance
    .delete(`checkout?id=${data.id}`)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success("Item removed from cart!");
        return res.data.data;
      } else {
        if (res.data && res.data.status === 462) {
          toast.success("Item removed from cart!");
        } else {
          toast.error("Could not remove item!. Please try later");
        }
        return {};
      }
    })
    .catch((error) => {
      return {};
    });
};
export const modifyOrder = async (data) => {
  const postData = { ...data, status: 1 };
  return await publicInstance
    .put(`order`, postData)
    .then((res) => {
      if (res.data && res.data.status === 200) {
        toast.success("Order Updated Successfully");
        return res.data.data;
      } else if (res.data && res.data.status === 462) {
        toast.error(res.data.message);

        return res.data.data;
      } else {
        toast.error("Something went wrong!. Please try later");
        return {};
      }
    })
    .catch((error) => {
      return {};
    });
};

export const updateOrder = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ORDER,
    payload: data,
  });
};
export const clearOrder = (id) => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER,
  });
};
export const changeOrderItemCount =
  ({ productId, count }) =>
  (dispatch) => {
    dispatch({
      type: CHANGE_ORDER_ITEM_COUNT,
      payload: { productId, count },
    });
  };
