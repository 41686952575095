import { useState } from "react";
import { NavLink } from "react-router-dom";
import { getUserSession } from "../../../auth";
import { useTranslation } from "react-i18next";
import Preloader from "../../layout/preloader/Preloader";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import Cart from "../cart/Cart";

function MyOrdersAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <img src={spinnerImg} />
      ) : (
        <>
          <div className="container checkout_cart_wrapper">
            <Cart />
          </div>
        </>
      )}
    </>
  );
}

export default MyOrdersAccount;
