import React from "react";

const ButtonComponent = ({
  className,
  text,
  type,
  loader = false,
  onClick,
}) => {
  return (
    <button className={className} type={type} onClick={onClick}>
      {!loader ? `${text}` : <div className='spinner-border' />}
    </button>
  );
};

export default ButtonComponent;
