import classNames from "classnames";
import React from "react";

const InputGroupComponent = ({ className, children }) => {
  let invalidFound = false;
  children &&
    children.forEach((childeItem) => {
      const { isInvalid } = childeItem.props;

      if (isInvalid) {
        invalidFound = true;
      }
    });

  //   if (invalidFound) {
  //     return (
  //       <>
  //         <div className={classNames(className)}>{children}</div>
  //         {children[2]}
  //       </>
  //     );
  //   }

  return <div className={classNames(className)}>{children}</div>;
};

export default InputGroupComponent;
