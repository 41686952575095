import giftBanner from "../../../assets/img/gift_banner.png";
import preBanner from "../../../assets/img/pre_banner.png";
import customBanner from "../../../assets/img/custom_banner.png";
import useGetAuthData from "../../../hooks/useGetAuthData";
import Preloader from "../../layout/preloader/Preloader";
import React, { useState } from "react";
import { createRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartAction,
  updateCart,
} from "../../../redux/actions/cartActions";
import { langagesAction } from "../../../redux/actions/langagesAction";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";

// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
import ProductItems from "./ProductItems";
import Cart from "../cart/Cart";
const ProductsWeUse = (props) => {
  const dispatch = useDispatch();
  const [addToCartLoader, setAddToCartLoader] = useState(false);

  const userSession = getUserSession();

  const languages = useSelector((state) => state.allLangages.langages);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const inactiveLanguage = languages.find(
    (l) => l.code !== currentLanguageCode
  );

  const { error, isLoading, data } = useGetAuthData("products");
  const { productsdata } = useGetAuthData("products");

  const navigate = useNavigate();
  const inputRef = createRef();
  const [loader, setLoader] = useState(false);
  const [serviceArray, setServiceArray] = useState([]);
  const [giftType, setGiftType] = useState(1);
  const [giftPackageId, setGiftPackageId] = useState(0);
  const { title, subtitle, short_description, image } = data;
  // console.log(packageList);
  return (
    <>
      <div className="gift-wrapper  user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="container">
          <div className="gift_head">
            <div className="row gift_head_row">
              <div className="col-xs-12 col-md-12 col-lg-6 gift_head_text">
                <div className="gift_head_left">
                  {isLoading ? (
                    <Preloader />
                  ) : (
                    <>
                      <h1>
                        <span>{title}</span>
                      </h1>
                      <h4>{subtitle}</h4>
                      <p>{short_description}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-6 gift_head_image">
                <img src={giftBanner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gift_content_data">
        <div className="container ">
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 main_nav">
              <div className="accordion_wrapper">
                <section className="product_we_use_wrapper">
                  <>
                    {isLoading ? (
                      <Preloader />
                    ) : (
                      <>
                        <ProductItems products={data.products} />
                      </>
                    )}
                  </>
                </section>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsWeUse;
