import { createRef } from "react";

import {
  InputComponent,
  InputGroupComponent,
  InvalidFeedback,
} from "../../../form_element";
import { useTranslation } from "react-i18next";

export const ContactInfo = (props) => {
  const { t } = useTranslation();

  const {
    formData,
    setFormData,
    errorResponse,
    setErrorResponse,
    setErrorState,
    errorState,
    inputRef,
  } = props;
  // const inputRef = createRef();

  const handleInputChange = (e) => {
    setErrorResponse("");
    const objectName = e.target.name;
    console.log(objectName);
    setFormData({
      ...formData,
      [objectName]: e.target.value,
    });
    setErrorState({
      ...errorState,
      [objectName]: undefined,
    });
  };

  return (
    <div className="checkoutItems">
      <div className="d-flex bs-canvas-header side-cart-header">
        <h3 className="d-inline-block main-cart-title mb-0">
          {t("contact_information")}
        </h3>
      </div>
      <div className="container-fluid d-flex checkout_address_wrapper">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <form action="#" className="pt-3 input-white p-3">
              <div className="row pb-3">
                <div className="col-xs-12 col-sm-12">
                  {errorResponse && (
                    <p className="text-danger text-center">{errorResponse}</p>
                  )}
                </div>
                <div className="col-sm-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <InputComponent
                      // inputRef={inputRef}
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeHolder={t("first_name")}
                      inputClassName="form-control"
                      isInvalid={errorState?.firstName ? true : false}
                      onChange={handleInputChange}
                      value={
                        formData.firstName != "GUEST" ? formData.firstName : ""
                      }
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>{errorState?.firstName}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-sm-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <InputComponent
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeHolder={t("last_name")}
                      inputClassName="form-control"
                      isInvalid={errorState?.lastName ? true : false}
                      onChange={handleInputChange}
                      value={
                        formData.lastName != "GUEST" ? formData.lastName : ""
                      }
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>{errorState?.lastName}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-sm-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <InputComponent
                      id="email-user"
                      name="email"
                      type="email"
                      border="false"
                      placeHolder={t("email")}
                      inputClassName="form-control"
                      isInvalid={errorState?.email ? true : false}
                      onChange={handleInputChange}
                      value={formData.email}
                    />
                    <i className="uil uil-envelope lgn_icon"></i>
                    <InvalidFeedback>{errorState?.email}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-sm-6">
                  <InputGroupComponent className="form-group pos_rel checkout-login">
                    <InputComponent
                      id="phoneNumber"
                      name="phoneNumber"
                      type="phoneNumber"
                      placeHolder={t("phone")}
                      inputClassName="form-control"
                      isInvalid={errorState?.phoneNumber ? true : false}
                      onChange={handleInputChange}
                      value={formData.phoneNumber}
                    />
                    <i className="uil uil-mobile-android-alt lgn_icon"></i>
                    <InvalidFeedback>{errorState?.phoneNumber}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-xl-12 comment-block">
                  <InputGroupComponent className="">
                    <label>{t("customization_comments")}</label>
                    <textarea
                      //   style={{ minHeight: "90px" }}
                      rows={2}
                      type="text"
                      name="customerComment"
                      className="align-self-center"
                      onChange={handleInputChange}
                      value={formData.customerComment}
                    ></textarea>
                  </InputGroupComponent>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
