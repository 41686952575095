import dividerIcon from "../../../assets/img/home/devider-icon.png"
function Devider() {
  return (


    <div className="divider-style1 wow fadeIn">
      <div className="container text-center position-relative">
        <span className="divider-icon">
          {/* <i className="flaticon-lotus-flower"></i> */}
          <img src={dividerIcon} />
        </span>
      </div>
    </div>

  );
}

export default Devider;
