export const OrderPayment = (props) => {
    const { paymentMethod, formData, handleCheckbox, errorState } = props;
    console.log({ errorState: errorState.paymentMethod })

    return (
        <>
            <div className="row">
                <div className="invalidMethod">
                    {errorState?.paymentMethod}
                </div>
            </div>
            <div className="row">
                {paymentMethod.map((method, i) =>
                    <div className={`col-xl-${12 / paymentMethod.length}`} key={i}>
                        <input
                            type="checkbox"
                            name="paymentMethod"
                            id={`paymentMethod${i}`}
                            value={i + 1}
                            checked={i + 1 + "" === formData.paymentMethod}
                            onChange={handleCheckbox}
                        />
                        <label htmlFor={`paymentMethod${i}`}>
                            {method}
                        </label>
                    </div>
                )}
            </div>
        </>
    )
}