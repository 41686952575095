import axios from "axios";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import store from "../redux/store";
import cookies from "js-cookie";
import { getLocalSession } from "../auth/index.js";

// import i18next from "i18next";
import {
  // isAuthenticated,
  // loginAction,
  // registerAction,
  // setUserSession,
  getUserSession,
} from "../auth";
const useGetAuthData = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(null);
  const userSession = getUserSession();
  const currentLanguageCode = cookies.get("i18next") || "en";
  useEffect(() => {
    const abortCont = new AbortController();
    let isMounted = true;
    const config = {
      headers: {
        lang: currentLanguageCode === "ar" ? 2 : 1,
        authToken: userSession.authToken,
        sessionToken: getLocalSession(),
      },
    };
    const getData = async (dataUrl) => {
      try {
        const response = await axios.get(url, config, {
          signal: abortCont.signal,
        });
        if (isMounted) {
          setData(response.data.data.value);
          setisLoading(false);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          if (err.status == 401) {
            setError("Please login to continue");
          } else {
            setError(err.message);
          }
          setData([]);
        }
      } finally {
        if (isMounted) {
          setisLoading(false);
        }
      }
    };
    getData(url);
    const cleanUp = () => {
      isMounted = false;
      abortCont.abort();
    };
    return cleanUp;
  }, [url, currentLanguageCode, userSession.authToken]);

  return { data, isLoading, error };
};
export default useGetAuthData;
