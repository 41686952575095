import axios from "axios";
import { toast } from "react-toastify";
import { getLocalSession, getUserSession } from "../auth/index.js";
import { ACCESS_TOKEN, API_BASE_PATH } from "../helpers/config.js";
import cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";

const currentLanguageCode = cookies.get("i18next") || "en";
const langages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    id: 1,
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
    id: 2,
  },
];
const projectLanguage = langages.find((l) => l.code === currentLanguageCode);
console.log(projectLanguage);
const LanguageData = () => {
  // const languages = useSelector((state) => state.allLangages.langages);
  // const projectLanguage = langages.find((l) => l.code === currentLanguageCode);
  return 10;
};
export const publicInstance = axios.create({
  baseURL: API_BASE_PATH,
});
// with access token
publicInstance.interceptors.request.use(
  (request) => {
    console.log(projectLanguage);
    request.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Ashik: "*",
      accessToken: ACCESS_TOKEN,
      sessionToken: getLocalSession(),
      authToken: getUserSession().authToken || null,
      lang: projectLanguage.id,
    };
    return request;
  },
  (error) => Promise.reject(error)
);
// failure handling
publicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      toast.error("Login to continue");
    }

    Promise.reject(error);
  }
);
