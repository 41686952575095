import { TOGGLE_CART_DRAWER, TOGGLE_FILTER_DRAWER } from "../constants";

const sideDrawerReducer = (
  state = { filterDraw: false, cartDraw: false },
  action
) => {
  const { type } = action;
  switch (type) {
    case TOGGLE_FILTER_DRAWER:
      return {
        cartDraw: false,
        filterDraw: !state.filterDraw,
        overLay: !state.filterDraw,
      };
    case TOGGLE_CART_DRAWER:
      return {
        cartDraw: !state.cartDraw,
        filterDraw: false,
        overLay: !state.cartDraw,
      };

    default:
      return state;
  }
};
export default sideDrawerReducer;
