import React, { createRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import { useTranslation } from "react-i18next";

import orderPlacedSuccess from "../../../assets/img/success.png";
import orderPlacedFailed from "../../../assets/img/failed.png";
import useGetAuthData from "../../../hooks/useGetAuthData";
import Preloader from "../../layout/preloader/Preloader";
import { t } from "i18next";

const OrderPlacedSummery = () => {
  const { t } = useTranslation();

  // const [data, setData] = useState([]);
  // const [isLoading, setisLoading] = useState(true);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();
  let { q } = useParams();
  const authenticated = isAuthenticated();
  let orderId = "";
  let amount = "";
  let status = "";
  let transactionId = "";

  if (q) {
    const decoded = atob(q.toString());
    console.log("queryString ", decoded);
    const newparams = new URLSearchParams(decoded);
    orderId = newparams.get("orderId");
    status = newparams.get("status");
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="wrapper">
      {status && parseInt(status) == 1 ? (
        <Success orderId={orderId} transactionId={transactionId} />
      ) : (
        <Failed
          orderId={orderId}
          amount={amount}
          transactionId={transactionId}
        />
      )}
    </div>
  );
};

const Success = ({ orderId, transactionId }) => {
  // window.gtag('event', 'conversion', {'send_to': 'AW-722964367/gHLiCO-pieEBEI-f3tgC'});
  const { error, isLoading, data } = useGetAuthData(
    "order-summery?id=" + orderId
  );
  // console.log(data.id);
  // const { giftPage } = data;

  return (
    <div className="all-product-grid space pb-5">
      <div class="bgcircle"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div class="order-placed-dt">
              <div class="success_page text-center">
                <img src={orderPlacedSuccess} />
              </div>
              <h2> {t("order_successfully_placed")}</h2>
              <h3>
                {t("order_id")} :<span> #{data.id}</span>
              </h3>
              <p className="mt-2 text-center">{t("cancel_booking_warning")}</p>
              <div class="delivery-address-bg">
                <div class="delivery-address-bg-content">
                  <div class="title585">
                    <div class="pln-icon">
                      <i class="uil uil-telegram-alt"></i>
                    </div>
                    <h4 className="order_sumHead">
                      {t("your_order_details_follows")}{" "}
                      <i class="uil uil-tag-alt"></i>
                    </h4>
                  </div>
                  <ul class="address-placed-dt1 list-unstyled ">
                    {isLoading ? (
                      <Preloader />
                    ) : (
                      <>
                        <li>
                          <p>
                            <i class="uil uil-tag-alt"></i>
                            {t("order_status")} :
                            <span> {data.order_status_name}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="uil uil-tag-alt"></i>
                            {t("order_date")} :<span> {data.date}</span>
                          </p>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="clearfix"></div>
                </div>
                <div class="title585 mt-3">
                  <div class="pln-icon">
                    <i class="uil uil-telegram-alt"></i>
                  </div>
                  <h4 className="order_sumHead">
                    {t("order_items")} <i class="uil uil-tag-alt"></i>
                  </h4>
                </div>
                <div class="address-placed-dt1">
                  {data.order_products &&
                    data.order_products.map(
                      ({
                        id,
                        amount,
                        delivery_date,
                        delivery_time,
                        type,
                        giftType,
                        giftTypeName,
                        invoice,
                        items,
                        quantity,
                        returnStatus,
                        typeName,
                      }) => (
                        <>
                          <div className="row pdt_name">
                            <div className="col-6">
                              <strong>
                                {typeName}{" "}
                                {type == 1 ? " - " + giftTypeName : ""}
                              </strong>
                            </div>
                            <div className="col-6">
                              {/* <h4>Items</h4> */}
                              {items && items.length > 0 ? (
                                <ul className="kggrm-now checkout-atr-list list-unstyled p-0">
                                  {items.map(({ product_name }, idx) => (
                                    <>
                                      <li key={`${product_name}_${idx}`}>
                                        {product_name}
                                      </li>
                                      {type == 2 ? (
                                        <>
                                          {t("booking_number")} : #{id} <br />
                                          {t("date")} : {delivery_date} <br />
                                          {t("time")} : {delivery_time}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}

                  {transactionId && transactionId != "" ? (
                    <li>
                      <p>
                        <i class="uil uil-atm-card"></i> {t("transaction_id")}:
                        <span>#{transactionId}</span>
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
                <h4 className="mt-3 mb-1 text-center">
                  {t("sub_total")}:<span> QAR {data.sub_total}</span>
                </h4>
                <h4 className="mt-1 mb-1 text-center">
                  {t("delivery_charge")}:
                  <span> QAR {data.shipping_charge}</span>
                </h4>
                {data.coupon_amount && data.coupon_amount > 0 && (
                  <>
                    <h4 className="mt-1 mb-1 text-center">
                      {t("discount_coupon")}:
                      <span> QAR {data.coupon_amount}</span>
                    </h4>
                  </>
                )}
                <h3 className="mt-3">
                  {t("total_amount")}:<span> QAR {data.total_amount}</span>
                </h3>
                <div className="payData">
                  {data.payment_details &&
                  data.payment_details.payment_method > 0 ? (
                    <>
                      <h4 className="mt-2 ">
                        {t("payment_method")} :
                        <span> {data.payment_details.payment_method_name}</span>
                      </h4>
                      <h4 className="mt-2 ">
                        {t("payment_status")}:
                        <span> {data.payment_details.payment_status}</span>
                      </h4>
                      {data.payment_details &&
                      data.payment_details.payment_method == 2 ? (
                        data.payment_details &&
                        data.payment_details.payment_statusCode == 1 ? (
                          <h4 className="mt-2 ">
                            {t("transaction_id")}
                            <span> {data.payment_details.transaction_id}</span>
                          </h4>
                        ) : (
                          <>
                            <h4 className="mt-2 ">
                              {t("transaction_id")}
                              <span>
                                {" "}
                                {data.payment_details.transaction_id}
                              </span>
                            </h4>
                            <h4 className="mt-2 ">
                              {data.payment_details.payment_response_message &&
                              data.payment_details.payment_response_message !=
                                0 ? (
                                <>
                                  {t("payment_message")}
                                  <span>
                                    {" "}
                                    {
                                      data.payment_details
                                        .payment_response_message
                                    }
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </h4>
                          </>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div class="mb-0 login_btn_wrap orderSummeryBtn">
                  {data.payment_details &&
                  data.payment_details.payment_method == 2 ? (
                    data.payment_details.payment_statusCode == 1 ? (
                      <>
                        <p className="mt-2">{t("your_email_sent")}</p>
                        <p className="mt-2">{t("cancel_booking_warning")}</p>
                        <a
                          href={data.userType == 1 ? "/profile" : "/home"}
                          class="vs-btn vs-style1   form_button"
                        >
                          {data.userType == 1
                            ? "My Orders"
                            : "Continue Shopping"}
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          href={
                            data.payment_details &&
                            data.payment_details.paymentUrl
                          }
                          class="vs-btn vs-style1   form_button"
                        >
                          {t("retry_payment")}
                        </a>
                      </>
                    )
                  ) : (
                    <>
                      <p className="mt-2">{t("your_email_sent")}</p>
                      <a href="/profile" class="vs-btn vs-style1   form_button">
                        {t("my_account")}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Failed = ({ orderId, transactionId }) => {
  const { error, isLoading, data } = useGetAuthData(
    "order-summery?id=" + orderId
  );
  return (
    <div className="all-product-grid space pb-5">
      <div class="bgcircle"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div class="order-placed-dt">
              <div class="success_page">
                <img src={orderPlacedFailed} />
              </div>
              <h2 class="failed">{t("order_failed")}!</h2>
              <p className="text-center">{t("payment_failed_message")} </p>
              <h3 className="text-center">
                {t("order_id")}:<span> #{data.id}</span>
              </h3>
              <div class="delivery-address-bg">
                <div class="title585 mt-3">
                  <div class="pln-icon">
                    <i class="uil uil-telegram-alt"></i>
                  </div>
                  <h4 className="order_sumHead">
                    {t("order_items")} <i class="uil uil-tag-alt"></i>
                  </h4>
                </div>
                <div class="address-placed-dt1">
                  {data.order_products &&
                    data.order_products.map(
                      ({
                        id,
                        amount,
                        delivery_date,
                        delivery_time,
                        type,
                        giftType,
                        giftTypeName,
                        invoice,
                        items,
                        quantity,
                        returnStatus,
                        typeName,
                      }) => (
                        <>
                          <div className="row pdt_name">
                            <div className="col-6">
                              <strong>
                                {typeName}{" "}
                                {type == 1 ? " - " + giftTypeName : ""}
                              </strong>
                            </div>
                            <div className="col-6">
                              {/* <h4>Items</h4> */}
                              {items && items.length > 0 ? (
                                <ul className="kggrm-now checkout-atr-list list-unstyled p-0">
                                  {items.map(({ product_name }, idx) => (
                                    <>
                                      <li key={`${product_name}_${idx}`}>
                                        {product_name}
                                      </li>
                                      {items && items.type == 2 ? (
                                        <>
                                          {t("date")} : {delivery_date} <br />
                                          {t("time")} : {delivery_time}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}

                  {transactionId && transactionId != "" ? (
                    <li>
                      <p>
                        <i class="uil uil-atm-card"></i>
                        {t("transaction_id")}:<span>#{transactionId}</span>
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
                <h4 className="mt-3">
                  {t("sub_total")}:<span> QAR {data.sub_total}</span>
                </h4>
                <h4 className="mt-3">
                  {t("delivery_charge")}:
                  <span> QAR {data.shipping_charge}</span>
                </h4>
                {data.coupon_amount && data.coupon_amount > 0 && (
                  <>
                    <h4 className="mt-3">
                      {t("discount_coupon")}:
                      <span> QAR {data.coupon_amount}</span>
                    </h4>
                  </>
                )}

                <h3 className="mt-3">
                  {t("total_amount")}:<span> QAR {data.total_amount}</span>
                </h3>
                <div className="payData">
                  {data.payment_details &&
                  data.payment_details.payment_method > 0 ? (
                    <>
                      <h4 className="mt-2 ">
                        {t("payment_method")}:
                        <span> {data.payment_details.payment_method_name}</span>
                      </h4>
                      <h4 className="mt-2 ">
                        {t("payment_status")} :
                        <span> {data.payment_details.payment_status}</span>
                      </h4>
                      {data.payment_details &&
                      data.payment_details.payment_method == 2 ? (
                        data.payment_details &&
                        data.payment_details.payment_statusCode == 1 ? (
                          <h4 className="mt-2 ">
                            {t("transaction_id")}
                            <span> {data.payment_details.transaction_id}</span>
                          </h4>
                        ) : (
                          <>
                            <h4 className="mt-2 ">
                              {t("transaction_id")}
                              <span>
                                {" "}
                                {data.payment_details.transaction_id}
                              </span>
                            </h4>
                            <h4 className="mt-2 ">
                              {data.payment_details.payment_response_message &&
                              data.payment_details.payment_response_message !=
                                0 ? (
                                <>
                                  {t("payment_message")}
                                  <span>
                                    {" "}
                                    {
                                      data.payment_details
                                        .payment_response_message
                                    }
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </h4>
                          </>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div class="mb-0 login_btn_wrap orderSummeryBtn">
                  <p>{t("dont_worry_message")}</p>
                  <div class="placed-bottom-dt">
                    {t("you_may")}{" "}
                    <span>
                      <a
                        href={
                          data.payment_details &&
                          data.payment_details.paymentUrl
                        }
                      >
                        {t("click_here")}
                      </a>
                    </span>{" "}
                    {t("to_make_payment")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPlacedSummery;
