import React, { useEffect, useRef } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  InputGroupComponent,
  ButtonComponent,
  InputComponent,
  InvalidFeedback,
} from "../../form_element";
import { useState } from "react";
import {
  forgotPasswordAction,
  resetPasswordAction,
  verifyOtpAction,
} from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { decryptData } from "../../../helpers/helperFunctions";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const VerifyOTP = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();
  let userData = {};
  if (token) {
    userData = decryptData(token);
  }
  useEffect(() => {
    setFormData({
      ...formData,
      userId: userData && userData.user_id,
      email: userData && userData.email,
    });
  }, [token]);

  const otp1Ref = useRef(null);
  const otp2Ref = useRef(null);
  const otp3Ref = useRef(null);
  const otp4Ref = useRef(null);
  const otp5Ref = useRef(null);

  const [formData, setFormData] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    userId: "",
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");

  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("");
    }
  });

  const handleInputChange = (e) => {
    setErrorResponse("");

    if (e.target.value) {
      if (/^-?\d{1}$/.test(e.target.value)) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });

        switch (e.target.name) {
          case "otp1":
            otp2Ref.current.focus();

            break;
          case "otp2":
            otp3Ref.current.focus();

            break;
          case "otp3":
            otp4Ref.current.focus();

            break;
          case "otp4":
            otp5Ref.current.focus();

            break;

          default:
            break;
        }
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
    }

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    otp1Ref && otp1Ref.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      otp1: {
        required: true,
        type: "number",
      },
      otp2: {
        required: true,
        type: "number",
      },
      otp3: {
        required: true,
        type: "number",
      },
      otp4: {
        required: true,
        type: "number",
      },
      otp5: {
        required: true,
        type: "number",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await verifyOtpAction(formData);
      if (res) {
        if (res === 200) {
          toast.success(
            "OTP Verified Successfully. Now you can reset your Password."
          );
          navigate(`/reset-password/${token}`);
        } else {
          setErrorResponse(res);
        }
        setLoader(false);
      }
    } else {
      setErrorState(errors);
    }
  };
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const resendOtp = async (e) => {
    e.preventDefault();
    setErrorState({});
    setErrorResponse("");
    setSeconds(60);
    const res = await forgotPasswordAction({ email: formData.email });
    setFormData({
      ...formData,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
    });
  };
  return (
    <>
      <div className="signUp-wrapper user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="container">
          <div className="row justify-content-center auth_log_wrapper">
            <div className="col-sm-12 col-lg-7   col-xl-7">
              <form
                action="#"
                className="signUp-form bg-light-theme  pt-5 pb-5  pr-4 pl-4 input-white set_new_password"
              >
                <h2 className="form-title text-center">{t("varify_otp")}</h2>

                <p>{t("enter_one_time_password")}</p>
                {errorResponse && (
                  <p className="text-danger text-center">{errorResponse}</p>
                )}
                <InputGroupComponent className="form-group pos_rel">
                  <label className="control-label">{t("enter_otp")}</label>
                  <ul className="code-alrt-inputs signup-code-list otp_items">
                    <li>
                      <InputComponent
                        inputRef={otp1Ref}
                        id="otp1"
                        name="otp1"
                        type="text"
                        placeHolder=""
                        inputClassName="form-control input-md"
                        isInvalid={errorState.otp1 ? true : false}
                        onChange={handleInputChange}
                        value={formData.otp1}
                      />
                    </li>
                    <li>
                      <InputComponent
                        inputRef={otp2Ref}
                        id="otp2"
                        name="otp2"
                        type="text"
                        placeHolder=""
                        inputClassName="form-control input-md"
                        isInvalid={errorState.otp2 ? true : false}
                        onChange={handleInputChange}
                        value={formData.otp2}
                      />
                    </li>
                    <li>
                      <InputComponent
                        inputRef={otp3Ref}
                        id="otp3"
                        name="otp3"
                        type="text"
                        placeHolder=""
                        inputClassName="form-control input-md"
                        isInvalid={errorState.otp3 ? true : false}
                        onChange={handleInputChange}
                        value={formData.otp3}
                      />
                    </li>
                    <li>
                      <InputComponent
                        inputRef={otp4Ref}
                        id="otp4"
                        name="otp4"
                        type="text"
                        placeHolder=""
                        inputClassName="form-control input-md"
                        isInvalid={errorState.otp4 ? true : false}
                        onChange={handleInputChange}
                        value={formData.otp4}
                      />
                    </li>
                    <li>
                      <InputComponent
                        inputRef={otp5Ref}
                        id="otp5"
                        name="otp5"
                        type="text"
                        placeHolder=""
                        inputClassName="form-control input-md"
                        isInvalid={errorState.otp5 ? true : false}
                        onChange={handleInputChange}
                        value={formData.otp5}
                      />
                    </li>
                  </ul>
                  {seconds > 0 ? (
                    <span className="resend-link">
                      {t("expire_otp_in")} {seconds} {t("seconds")}
                    </span>
                  ) : (
                    <a href="/" className="resend-link" onClick={resendOtp}>
                      Resend OTP{" "}
                    </a>
                  )}
                </InputGroupComponent>

                <div className="mb-0 mb-0 text-center">
                  <ButtonComponent
                    className="vs-btn vs-style1  form_button forgot_next"
                    type="submit"
                    text={t("varify_otp")}
                    loader={loader}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
