import roseImage from "../../../assets/img/banner/rose.png";
import { t } from "i18next";
import { Link } from "react-router-dom";
const BannerPage = (props) => {
  return (
    <>
      <section className="gift-wrapper  user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="gift-wrapper-data">
          <div className="container">
            <div className="gift_head">
              <div className="row gift_head_row">
                <div className="col-xs-12 col-md-12 col-lg-6 gift_head_text">
                  <div className="gift_head_left">
                    {/* {isLoading ? (
                    <Preloader />
                  ) : ( */}
                    <>
                      <h1>
                        <span>{props.title}</span>
                      </h1>
                      <h4>{props.subtitle}</h4>
                      <p>{props.description}</p>
                    </>
                    {/* // )} */}
                  </div>
                  <div className="hero-btn ls-l c-btn-make-appointment slider-btn">
                    <div className="service-category btn-make-appointment">
                      <Link to={"/booking"} state={{ from: "/booking" }}>
                        <span className="vs-btn-text">
                          {" "}
                          {t("make_an_appointment")}
                        </span>
                        <span className="vs-btn-icon">
                          <i className="far fa-arrow-right"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6 gift_head_image">
                  <img src={props.image} alt="" />
                </div>
              </div>
              {/* <div className="row">
                <div className="hero-btn ls-l c-btn-make-appointment slider-btn">
                  <div className="service-category btn-make-appointment">
                    <Link to={"/booking"} state={{ from: "/booking" }}>
                      {" "}
                      {t("make_an_appointment")}
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <span className="bottom_cover"></span> */}
        </div>
      </section>
    </>
  );
};

export default BannerPage;
