import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BookingPopup(props) {
  const [show, setShow] = useState(props.show);
  const [itemData, Setitemdata] = useState({});
  const { t } = useTranslation();

  const handleClose = () => {
    props.updateShow(false);
    setShow(false);
  };
  useEffect(() => {
    setShow(props.show);
    Setitemdata(props.data);
  }, [props]);
  console.log(itemData);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        className="order_modal"
        onHide={props.onHide}
      >
        {itemData && (
          <>
            <Modal.Header>
              <Modal.Title>
                {props.title} - #{itemData.id}
                <h4>{itemData.booking_status_name}</h4>
              </Modal.Title>
              <button
                type="button"
                class="btn-close"
                onClick={props.onHide}
                aria-label="Close"
              >
                <i class="fa fa-times"></i>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-2">
                  <img
                    src={
                      process.env.REACT_APP_API_BASIC_URL + "/" + itemData.image
                    }
                    className="item_img"
                    alt={itemData.title}
                  />
                </div>
                <div className="col-lg-6">
                  <h3>{itemData.title}</h3>
                  <h5 className="mt-3">
                    <i class="fa fa-calendar-alt"></i> {itemData.date}
                  </h5>
                  <h5 className="mt-3">
                    {t("service_provider")} : {itemData.therapist}
                  </h5>
                  <h5 className="mt-3">
                    {t("payment_method")} : {itemData.payment_method_name}
                  </h5>
                </div>
                <div className="col-lg-4 popflex">
                  <h5 className="mt-3">
                    {" "}
                    {t("order_ref")} : {itemData.order_id}
                  </h5>{" "}
                  <h5 className="mt-3">
                    <i class="fa fa-clock"></i> {itemData.time}
                  </h5>
                  <h5 className="mt-3">
                    {t("payment_status")} : {itemData.payment_status}
                  </h5>
                  <h5 className="mt-3">
                    {" "}
                    {t("payment_ref")} : {itemData.transaction_id}
                  </h5>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                {itemData.contactInfo && (
                  <div className="col-sm-12 col-md-12 mb-5">
                    <h3 className="mb-4"> {t("billing_details")}</h3>

                    <h5 className="mt-2">
                      {t("name")} :{" "}
                      {itemData.contactInfo.first_name +
                        "" +
                        itemData.contactInfo.last_name}
                    </h5>
                    <h5 className="mt-2">
                      {t("phone")} : {itemData.contactInfo.phone_number}
                    </h5>
                    <h5 className="mt-2">
                      {t("email")}: {itemData.contactInfo.email}
                    </h5>
                  </div>
                )}

                <div className="col-12 text-center">
                  {itemData.invoice && itemData.invoice.invoice && (
                    <a
                      href={
                        process.env.REACT_APP_API_BASIC_URL +
                        "/" +
                        itemData.invoice.invoice_file
                      }
                      target="_blank"
                      className="popfooterBtn"
                      variant="secondary"
                      onClick={props.onHide}
                    >
                      {t("invoice")}
                    </a>
                  )}
                  <Button
                    className="popfooterBtn"
                    variant="primary"
                    onClick={props.onHide}
                  >
                    {t("close")}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default BookingPopup;
