import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getUserSession } from "../../../auth";
import { useTranslation } from "react-i18next";
import Preloader from "../../layout/preloader/Preloader";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import OrderPopup from "../../layout/popup/OrderPopup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { privateInstance } from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
function MyOrdersAccount() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const {
    error,
    isLoading,
    data: orderList,
  } = useGetDataPrivate("order", projectLanguage.id);

  console.log(orderList);
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [orderData, setOrderData] = useState({});
  useEffect(() => {
    setOrderData(orderList);
  }, [orderList]);
  const [modalShow, setModalShow] = useState(false);
  const submit = (order_product_id) => {
    confirmAlert({
      title: "Cancel Order",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            return await privateInstance
              .post("cancel-order", {
                order_product_id: order_product_id,
              })
              .then((res) => {
                if (res.data.status === 200) {
                  setOrderData(res.data.data.value);
                  return res.data;
                } else {
                  toast.success(res.data.message);
                  return res.data.message;
                }
              })
              .catch((error) => {
                return "API error";
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            // alert("Click No");
          },
        },
      ],
    });
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center col-md-12 col-12">
          {" "}
          <img src={spinnerImg} />{" "}
        </div>
      ) : (
        <>
          <div className="container container-accordion-account-m">
            {orderData ? (
              <table className="table_account">
                <thead>
                  <tr>
                    <th className="col-2">{t("order_id")}</th>
                    <th className="col-3">{t("order_date")}</th>
                    <th className="col-2 app-number">{t("package")}</th>
                    <th className="col-3">{t("order_status")}</th>
                    <th className="col-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((order) => {
                    return (
                      <tr
                        key={order.id}
                        className={
                          order.order_status == 1
                            ? "appoint-pending"
                            : order.order_status == 2
                            ? "appoint-cancled"
                            : ""
                        }
                      >
                        <td>{order.order_id}</td>
                        <td>{order.date}</td>
                        <td>{order.title}</td>
                        <td>{order.order_status_name}</td>
                        <td>
                          <i
                            onClick={() => {
                              setModalShow(true);
                              setData(order);
                            }}
                            className="far fa-eye account d-inline-block mr-2"
                          ></i>
                          {order.cancelEligibility &&
                          order.cancelEligibility == 1 ? (
                            <i
                              onClick={() => {
                                submit(order.id);
                              }}
                              className="fal fa-times-circle account d-inline-block"
                            ></i>
                          ) : (
                            ""
                          )}
                          {/* <i className="fal fa-times-circle account d-inline-block"></i> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">{t("no_order_found")} </div>
            )}
          </div>
        </>
      )}
      <OrderPopup
        title={t("order")}
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default MyOrdersAccount;
