import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getUserSession } from "../../../auth";
import { useTranslation } from "react-i18next";
import Preloader from "../../layout/preloader/Preloader";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";
import useGetDataPrivate from "../../../hooks/useGetDataPrivate";
import BookingPopup from "../../layout/popup/BookingPopup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { privateInstance } from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
function AppointmentAccount() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const {
    error,
    isLoading,
    data: appointmentList,
  } = useGetDataPrivate("booking", projectLanguage.id);

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [bookingList, setBookingList] = useState({});
  useEffect(() => {
    setBookingList(appointmentList);
  }, [appointmentList]);
  const [modalShow, setModalShow] = useState(false);
  const submit = (order_product_id) => {
    confirmAlert({
      title: "Cancel Booking",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            return await privateInstance
              .post("booking-cancel", {
                order_product_id: order_product_id,
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toast.success(res.data.message);
                  console.log(res.data.data);
                  setBookingList(res.data.data.value);
                  return res.data;
                } else {
                  toast.success(res.data.message);
                  return res.data.message;
                }
              })
              .catch((error) => {
                return "API error";
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            // alert("Click No");
          },
        },
      ],
    });
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center col-md-12 col-12">
          {" "}
          <img src={spinnerImg} />{" "}
        </div>
      ) : (
        <>
          <div className="container container-accordion-account-m">
            {appointmentList ? (
              <table className="table_account">
                <thead>
                  <tr className="">
                    <th className="col-3">{t("date_time")}</th>
                    <th className="col-3 app-number">{t("booking_number")}</th>
                    <th className="col-2">{t("therapist")}</th>
                    <th className="col-2">{t("remark")}</th>
                    <th className="col-1"></th>
                  </tr>
                </thead>

                <tbody>
                  {bookingList &&
                    bookingList.map((appoint) => {
                      return (
                        <tr
                          key={appoint.order_id}
                          className={
                            appoint.booking_status == 1
                              ? "appoint-pending"
                              : appoint.booking_status == 2
                              ? "appoint-cancled"
                              : ""
                          }
                        >
                          <td>
                            {appoint.date} {appoint.time}
                          </td>
                          <td>{appoint.order_id}</td>
                          <td>{appoint.therapist}</td>
                          <td>{appoint.booking_status_name}</td>
                          <td>
                            <i
                              onClick={() => {
                                setModalShow(true);
                                setData(appoint);
                              }}
                              className="far fa-eye account d-inline-block mr-2"
                            ></i>

                            {appoint.cancelEligibility &&
                            appoint.cancelEligibility == 1 ? (
                              <i
                                onClick={() => {
                                  submit(appoint.id);
                                }}
                                className="fal fa-times-circle account d-inline-block"
                              ></i>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">No Data found</div>
            )}

            <div className="service-category btn-make-appointment text-center mt-70 mb-20">
              <Link to={"/booking"}> {t("make_an_appointment")} </Link>
            </div>
          </div>
        </>
      )}
      <BookingPopup
        title={t("booking")}
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default AppointmentAccount;
