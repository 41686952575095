import {
  InputComponent,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import roseImage from "../../../assets/img/banner/rose.png";

import Email from "../../../assets/img/contact/Email.png";
import Phone from "../../../assets/img/contact/Phone.png";
import Whatsapp from "../../../assets/img/contact/Whatsapp.png";
import EmailMob from "../../../assets/img/contact/Emailmob.png";
import PhoneMob from "../../../assets/img/contact/Phonemob.png";
import WhatsappMob from "../../../assets/img/contact/Whatsappmob.png";

import { privateInstance } from "../../../axios";
import { useSelector, useDispatch } from "react-redux";

import feuille from "../../../assets/img/contact/feuille.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next from "i18next";
import useGetData from "../../../hooks/useGetData";

const Contact = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name);
    return await privateInstance
      .post("contact-messages", {
        name: name,
        email: email,
        message: message,
        phonenumber: phone,
      })
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setName("");
          setPhone("");
          setEmail("");
          setMessage("");

          return res.data;
        } else {
          return res.data.message;
        }
      })
      .catch((error) => {
        return "API error";
      });
  };
  const { error, isLoading, data } = useGetData("settings", projectLanguage.id);
  return (
    <section className="contact_wrapper vs-hero-wrapper vs-hero-layout1 bg-light-theme space">
      <div
        className="slider contact_content"
        style={{ height: "800px", minHeight: "700px" }}
      >
        <div
          className="bg-theme position-absolute c-img-slider "
          style={{ top: "-130px", opacity: "0.1", borderRadius: "50%" }}
        >
          {" "}
        </div>

        <img
          className="ls-l roseimg1"
          src={roseImage}
          alt="Rose Icons"
          style={{ width: "60px", height: "60px" }}
        />
        <img
          className="ls-l roseimg2"
          src={roseImage}
          alt="Rose Icons"
          style={{ width: "60px", height: "60px" }}
        />
        <img className="ls-l feuille" src={feuille} alt="feuille" />

        <div className="container" id="bloccontact">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <h2 className="contactus p-0">{/* {t("contactUs")} */}</h2>
            </div>
            <div className="col-md-12 col-lg-12" id="vs-contact-box2">
              <form className="p-4" onSubmit={handleSubmit}>
                <center>
                  <div className="formtitle contact-sub-title pb-4">
                    {" "}
                    {/* {t("enquiry")} */}
                    {t("contactUs")}
                  </div>
                </center>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <InputGroupComponent
                      id="inputs"
                      className="form-group pos_rel"
                    >
                      <InputComponent
                        id="name"
                        name="name"
                        type="name"
                        placeHolder={t("name")}
                        inputClassName="form-control inputs"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <i className="uil uil-envelope lgn_icon"></i>
                    </InputGroupComponent>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <InputGroupComponent
                      id="inputs"
                      className="form-group pos_rel"
                    >
                      <InputComponent
                        id="phone"
                        name="phone"
                        type="phone"
                        placeHolder={t("phone")}
                        inputClassName="form-control inputs"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                      />
                      <i className="uil uil-envelope lgn_icon"></i>
                    </InputGroupComponent>
                  </div>
                </div>

                <div className="col-13">
                  <InputGroupComponent
                    id="inputs"
                    className="form-group pos_rel"
                  >
                    <InputComponent
                      id="email"
                      name="email"
                      type="email"
                      placeHolder={t("email")}
                      inputClassName="form-control inputs"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <i className="uil uil-envelope lgn_icon"></i>
                  </InputGroupComponent>
                </div>

                <div className="col-13">
                  <InputGroupComponent
                    id="inputs"
                    className="form-group pos_rel margin-bottom-null"
                  >
                    <textarea
                      id="message"
                      name="message"
                      type="message"
                      placeHolder={t("message")}
                      inputClassName="form-control lgn_input"
                      rows="5"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                    <i className="uil uil-envelope lgn_icon"></i>
                  </InputGroupComponent>
                </div>
                <div className="d-flex justify-content-center pt-4">
                  <ButtonComponent
                    className=" contact-button form_button"
                    type="submit"
                    text={t("submit")}
                  />
                </div>
              </form>
            </div>
          </div>
          {isLoading ? (
            <></>
          ) : (
            <div className="row" id="concontact">
              <div className="col-sm-12 col-md-12 col-lg-6 divnum1 d-flex align-items-center contact_links">
                <img
                  className="iconph conticon"
                  src={Phone}
                  alt="Phone"
                  style={{ width: "35px", height: "35px" }}
                />{" "}
                <img
                  className="iconph conticonmob"
                  src={PhoneMob}
                  alt="Phone"
                  style={{ width: "35px", height: "35px" }}
                />
                {/* <i class="fa fa-phone-volume"></i> */}
                <a href={"tel:" + data.settings.phone}>{data.settings.phone}</a>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 divnum2 d-flex align-items-center contact_links">
                <img
                  className="iconwha conticon"
                  src={Whatsapp}
                  alt="Whatsapp"
                  style={{ width: "35px", height: "35px" }}
                />{" "}
                <img
                  className="iconwha conticonmob"
                  src={WhatsappMob}
                  alt="Whatsapp"
                  style={{ width: "35px", height: "35px" }}
                />{" "}
                {/* <i class="fab fa-whatsapp"></i> */}
                <a
                  aria-label="Chat on WhatsApp"
                  href={"https://wa.me/" + data.settings.whatsapp}
                >
                  {" "}
                  {data.settings.whatsapp}
                </a>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 divnum3 d-flex align-items-center contact_links">
                <img
                  className="iconwha conticon"
                  src={Email}
                  alt="Email"
                  style={{ width: "35px", height: "25px" }}
                />{" "}
                <img
                  className="iconwha conticonmob"
                  src={EmailMob}
                  alt="Email"
                  style={{ width: "35px", height: "25px" }}
                />{" "}
                {/* <i class="fa fa-envelope-square"></i> */}
                <a aria-label="Mail TO" href={"mailto:" + data.settings.email}>
                  {data.settings.email}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
