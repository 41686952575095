import { useState } from "react";
import { useEffect } from "react";

import { Link } from "react-router-dom";
import { InputComponent, ButtonComponent } from "../../../form_element";
import { OrderPayment } from "./OrderPayment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const FooterOrderDrawer = (props) => {
  const {
    shippingCharge,
    subTotal,
    total,
    handleCheckout,
    paymentMethod,
    couponCode,
    couponAmount,
    handleCoupon,
    formData,
    handleCheckbox,
    errorState,
    loader,
    cartItems,
    data,
  } = props;
  const { t } = useTranslation();
  console.log(couponCode);
  const [coupon, setCoupon] = useState(couponCode || "");
  const [dataloader, setDataloader] = useState(false);

  useEffect(() => {
    if (data.cartItems) {
      setDataloader(true);
    }
  }, [data]);
  useEffect(() => {
    setCoupon(couponCode);
  }, [couponCode]);
  const applyCoupon = () => {
    if (coupon !== "") {
      handleCoupon(coupon);
    } else {
      toast.error("Coupon code required");
    }
    setCoupon("");
  };
  const removeCoupon = () => {
    handleCoupon("");
  };
  console.log(dataloader);
  if (!dataloader) {
    return (
      <>
        <div className="">{/* <h3>Loading.....</h3> */}</div>
      </>
    );
  }
  return (
    <>
      <div ashik={"ashik"} hello={loader} className="bs-canvas-footer p-3">
        {cartItems?.length > 0 && (
          <>
            <div className="main-sub-total-cart d-flex flex-column checkout_side_wrapper">
              {subTotal && (
                <div className="d-flex justify-content-between">
                  <h6>{t("sub_total")}</h6>
                  <h6>{subTotal} QAR</h6>
                </div>
              )}
              {shippingCharge && shippingCharge > 0 ? (
                <div className="d-flex justify-content-between">
                  <h6>{t("delivery_charge")}</h6>
                  <h6>{shippingCharge} QAR</h6>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-between">
                <h6>
                  {t("discount_coupon")}
                  {couponCode && (
                    <>
                      ( {couponCode})
                      <span className="remove_coupon" onClick={removeCoupon}>
                        Remove
                      </span>
                    </>
                  )}
                </h6>
                <h6> {couponAmount && couponAmount + " QAR"} </h6>
              </div>
              <div className="d-flex justify-content-between mb-5 coupon_wrapper">
                <InputComponent
                  id="coupon"
                  name="coupon"
                  type="coupon"
                  inputClassName="form-control cart-coupon-input mr-4"
                  // isInvalid={errorState.coupon ? true : false}
                  onChange={(e) => setCoupon(e.target.value)}
                  value={coupon}
                />
                <div
                  onClick={applyCoupon}
                  className="cart-coupon-btn vs-btn align-items-center cursor"
                >
                  {t("apply")}
                </div>
              </div>
            </div>

            {total && (
              <div className="main-total-cart">
                <h2>{t("total")}</h2>
                <h2>{total} QAR</h2>
              </div>
            )}
            {paymentMethod && (
              <OrderPayment
                {...{ paymentMethod, formData, handleCheckbox, errorState }}
              />
            )}
          </>
        )}
        {total && cartItems?.length > 0 ? (
          <div className="checkout-cart text-center service-category btn-add-booking">
            {/* <Link
            onClick={handleCheckout}
            // to="/checkout"
            className="cart-checkout-btn hover-btn"
            loader={loader}
          >
            {t("order_now")}
          </Link> */}
            {/* <p>{loader ? "ashik" : "ali"}</p> */}
            <ButtonComponent
              className="vs-btn vs-style1  form_button"
              type="submit"
              text={t("order_now")}
              loader={loader}
              onClick={handleCheckout}
              // onClick={() => {
              //   setLoader(true);
              //   handleCheckout();
              // }}
            />
          </div>
        ) : (
          <>
            <div className="checkout-cart text-center service-category btn-add-booking continue_shopping">
              <h6>Your cart is empty</h6>

              <a
                href="home"
                class="vs-btn vs-style1  form_button"
                type="submit"
              >
                Continue Shopping
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
};
