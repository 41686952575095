import useGetData from "../../../hooks/useGetData";
import HighlitedService from "../service/HighlitedService";
import HomeAbout from "../home/HomeAbout";
import Appointment from "../appointment/Appointment";
import Preloader from "../../layout/preloader/Preloader";
import SliderComponent from "../../layout/slider/SliderComponent2";
import PackageHome from "../../pages/package home/PackageHome";
import Devider from "../../layout/devider/Devider";
import ProductsWeUseHome from "../../pages/product/ProductsWeUseHome";
import FollowUs from "../../pages/follow us/FollowUs";

import { useEffect, useState } from "react";
import store from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import i18next from "i18next";
import InstagramEmbed from "react-instagram-embed";
import { useTranslation } from "react-i18next";

function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Currentlanguage = useSelector(
    (state) => state.allLangages.currentLangage
  );
  const currentLanguageCode = cookies.get("i18next") || "en";
  const languages = useSelector((state) => state.allLangages.langages);
  const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [clang, setCLang] = useState(Currentlanguage);
  const [myLanguage, setMyLanguage] = useState({});
  store.subscribe(() => {
    setCLang(Currentlanguage);
  });
  useEffect(() => {
    // setMyLanguage(projectLanguage);
  }, []);
  const { error, isLoading, data } = useGetData("home", projectLanguage.id);
  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <SliderComponent
            banner={data.banner}
            workingHours={data.working_hours}
          />
          {/* <p> {t("my_cart")} - </p> */}
          {/* <HighlitedService featured={data.featured}></HighlitedService> */}
          <HomeAbout
            title={data.home_page_about.title}
            description={data.home_page_about.description}
          />
          <Appointment
            makeAppointment={data.make_appointment}
            serviceList={data.service_list}
            serviceListAll={data.service_list_all}
            homePageServices={data.home_page_services}
          />
          <PackageHome />
          <Devider />
          <ProductsWeUseHome
            product_we_use={data.product_we_use}
            title={data.product_we_use.title}
            description={data.product_we_use.description}
          />
          <FollowUs />

          {/* <InstagramEmbed
            url="https://instagr.am/p/Zw9o4/"
            clientAccessToken="c79cd2dd2de97469df1126ecb67a675f|6333870646676869"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          /> */}
        </>
      )}
    </>
  );
}

export default Home;
