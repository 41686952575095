import SliderSlick from "react-slick";
import { t } from "i18next";
import { Link } from "react-router-dom";

function ServiceItem(props) {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-right btn-next"}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-left btn-prev"}
        onClick={onClick}
      />
    );
  }

  let initialLength;
  initialLength = props.items.length < 4 ? props.items.length : 4;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // slidesToShow: initialLength,
    // slidesToScroll: initialLength,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderSlick
      className=" pt-5 pb-5 pl-4 pr-4 service_item_slider_wrapper"
      {...settings}
    >
      {props.items.map((service, i) => {
        return (
          <>
            <div
              key={i}
              className={` row position-relative  service_item_wrapper_data service-tab-container-${
                i + 1
              }`}
            >
              <div className="col-md-12 col-lg-3">
                <img
                  src={
                    process.env.REACT_APP_API_BASIC_URL + "/" + service.image
                  }
                />
              </div>
              <div className="col-md-12 col-lg-6 d-flex flex-column ">
                <div className="title-service font-b mb-3">{service.title}</div>
                <div className="description-service mb-4">
                  {service.description}
                </div>
                <div className="time-service">
                  <i className="far fa-clock mr-2"></i>
                  {service.time}
                </div>
              </div>
              <div className="col-md-12 col-lg-3 serviceItemBookNow">
                <Link
                  to={"/booking/" + service.type}
                  state={{ from: "/booking/" + service.type }}
                  className="vs-btn vs-style1 btn-book-now-service  align-self-center"
                >
                  <span className="vs-btn-text"> {t("book_now")}</span>
                </Link>
              </div>
            </div>
          </>
        );
      })}
    </SliderSlick>
  );
}

export default ServiceItem;
