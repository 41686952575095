import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { logoutAction, refreshLocalSession } from "../../../auth";

const Logout = (props) => {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    doLogout();
  }, []);

  const doLogout = async () => {
    const logoutRes = await logoutAction();
    if (logoutRes) {
      setTimeout(() => {
        toast.success("You have been logged out");
      }, 1000);
    }
    setLoader(false);
    refreshLocalSession();
    window.location.href = "/home";
  };

  if (loader) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className="spinner-border" />
      </div>
    );
  }

  return <Navigate to="/" />;
};

export default Logout;
