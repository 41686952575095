import React, { useState, useRef } from "react";
import { createRef, useEffect } from "react";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { useCookies } from "react-cookie";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
function CustomePackageGiftItemComponent(props) {
  const inputRef = createRef();

  const [activeTab, setActiveTab] = useState("cust0");
  const [errorState, setErrorState] = useState({});
  // const [clearData, setCleardata] = useState(props.emptydata || 0);
  const [customPkgItems, setCustomPkgItems] = useState([]);
  const [statusCheck, setStatusCheck] = useState(false);
  const accordElem = useRef(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleAccordion = (id) => {
    // alert(id);
    setTimeout(() => {
      //document.querySelector(id).scrollTo({ top: 100, behavior: "smooth" });
      window.scrollTo(0, 850);
    }, 100);
  };
  // const resetRadio = (e) => {
  //   alert();
  //   setCustomPkgItems([]);
  //   setStatusCheck(true);
  // };
  const handleInputChange = (e) => {
    // setErrorResponse("");
    // setFormData({
    //   ...formData,
    //   [e.target.name]: e.target.value,
    // });
    // setErrorState({
    //   ...errorState,
    //   [e.target.name]: "",
    // });
  };
  useEffect(() => {
    if (props.emptydata == 1) {
      setCustomPkgItems([]);
      props.updateEmptyData(0);
    }
  }, [props.emptydata]);
  const addcustompackage = (service_id, package_id, val) => {
    // console.log("Here i am");
    // alert("Here");
    customPkgItems[service_id] = customPkgItems[service_id] || [];
    customPkgItems[service_id][package_id] =
      customPkgItems[service_id][package_id] || [];
    // const serArray = [];
    //  const[service_id]][package_id] = val;
    //let items = [[{ 0: 23 }]];

    // let items = { [service_id]: { [package_id]: val } };
    // setCustomPkgItems({ [service_id]: { [package_id]: val } });
    customPkgItems[service_id][package_id] = val;
    console.log(customPkgItems);
    console.log(customPkgItems[service_id][package_id]);
    setCustomPkgItems(
      // ...customPkgItems,
      customPkgItems
    );
    // const iterator = customPkgItems.values();

    props.updateCustompackageId(customPkgItems);
    // console.log(customPkgItems);
  };
  const data = [
    { title: "Title 1", content: "Lorem ipsum dolor sit amet." },
    { title: "Title 2", content: "Lorem ipsum dolor sit amet." },
    { title: "Title 3", content: "Lorem ipsum dolor sit amet." },
  ];
  return (
    <>
      <section className="custome-package-item-wrapper">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 secondery_nav">
            <Accordion className="hide-l show-m">
              {props.customePackages.length &&
                props.customePackages.map((customeData, index) => (
                  <AccordionItem
                    key={index}
                    isActive={false}
                    // isActive={index === 0 ? true : false}
                  >
                    {({ open }) => (
                      <>
                        <AccordionHeader
                          onClick={(e) => {
                            e.preventDefault();
                            toggleAccordion("#custom_package_head" + index);
                          }}
                          className={
                            customeData.type == 1
                              ? "custom_accordion_head"
                              : "custom_accordion_head other_voucher"
                          }
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.image
                            }
                            className="ls-l img-slider imgPackage"
                            alt={"Spa Girl " + index}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.imageHover
                            }
                            className="ls-l img-slider imgPackageHover"
                            alt={"Spa Girl " + index}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />

                          <h4
                            className={`accordion-title ${
                              open ? "" : ""
                              // open ? "accordion-active" : ""
                            }`}
                          >
                            {customeData.title}
                          </h4>

                          <i
                            className={`fa-2x far float-right mr-4 
                                                    ${
                                                      open
                                                        ? " fa-chevron-up color-white"
                                                        : // ? "accordion-active fa-chevron-up color-white"
                                                          "fa-chevron-down"
                                                    }`}
                          ></i>
                        </AccordionHeader>

                        <AccordionBody>
                          <div
                            className="accordion-body"
                            id={"custom_package_head" + index}
                          >
                            <TabPane tabId={"cust" + index}>
                              <Row>
                                <Col sm="12">
                                  <div className="custom-contents">
                                    <>
                                      {customeData.type == 1 ? (
                                        <>
                                          {customeData.getServicesTypeData &&
                                          customeData.getServicesTypeData
                                            .length > 0
                                            ? customeData.getServicesTypeData.map(
                                                (
                                                  getServicesTypeDataItems,
                                                  idx
                                                ) => (
                                                  <div className="serviceItemWrap">
                                                    <h4 className="setviceItemTitle">
                                                      {
                                                        getServicesTypeDataItems.title
                                                      }
                                                    </h4>
                                                    {getServicesTypeDataItems.items &&
                                                    getServicesTypeDataItems
                                                      .items.length > 0
                                                      ? getServicesTypeDataItems.items.map(
                                                          (
                                                            {
                                                              image,
                                                              price,
                                                              title,
                                                              id,
                                                              voucher,
                                                            },
                                                            idx
                                                          ) => (
                                                            <div
                                                              className="row align-items-center mb-3 cus_item_row"
                                                              key={
                                                                "cusItem" + idx
                                                              }
                                                            >
                                                              <div className="col-xs-12 col-sm-12 col-md-1 d-none d-lg-block">
                                                                <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                                                                  <label>
                                                                    <InputComponent
                                                                      inputRef={
                                                                        inputRef
                                                                      }
                                                                      id={
                                                                        "serviceIdType" +
                                                                        getServicesTypeDataItems.id +
                                                                        "Id" +
                                                                        customeData.id
                                                                      }
                                                                      name={
                                                                        "serviceIdType" +
                                                                        getServicesTypeDataItems.id +
                                                                        "Id" +
                                                                        customeData.id
                                                                      }
                                                                      type="radio"
                                                                      inputClassName="form-control lgn_input ali"
                                                                      isInvalid={
                                                                        errorState.email
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={() =>
                                                                        addcustompackage(
                                                                          customeData.id,
                                                                          getServicesTypeDataItems.id,
                                                                          id
                                                                        )
                                                                      }
                                                                      mydata={
                                                                        "Ashikali"
                                                                      }
                                                                      checked={
                                                                        customPkgItems[
                                                                          customeData
                                                                            .id
                                                                        ] === id
                                                                      }
                                                                      value={id}
                                                                    />
                                                                    <div className="check"></div>
                                                                  </label>

                                                                  {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                                  <InvalidFeedback>
                                                                    {
                                                                      errorState.email
                                                                    }
                                                                  </InvalidFeedback>
                                                                </InputGroupComponent>
                                                              </div>
                                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 cust_items zooom">
                                                                <img
                                                                  src={
                                                                    process.env
                                                                      .REACT_APP_API_BASIC_URL +
                                                                    "/" +
                                                                    voucher
                                                                  }
                                                                  className="ls-l img-slider imgCustomPackageItems"
                                                                  alt={
                                                                    "Spa Girl " +
                                                                    index
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "150px",
                                                                    height:
                                                                      "75px",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                                <h5>
                                                                  {" "}
                                                                  {title}
                                                                </h5>
                                                                <InputGroupComponent className="form-group pos_rel custom_item_radio login_group_wrapper d-lg-none ">
                                                                  <label>
                                                                    <InputComponent
                                                                      inputRef={
                                                                        inputRef
                                                                      }
                                                                      id={
                                                                        "serviceIdType" +
                                                                        getServicesTypeDataItems.id +
                                                                        "Id" +
                                                                        customeData.id
                                                                      }
                                                                      name={
                                                                        "serviceIdType" +
                                                                        getServicesTypeDataItems.id +
                                                                        "Id" +
                                                                        customeData.id
                                                                      }
                                                                      type="radio"
                                                                      inputClassName={
                                                                        "form-control lgn_input Ashikali"
                                                                      }
                                                                      isInvalid={
                                                                        errorState.email
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={() =>
                                                                        addcustompackage(
                                                                          customeData.id,
                                                                          getServicesTypeDataItems.id,
                                                                          id
                                                                        )
                                                                      }
                                                                      checked={
                                                                        customPkgItems[
                                                                          customeData
                                                                            .id
                                                                        ] === id
                                                                      }
                                                                      value={id}
                                                                    />
                                                                    <div className="check"></div>
                                                                  </label>
                                                                  {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                                  <InvalidFeedback>
                                                                    {
                                                                      errorState.email
                                                                    }
                                                                  </InvalidFeedback>
                                                                </InputGroupComponent>
                                                                <h5 className="text-righ custom_item_price d-md-block d-lg-none">
                                                                  {price} QAR
                                                                </h5>
                                                              </div>
                                                              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3 d-none d-lg-block">
                                                                <h5 className="text-righ custom_item_price">
                                                                  {price} QAR
                                                                </h5>
                                                              </div>
                                                              {/* <div className="col-xs-12 col-sm-12 col-md-2 d-lg-none">
                                                                        
                                                                      </div> */}
                                                            </div>
                                                          )
                                                        )
                                                      : ""}
                                                  </div>
                                                )
                                              )
                                            : ""}
                                        </>
                                      ) : customeData.getServicesTypeData &&
                                        customeData.getServicesTypeData.length >
                                          0 ? (
                                        customeData.getServicesTypeData.map(
                                          (
                                            { image, price, title, id },
                                            idx
                                          ) => (
                                            <div
                                              className="row align-items-center mb-3 cus_item_row"
                                              key={"cusItem" + idx}
                                            >
                                              <div className="col-xs-12 col-sm-12 col-md-1 d-none d-lg-block">
                                                <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                                                  <label>
                                                    <InputComponent
                                                      inputRef={inputRef}
                                                      id={
                                                        "serviceIdType0Id" +
                                                        customeData.id
                                                      }
                                                      name={
                                                        "serviceIdType0Id" +
                                                        customeData.id
                                                      }
                                                      type="radio"
                                                      inputClassName="form-control lgn_input aby"
                                                      isInvalid={
                                                        errorState.email
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={() =>
                                                        addcustompackage(
                                                          0,
                                                          customeData.id,
                                                          id
                                                        )
                                                      }
                                                      checked={
                                                        customPkgItems[
                                                          customeData.id
                                                        ] === id
                                                      }
                                                      value={id}
                                                    />
                                                    <div className="check"></div>
                                                  </label>

                                                  {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                  <InvalidFeedback>
                                                    {errorState.email}
                                                  </InvalidFeedback>
                                                </InputGroupComponent>
                                              </div>
                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 cust_items">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_BASIC_URL +
                                                    "/" +
                                                    image
                                                  }
                                                  className="ls-l img-slider imgCustomPackageItems"
                                                  alt={"Spa Girl " + index}
                                                  style={{
                                                    width: "150px",
                                                    height: "75px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                <h5> {title}</h5>
                                                <InputGroupComponent className="form-group pos_rel custom_item_radio login_group_wrapper d-lg-none">
                                                  <label>
                                                    <InputComponent
                                                      inputRef={inputRef}
                                                      id={
                                                        "serviceIdType0Id" +
                                                        customeData.id
                                                      }
                                                      name={
                                                        "serviceIdType0Id" +
                                                        customeData.id
                                                      }
                                                      type="radio"
                                                      inputClassName="form-control lgn_input aby"
                                                      isInvalid={
                                                        errorState.email
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={() =>
                                                        addcustompackage(
                                                          0,
                                                          customeData.id,
                                                          id
                                                        )
                                                      }
                                                      checked={
                                                        customPkgItems[
                                                          customeData.id
                                                        ] === id
                                                      }
                                                      value={id}
                                                    />
                                                    <div className="check"></div>
                                                  </label>

                                                  {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                  <InvalidFeedback>
                                                    {errorState.email}
                                                  </InvalidFeedback>
                                                </InputGroupComponent>
                                                <h5 className="text-righ custom_item_price  d-md-block d-lg-none">
                                                  {price} QAR
                                                </h5>
                                              </div>
                                              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3 d-none d-lg-block">
                                                <h5 className="text-righ custom_item_price">
                                                  {price} QAR
                                                </h5>
                                              </div>
                                              {/* <div className="col-xs-12 col-sm-12 col-md-2 d-lg-none">
                                                        
                                                      </div> */}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </div>
                        </AccordionBody>
                      </>
                    )}
                  </AccordionItem>
                ))}
            </Accordion>
            <Nav tabs className="hide-m  ">
              {props.customePackages.length &&
                props.customePackages.map((customeData, i) => {
                  return (
                    <>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "cust" + i,
                          })}
                          onClick={() => {
                            toggle("cust" + i);
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.image
                            }
                            className="ls-l img-slider imgPackage"
                            alt={"Spa Girl " + i}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              customeData.imageHover
                            }
                            className="ls-l img-slider imgPackageHover"
                            alt={"Spa Girl " + i}
                            style={
                              {
                                // width: "100%",
                              }
                            }
                          />

                          <h4>{customeData.title}</h4>
                        </NavLink>
                      </NavItem>
                    </>
                  );
                })}
            </Nav>

            <TabContent activeTab={activeTab} id="myTabContent">
              {props.customePackages.length &&
                props.customePackages.map((customeData, i) => {
                  return (
                    <>
                      {/* <button type="reset" for="gift_form">
                        Reset
                      </button> */}
                      <TabPane tabId={"cust" + i}>
                        <Row>
                          <Col sm="12">
                            <div className="custom-contents">
                              <>
                                {customeData.type == 1
                                  ? customeData.getServicesTypeData &&
                                    customeData.getServicesTypeData.length > 0
                                    ? customeData.getServicesTypeData.map(
                                        (getServicesTypeDataItems, idx) => (
                                          <div className="serviceItemWrap">
                                            <h4 className="setviceItemTitle">
                                              {getServicesTypeDataItems.title}
                                            </h4>

                                            {getServicesTypeDataItems.items &&
                                            getServicesTypeDataItems.items
                                              .length > 0
                                              ? getServicesTypeDataItems.items.map(
                                                  (
                                                    {
                                                      image,
                                                      price,
                                                      title,
                                                      id,
                                                      voucher,
                                                    },
                                                    idx
                                                  ) => (
                                                    <div
                                                      className="row align-items-center mb-3 cus_item_row"
                                                      key={"cusItem" + idx}
                                                    >
                                                      <div className="col-xs-12 col-sm-12 col-md-1 d-none d-lg-block">
                                                        <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                                                          <label>
                                                            <InputComponent
                                                              inputRef={
                                                                inputRef
                                                              }
                                                              id={
                                                                "serviceIdType" +
                                                                getServicesTypeDataItems.id +
                                                                "Id" +
                                                                customeData.id
                                                              }
                                                              name={
                                                                "serviceIdType" +
                                                                getServicesTypeDataItems.id +
                                                                "Id" +
                                                                customeData.id
                                                              }
                                                              type="radio"
                                                              inputClassName={
                                                                "form-control lgn_input ashilk-" +
                                                                statusCheck +
                                                                "-----" +
                                                                customPkgItems[
                                                                  customeData.id
                                                                ] +
                                                                "-->" +
                                                                id
                                                              }
                                                              onChange={() =>
                                                                addcustompackage(
                                                                  customeData.id,
                                                                  getServicesTypeDataItems.id,
                                                                  id
                                                                )
                                                              }
                                                              isInvalid={
                                                                errorState.email
                                                                  ? true
                                                                  : false
                                                              }
                                                              defaultChecked={
                                                                statusCheck
                                                              }
                                                              checked={
                                                                customPkgItems[
                                                                  customeData.id
                                                                ] === id
                                                              }
                                                              value={id}
                                                            />
                                                            <div className="check"></div>
                                                          </label>

                                                          {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                          <InvalidFeedback>
                                                            {errorState.email}
                                                          </InvalidFeedback>
                                                        </InputGroupComponent>
                                                      </div>
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 cust_items">
                                                        <img
                                                          src={
                                                            process.env
                                                              .REACT_APP_API_BASIC_URL +
                                                            "/" +
                                                            voucher
                                                          }
                                                          className="ls-l img-slider imgCustomPackageItems"
                                                          alt={"Spa Girl " + i}
                                                          style={{
                                                            width: "150px",
                                                            height: "75px",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                        <h5> {title}</h5>
                                                        <InputGroupComponent className="form-group pos_rel custom_item_radio login_group_wrapper d-lg-none ">
                                                          <label>
                                                            <InputComponent
                                                              inputRef={
                                                                inputRef
                                                              }
                                                              id={
                                                                "serviceIdType" +
                                                                getServicesTypeDataItems.id +
                                                                "Id" +
                                                                customeData.id
                                                              }
                                                              name={
                                                                "serviceIdType" +
                                                                getServicesTypeDataItems.id +
                                                                "Id" +
                                                                customeData.id
                                                              }
                                                              type="radio"
                                                              inputClassName={
                                                                "form-control lgn_input Ashikali"
                                                              }
                                                              isInvalid={
                                                                errorState.email
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={() =>
                                                                addcustompackage(
                                                                  customeData.id,
                                                                  getServicesTypeDataItems.id,
                                                                  id
                                                                )
                                                              }
                                                              mydata={
                                                                "Ashikali"
                                                              }
                                                              checked={
                                                                customPkgItems[
                                                                  customeData.id
                                                                ] === id
                                                              }
                                                              value={id}
                                                            />
                                                            <div className="check"></div>
                                                          </label>

                                                          {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                                          <InvalidFeedback>
                                                            {errorState.email}
                                                          </InvalidFeedback>
                                                        </InputGroupComponent>
                                                        <h5 className="text-righ custom_item_price  d-md-block d-lg-none">
                                                          {price} QAR
                                                        </h5>
                                                      </div>
                                                      <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3 d-none d-lg-block">
                                                        <h5 className="text-righ custom_item_price">
                                                          {price} QAR
                                                        </h5>
                                                      </div>
                                                      {/* <div className="col-xs-12 col-sm-12 col-md-2 d-lg-none">
                                                        
                                                      </div> */}
                                                    </div>
                                                  )
                                                )
                                              : ""}
                                          </div>
                                        )
                                      )
                                    : ""
                                  : customeData.getServicesTypeData &&
                                    customeData.getServicesTypeData.length > 0
                                  ? customeData.getServicesTypeData.map(
                                      ({ image, price, title, id }, idx) => (
                                        <div
                                          className="row align-items-center mb-3 cus_item_row"
                                          key={"cusItem" + idx}
                                        >
                                          <div className="col-xs-12 col-sm-12 col-md-1 d-none d-lg-block">
                                            <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                                              <label>
                                                <InputComponent
                                                  inputRef={inputRef}
                                                  id={
                                                    "serviceIdType0Id" +
                                                    customeData.id
                                                  }
                                                  name={
                                                    "serviceIdType0Id" +
                                                    customeData.id
                                                  }
                                                  type="radio"
                                                  inputClassName="form-control lgn_input  ashik"
                                                  isInvalid={
                                                    errorState.email
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() =>
                                                    addcustompackage(
                                                      0,
                                                      customeData.id,
                                                      id
                                                    )
                                                  }
                                                  checked={
                                                    customPkgItems[
                                                      customeData.id
                                                    ] === id
                                                  }
                                                  value={id}
                                                />
                                                <div className="check"></div>
                                              </label>

                                              {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                              <InvalidFeedback>
                                                {errorState.email}
                                              </InvalidFeedback>
                                            </InputGroupComponent>
                                          </div>
                                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 cust_items">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_API_BASIC_URL +
                                                "/" +
                                                image
                                              }
                                              className="ls-l img-slider imgCustomPackageItems"
                                              alt={"Spa Girl " + i}
                                              style={{
                                                width: "150px",
                                                height: "75px",
                                              }}
                                            />
                                          </div>
                                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                            <h5> {title}</h5>
                                            <InputGroupComponent className="form-group pos_rel custom_item_radio login_group_wrapper d-lg-none ">
                                              <label>
                                                <InputComponent
                                                  inputRef={inputRef}
                                                  id={
                                                    "serviceIdType0Id" +
                                                    customeData.id
                                                  }
                                                  name={
                                                    "serviceIdType0Id" +
                                                    customeData.id
                                                  }
                                                  type="radio"
                                                  inputClassName="form-control lgn_input Ashikali"
                                                  isInvalid={
                                                    errorState.email
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() =>
                                                    addcustompackage(
                                                      0,
                                                      customeData.id,
                                                      id
                                                    )
                                                  }
                                                  checked={
                                                    customPkgItems[
                                                      customeData.id
                                                    ] === id
                                                  }
                                                  value={id}
                                                />
                                                <div className="check"></div>
                                              </label>

                                              {/* <i className="uil uil-envelope lgn_icon"></i> */}
                                              <InvalidFeedback>
                                                {errorState.email}
                                              </InvalidFeedback>
                                            </InputGroupComponent>
                                            <h5 className="text-righ custom_item_price  d-md-block d-lg-none">
                                              {price} QAR
                                            </h5>
                                          </div>
                                          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3 d-none d-lg-block">
                                            <h5 className="text-righ custom_item_price">
                                              {price} QAR
                                            </h5>
                                          </div>
                                          {/* <div className="col-xs-12 col-sm-12 col-md-2 d-lg-none">
                                            
                                          </div> */}
                                        </div>
                                      )
                                    )
                                  : ""}
                              </>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </>
                  );
                })}
            </TabContent>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomePackageGiftItemComponent;
