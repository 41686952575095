import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { registerAction, isAuthenticated } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";

import { setUserSession, verifyOtpAction } from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import useGetDataPrivate from "../../..//hooks/useGetDataPrivate";
import { privateInstance } from "../../../axios";
import spinnerImg from "../../../assets/img/logo/ezgif.com-gif-maker.gif";

const DetailsAccount = (props) => {
  const { t } = useTranslation();

  // const { error, isLoading, accountDetails } = props;
  const { error, isLoad, data: accountDetails } = useGetDataPrivate("profile");
  console.log(accountDetails);
  const navigate = useNavigate();
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
  });
  const [formDataPassword, setFormDataPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderPassword, setLoaderPassword] = useState(false);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handleInputChangePass = (e) => {
    setErrorResponse("");
    setFormDataPassword({
      ...formDataPassword,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    // if (isLoad) {
    setFormData({
      ...formData,
      id: accountDetails.id,
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      email: accountDetails.email,
      phoneNumber: accountDetails.phoneNumber,
      streetAddress: accountDetails.streetAddress,
    });
    // }
    inputRef && inputRef.current && inputRef.current.focus();
  }, [isLoad, accountDetails]);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (formDataPassword.newPassword != formDataPassword.confirmPassword) {
      toast.error("Password and Confirm Password should be same");
      return;
    }
    if (
      !RegExp("^([A-Za-z0-9])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,18})").test(
        formDataPassword.newPassword
      )
    ) {
      toast.error(
        "Please enter a valid password (Minimum 6 and maximum 20 characters, alpha numeric, minimum one special character)"
      );
      return;
    }
    const validateOptions = {
      currentPassword: {
        required: true,
        type: "text",
      },
      newPassword: {
        required: true,
        type: "text",
      },
      confirmPassword: {
        required: true,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formDataPassword);

    if (isValid) {
      setLoaderPassword(true);
      return await privateInstance
        .post("update-new-password", {
          old_password: formDataPassword.currentPassword,
          new_password: formDataPassword.newPassword,
        })
        .then((res) => {
          setLoaderPassword(false);
          if (res.data.status === 200) {
            toast.success(res.data.message);
            return res.data;
          } else {
            toast.error(res.data.message);
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      firstName: {
        required: true,
        type: "text",
      },
      lastName: {
        required: true,
        type: "text",
      },
      email: {
        required: true,
        type: "email",
      },
      phoneNumber: {
        required: true,
        type: "number",
      },
      streetAddress: {
        required: false,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);

    if (isValid) {
      setLoader(true);
      return await privateInstance
        .post("profile", {
          id: formData.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          streetAddress: formData.streetAddress,
        })
        .then((res) => {
          setLoader(false);
          if (res.data.status === 200) {
            toast.success(res.data.message);
            props.updateUser(res.data.data.value);
            return res.data;
          } else {
            return res.data.message;
          }
        })
        .catch((error) => {
          return "API error";
        });
    }
  };
  return (
    <>
      {isLoad ? (
        <div className="container text-center">
          <img src={spinnerImg} />
        </div>
      ) : (
        <>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-12">
                <form action="#" className="p-sm-5 p-3 input-white">
                  <div className="row pb-3">
                    <div className="col-xs-12 col-sm-12">
                      {errorResponse && (
                        <p className="text-danger text-center">
                          {errorResponse}
                        </p>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                      <InputGroupComponent className="form-group pos_rel">
                        <InputComponent
                          inputRef={inputRef}
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeHolder={t("first_name")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.firstName ? true : false}
                          onChange={handleInputChange}
                          value={formData.firstName}
                        />

                        <InvalidFeedback>
                          {errorState.firstName}
                        </InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                      <InputGroupComponent className="form-group pos_rel">
                        <InputComponent
                          inputRef={inputRef}
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeHolder={t("last_name")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.lastName ? true : false}
                          onChange={handleInputChange}
                          value={formData.lastName}
                        />

                        <InvalidFeedback>{errorState.lastName}</InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                      <InputGroupComponent className="form-group pos_rel">
                        <InputComponent
                          name="email"
                          type="email"
                          placeHolder={t("email")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.email ? true : false}
                          // onChange={handleInputChange}
                          value={formData.email}
                          readonly
                        />

                        <InvalidFeedback>{errorState.email}</InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                      <InputGroupComponent className="form-group pos_rel checkout-login">
                        <InputComponent
                          id="phoneNumber"
                          name="phoneNumber"
                          type="number"
                          placeHolder={t("phone")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.phoneNumber ? true : false}
                          onChange={handleInputChange}
                          value={formData.phoneNumber}
                        />

                        <InvalidFeedback>
                          {errorState.mobileNumber}
                        </InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                      <InputGroupComponent className="form-group pos_rel">
                        <InputComponent
                          name="streetAddress"
                          type="text"
                          placeHolder={t("street_address")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.streetAddress ? true : false}
                          onChange={handleInputChange}
                          value={formData.streetAddress}
                        />

                        <InvalidFeedback>
                          {errorState.streetAddress}
                        </InvalidFeedback>
                      </InputGroupComponent>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mb-40 mt-20 text-center">
                      <ButtonComponent
                        className="vs-btn vs-style1  border-0 form_button"
                        type="submit"
                        text={t("save_changes")}
                        loader={loader}
                        onClick={handleSubmit}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
                      <InputGroupComponent className="form-group pos_rel ">
                        <InputComponent
                          id="currentPassword"
                          name="currentPassword"
                          type="text"
                          placeHolder={t("current_password")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.currentPassword ? true : false}
                          onChange={handleInputChangePass}
                          value={formData.currentPassword}
                        />

                        <InvalidFeedback>{errorState.password}</InvalidFeedback>
                      </InputGroupComponent>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
                      <InputGroupComponent className="form-group pos_rel ">
                        <InputComponent
                          id="newPassword"
                          name="newPassword"
                          type="text"
                          placeHolder={t("new_password")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.newPassword ? true : false}
                          onChange={handleInputChangePass}
                          value={formData.newPassword}
                        />

                        <InvalidFeedback>{errorState.password}</InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
                      <InputGroupComponent className="form-group pos_rel ">
                        <InputComponent
                          id="confirmpassword"
                          name="confirmPassword"
                          type="text"
                          placeHolder={t("confirm_password")}
                          inputClassName="form-control account-details-input"
                          isInvalid={errorState.confirmPassword ? true : false}
                          onChange={handleInputChangePass}
                          value={formData.confirmPassword}
                        />

                        <InvalidFeedback>{errorState.password}</InvalidFeedback>
                      </InputGroupComponent>
                    </div>
                  </div>

                  <div className="mb-0 text-center">
                    <ButtonComponent
                      className="vs-btn vs-style1  border-0 form_button"
                      type="submit"
                      text={t("save_changes")}
                      loader={loaderPassword}
                      onClick={handleSubmitPassword}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsAccount;
