import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrderPopup(props) {
  const { t } = useTranslation();

  const [show, setShow] = useState(props.show);
  const [itemData, Setitemdata] = useState({});
  const handleClose = () => {
    props.updateShow(false);
    setShow(false);
  };
  useEffect(() => {
    setShow(props.show);
    Setitemdata(props.data);
  }, [props]);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        className="order_modal order_modal_wrap"
        onHide={props.onHide}
      >
        {itemData && (
          <>
            <Modal.Header>
              <Modal.Title>
                {props.title} - #{itemData.order_id}
                <h4>{itemData.booking_status_name}</h4>
              </Modal.Title>
              <button
                type="button"
                class="btn-close"
                onClick={props.onHide}
                aria-label="Close"
              >
                <i class="fa fa-times"></i>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-7">
                  <div className="row mb-4">
                    <div className="col-12">
                      <h3>{itemData.title}</h3>
                    </div>
                  </div>
                  {itemData.order_products &&
                    itemData.order_products.map((data, i) => {
                      return (
                        <div className="row mb-3 ">
                          <div className="col-lg-2">
                            <img
                              src={
                                process.env.REACT_APP_API_BASIC_URL +
                                "/" +
                                data.image
                              }
                              className="item_img"
                              alt={data.title}
                            />
                          </div>
                          <div className="col-lg-10">
                            <h5>{data.title}</h5>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="col-lg-5 popflex">
                  <h5 className="mt-3">
                    {t("payment_method")} : {itemData.payment_method_name}
                  </h5>
                  <h5 className="mt-3">
                    {t("payment_status")} : {itemData.payment_status}
                  </h5>
                  <h5 className="mt-3">
                    {t("payment_ref")} : {itemData.transaction_id}
                  </h5>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                {itemData.contactInfo && (
                  <div className="col-sm-12 col-md-6 mb-5">
                    <h3 className="mb-4">{t("billing_details")} </h3>

                    <h5 className="mt-2">
                      {t("name")} :{" "}
                      {itemData.contactInfo.first_name +
                        "" +
                        itemData.contactInfo.last_name}
                    </h5>
                    <h5 className="mt-2">
                      {t("phone")} : {itemData.contactInfo.phone_number}
                    </h5>
                    <h5 className="mt-2">
                      {t("email")} : {itemData.contactInfo.email}
                    </h5>
                  </div>
                )}
                {itemData.gift_from && (
                  <div className="col-sm-12 col-md-6 mb-5">
                    <h3 className="mb-4"> {t("gift_details")}</h3>

                    <h5 className="mt-2">
                      {" "}
                      {t("from")} : {itemData.gift_from}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("to")} : {itemData.gift_to}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("gift_cake_message")} : {itemData.gift_cake_massage}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("gift_card_message")} : {itemData.gift_card_mesage}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("gift_phone")} : {itemData.gift_phone}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("gift_comments")} : {itemData.gift_comments}
                    </h5>
                    <h5 className="mt-2">
                      {" "}
                      {t("gift_address")} : {itemData.gift_address}
                    </h5>
                  </div>
                )}
                <div className="col-12 text-center">
                  {itemData.invoice && itemData.invoice.invoice && (
                    <a
                      href={
                        process.env.REACT_APP_API_BASIC_URL +
                        "/" +
                        itemData.invoice.invoice_file
                      }
                      target="_blank"
                      className="popfooterBtn"
                      variant="secondary"
                      onClick={props.onHide}
                    >
                      {t("invoice")}
                    </a>
                  )}
                  <Button
                    className="popfooterBtn"
                    variant="primary"
                    onClick={props.onHide}
                  >
                    {t("close")}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default OrderPopup;
