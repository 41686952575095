import { TOGGLE_CART_DRAWER, TOGGLE_FILTER_DRAWER } from "../constants";

export const toggleFilterAction = () => (dispatch) => {
  dispatch({
    type: TOGGLE_FILTER_DRAWER,
  });
};
export const toggleCartAction = () => (dispatch) => {
  dispatch({
    type: TOGGLE_CART_DRAWER,
  });
};
