import React, { useState } from "react";
import { createRef, useEffect } from "react";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";

import classnames from "classnames";
import i18next, { t } from "i18next";

function PrePackageGiftItemComponent(props) {
  const [loader, setLoader] = useState(false);
  const [activePackage, setActivePackage] = useState(props.giftPackageId || 0);

  const addprepackage = (val) => {
    setActivePackage(val);
    props.updatePrepackageId(val);
  };
  useEffect(() => {
    setActivePackage(props.giftPackageId);
  }, [props.giftPackageId]);
  return (
    <>
      <section className="pre-package-item-wrapper">
        <div className="row">
          {props.prePackages.length &&
            props.prePackages.map((preData, i) => {
              return (
                <>
                  <div
                    className={
                      "col-xs-12 col-sm-12 col-md-12 col-lg-6  " +
                      classnames({
                        active: activePackage === preData.id,
                      })
                    }
                  >
                    <div className="pre-package-item">
                      <div className="row">
                        <div className="col-sm-12 col-sm-12">
                          <h3>{preData.title}</h3>
                          <img
                            src={
                              process.env.REACT_APP_API_BASIC_URL +
                              "/" +
                              preData.image
                            }
                            className="ls-l "
                            alt={preData.title}
                            style={{
                              width: "100%",
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-8">
                              {preData.items && preData.items.length > 0 ? (
                                <ul>
                                  {preData.items.map(({ title }, idx) => (
                                    <li key={`pre_phg_${idx}`}>{title}</li>
                                  ))}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4">
                              <div className="pre_pkg_right_items">
                                <h4>{preData.price}</h4>
                                {/* <button>Add</button> */}
                                <ButtonComponent
                                  type="button"
                                  text={
                                    activePackage === preData.id
                                      ? t("selected")
                                      : t("select")
                                  }
                                  className={activePackage + "--" + preData.id}
                                  loader={loader}
                                  onClick={() => addprepackage(preData.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </section>
    </>
  );
}

export default PrePackageGiftItemComponent;
