import {
  CLEAR_ORDER,
  GET_ORDER_ITEMS_FAILURE,
  GET_ORDER_ITEMS_START,
  GET_ORDER_ITEMS_SUCCESS,
  UPDATE_ORDER,
} from "../constants";

const orderReducer = (
  state = { loader: false, result: {}, error: false },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_ITEMS_START:
      return {
        loader: true,
        result: state.result,
        error: false,
      };

    case GET_ORDER_ITEMS_SUCCESS:
      return {
        loader: false,
        result: payload,
        error: false,
      };
    case GET_ORDER_ITEMS_FAILURE:
      return {
        loader: false,
        result: {},
        error: true,
      };
    case CLEAR_ORDER:
      return {
        loader: false,
        result: {},
        error: false,
      };

    case UPDATE_ORDER:
      return {
        loader: false,
        result: payload,
        error: false,
      };

    default:
      return state;
  }
};
export default orderReducer;
