import flower from "../../../assets/img/appointment/flower.png";
import { t } from "i18next";

function Service(props) {
  const objectStyle = {
    // marginRight: "4%",
  };

  const objectStyle2 = {
    marginRight: "0%",
  };

  const backColor1 = {
    // marginRight: "4%",
    backgroundColor: "#FFEFE4",
  };
  const backColor2 = {
    marginRight: "0%",
    backgroundColor: "#ECEFE2",
  };
  return (
    <>
      {/* <div className="row"> */}
      <div
        className="col-4 col-md-12 col-lg-12 col-xl-12  serve_type "
        style={props.index == 0 ? objectStyle : objectStyle2}
      >
        <div
          className={
            "offer-banner-style1  mb-30 bg-light-theme homeserData homeserbg_" +
            props.index
          }
        >
          <div className={"row homeser"}>
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 homeser_col"> */}
            <div className="col-auto homeser_col">
              <div className="banner-image ">
                <img
                  src={process.env.REACT_APP_API_BASIC_URL + "/" + props.image}
                  alt="Offer Banner"
                  className="w-100"
                  // style={{ height: "275px" }}
                />
              </div>
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> */}
            <div className="col p-0">
              <div className={"banner-content m-auto  "}>
                <div className="service-title-c d-flex justify-content-center">
                  <h3 className="banner-title font-b">{props.title}</h3>
                </div>

                {/* <p className="mb-3 price-time-service">
                  {props.time} | {props.price} QR{" "}
                </p> */}
                <a
                  href={"/booking/" + props.id}
                  className="vs-btn vs-style1 c-btn-book-now-service"
                >
                  {/* <span className="vs-btn-shape"></span>
                  <span className="vs-btn-shape"></span>
                  <span className="vs-btn-shape"></span>{" "}
                  <span className="vs-btn-shape"></span> */}
                  {/* <span className="vs-btn-text btn-book-now-service">
                  </span> */}
                  {t("book_now")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
          className=" col-12 col-md-12 col-lg-12 col-xl-12"
          style={props.index == 0 ? backColor1 : backColor2}
        >
          <div className="offer-banner-style1 offer-banner-style1-m mb-30 bg-light-theme">
            <div className="d-flex flex-column">
              <div className="banner-image img-service-home">
                <img
                  src={process.env.REACT_APP_API_BASIC_URL + "/" + props.image}
                  alt="Offer Banner"
                  className="w-100"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="banner-content">
                <div className="service-title-c d-flex justify-content-center">
                  <h3 className="banner-title font-b">{props.title}</h3>
                </div>

                <p className="mb-3 price-time-service">
                  {props.time} | {props.price} QR{" "}
                </p>
                <a href="#" className="vs-btn vs-style1 c-btn-book-now-service">
                  <span className="vs-btn-shape"></span>
                  <span className="vs-btn-shape"></span>
                  <span className="vs-btn-shape"></span>{" "}
                  <span className="vs-btn-shape"></span>
                  <span className="vs-btn-text btn-book-now-service">
                    Book Now
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
}

export default Service;
