import React from "react";
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/flaticon.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/jquery.datetimepicker.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/layerslider.min.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/slick.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import "./assets/css/developer.css";
import "./assets/css/theme-color1.css";

import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import SideMEnu from "./components/layout/sidemenu/SideMenu";
import ScrollTop from "./components/layout/scrolltop/ScrollTop";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";

import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Outlet } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "../assets/locales/{{lng}}/translation.json",
    },

    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASIC_URL.replaceAll(
    "'",
    ""
  );
  axios.defaults.headers.common["accessToken"] =
    "c9aa0cdc71c865797675a68cf2725f71801d3709ad61a6fb684cda118fe119ad25dcbf6bd8e61eb89a58015e770b72375aadb99b9841424391fe8f35e67218a163a3296c072fa548c5a7522dc09f718a42404e57972eed65e01e0626206ef4e2";

  return (
    <div className="App">
      <BrowserRouter>
        <CookiesProvider>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Header />
          <Outlet />
          <Footer />
          <SideMEnu />
          <ScrollTop />
        </CookiesProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
