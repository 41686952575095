import { GET_WORKING_HOURS , GET_ERROR } from '../type'

const inital = {
    working_hours: [],
    loading: true,
}
const workingHoursReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_WORKING_HOURS:
            return {
                ...state,
                working_hours: action.payload,
                loading: false,
            }
            case GET_ERROR:
                return {
                loading: true,
                working_hours: action.payload,
                }
        default:
            return state;
    }
}
export default workingHoursReducer