import React, { useEffect, createRef, useRef } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  InputGroupComponent,
  ButtonComponent,
  InputComponent,
  InvalidFeedback,
} from "../../form_element";
import { useState } from "react";
import { forgotPasswordAction, resetPasswordAction } from "../../../auth";
import { validateForm } from "../../../services/FormValidation";
import { decryptData } from "../../../helpers/helperFunctions";

import { toast } from "react-toastify";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ResetPasswordVerifyOTP = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const inputRef = createRef();

  let { token } = useParams();
  let userData = {};
  if (token) {
    userData = decryptData(token);
  }
  useEffect(() => {
    setFormData({
      ...formData,
      userId: userData && userData.user_id,
      email: userData && userData.email,
    });
  }, [token]);

  const [formData, setFormData] = useState({
    userId: "",
    password: "",
    email: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");

  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("");
    }
  });

  const handleInputChange = (e) => {
    setErrorResponse("");

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setErrorState({
        ...errorState,
        confirmPassword: "Password and Confirm Password must be same.",
      });
      setLoader(false);
    } else {
      const validateOptions = {
        password: {
          required: true,
          type: "password",
        },
        confirmPassword: {
          required: true,
          type: "password",
        },
      };

      const { isValid, errors } = validateForm(validateOptions, formData);
      if (isValid) {
        setLoader(true);
        const res = await resetPasswordAction(formData);
        if (res) {
          if (res === 200) {
            toast.success("Password reset successfully . Please login again");
            navigate("/login");
          } else {
            setErrorResponse(res);
          }
          setLoader(false);
        }
      } else {
        setErrorState(errors);
      }
    }
  };
  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };
  const handleConfirmPasswordView = () => {
    if (confirmPasswordType == "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIconClass("fa fa-eye");
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIconClass("fa fa-eye-slash");
    }
  };
  const resendOtp = async (e) => {
    e.preventDefault();
    setErrorState({});
    setErrorResponse("");
    setSeconds(60);
    const res = await forgotPasswordAction({ email: formData.email });
  };
  return (
    <>
      <div className="signUp-wrapper user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="container">
          <div className="row justify-content-center auth_log_wrapper">
            <div className="col-sm-12 col-lg-7   col-xl-7">
              <form
                action="#"
                className="signUp-form bg-light-theme  pt-5 pb-5  pr-4 pl-4 input-white set_new_password"
              >
                <h2 className="form-title text-center">
                  {t("set_new_password")}
                </h2>
                {errorResponse && (
                  <p className="text-danger text-center">{errorResponse}</p>
                )}
                <div className="col-xs-12 col-sm-12 col-lg-12">
                  <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                    <div className="login_icon_email">
                      <i className="fa fa-lock"></i>
                    </div>
                    <InputComponent
                      id="password"
                      name="password"
                      type={passwordType}
                      placeHolder={t("password")}
                      inputClassName="form-control"
                      isInvalid={errorState.password ? true : false}
                      onChange={handlePasswordChange}
                      value={formData.password}
                    />
                    <i
                      className={passwordIconClass}
                      onClick={handlePasswordView}
                    ></i>
                    <i className="uil uil-padlock lgn_icon"></i>
                  </InputGroupComponent>
                  <InvalidFeedback>{errorState.password}</InvalidFeedback>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12">
                  <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                    <div className="login_icon_email">
                      <i className="fa fa-lock"></i>
                    </div>
                    <InputComponent
                      id="confirmpassword"
                      name="confirmPassword"
                      type={confirmPasswordType}
                      placeHolder={t("confirm_password")}
                      inputClassName="form-control lgn_input"
                      isInvalid={errorState.confirmPassword ? true : false}
                      onChange={handlePasswordChange}
                      value={formData.confirmPassword}
                    />
                    <i
                      className={confirmPasswordIconClass}
                      onClick={handleConfirmPasswordView}
                    ></i>
                    <i className="uil uil-padlock lgn_icon"></i>
                  </InputGroupComponent>
                  <InvalidFeedback>
                    {errorState.confirmPassword}
                  </InvalidFeedback>
                </div>
                <div className="mb-0 mb-0 text-center">
                  <ButtonComponent
                    className="vs-btn vs-style1  border-0 form_button forgot_next"
                    type="submit"
                    text={t("update_password")}
                    loader={loader}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordVerifyOTP;
