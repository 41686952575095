import { t } from "i18next";
import packageImage from "../../../assets/img/package/package.png";
import btnIcon from "../../../assets/img/package/packages-icon.png";
import btnIconHover from "../../../assets/img/package/packages-icon-hover.png";
import { Link } from "react-router-dom";

export default function PackageHome() {
  return (
    <section className="">
      <div className="position-relative package_wrapper ">
        {/* <img src={packageImage} alt="Shape BG" /> */}

        <div className="service-category ">
          <Link
            className="font-b position-relative package_parent mb-0"
            to="/gifts"
            state={{
              from: "/gifts",
            }}
          >
            <img className="icone-pkg" src={btnIcon} />
            <img className="icone-pkg_hover" src={btnIconHover} />
            <span className="font-l pkg d-inline-block position-relative">
              {t("packages")}
            </span>
            <i className="fa-2x far fa-long-arrow-right position-relative"></i>
          </Link>
        </div>
      </div>
    </section>
  );
}
