import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  trimStringSmall,
  trimStringExtraSmall,
} from "../../../helpers/helperFunctions";

const MobileMenuDropDown = ({ isLoggedIn, userName, isGuest, email }) => {
  const MenuItem = [
    {
      name: "home",
      url: "/",
    },
    {
      name: "about_us",
      url: "/aboutus",
    },
    {
      name: "services",
      url: "/services",
    },
    {
      name: "gifts",
      url: "/gifts",
    },
    {
      name: "booking",
      url: "/booking",
    },

    {
      name: "contact",
      url: "/contact",
    },
  ];

  const AuthMenuItem = [
    // {
    //   name: "favorits",
    //   url: "/favorits",
    //   icon: "far fa-heart",
    // },
    {
      name: "my_cart",
      url: "/profile",
      icon: "far fa-shopping-bag",
      tab: 2,
    },
    {
      name: "orders",
      url: "/profile",
      icon: "fal fa-shopping-bag",
      tab: 1,
    },
    {
      name: "my_account",
      url: "/profile",
      icon: "far fa-edit",
      tab: 3,
    },
  ];

  const { t } = useTranslation();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const handleCloseMenuMobile = () => {
    setOpenMobileMenu(false);
  };
  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  return (
    <>
      {/* <div className="position-relative"> */}
      <a
        onClick={handleOpenMobileMenu}
        className="vs-menu-toggle text-theme border-theme d-inline-block d-lg-none"
      >
        {!openMobileMenu ? (
          <i className="far fa-bars"></i>
        ) : (
          <i className="fal fa-times"></i>
        )}
      </a>
      {openMobileMenu ? (
        <div className="position-absolute drop-down drop-down-menu-mobile">
          <div className="vs-mobile-menu link-inherit pt-20 pl-20 pr-20">
            <ul className="links-c">
              {MenuItem.map((item) => {
                return (
                  <li key={item.name}>
                    <NavLink end to={item.url} onClick={handleCloseMenuMobile}>
                      {t(item.name)}
                    </NavLink>
                  </li>
                );
              })}
            </ul>

            {!isLoggedIn ? (
              <div className="mt-30 text-center">
                <div className="vs-btn vs-style1  border-0 form_button btn-login-m">
                  <NavLink to="/login" onClick={handleCloseMenuMobile}>
                    {t("login")}
                  </NavLink>
                </div>
                <div className="mt-20 signup-c mb-20">
                  <span className="">{t("new_here")} </span>
                  <span className="pink-pointer">
                    <NavLink to="/register" onClick={handleCloseMenuMobile}>
                      {t("signup")}
                    </NavLink>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-20">
                  <div className="user-name-m-menu font-b mb-20">
                    <span>{userName}</span>

                    <NavLink
                      state={{
                        tab: 3,
                      }}
                      to="profile"
                      onClick={handleCloseMenuMobile}
                      className="d-inline-block float-right"
                    >
                      <i className="far fa-edit"></i>
                    </NavLink>
                  </div>
                  <div className="user-email-m-menu">
                    <span>{email}</span>
                  </div>
                  <ul className="authmenu-ul">
                    {AuthMenuItem.map((item) => {
                      return (
                        <li key={item.name}>
                          <i className={`${item.icon} mr-2`}></i>
                          <NavLink
                            state={{
                              tab: item.tab,
                            }}
                            end
                            to={item.url}
                            onClick={handleCloseMenuMobile}
                            className="d-inline-block"
                          >
                            {t(item.name)}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="text-center">
                    <div className="vs-btn vs-style1  border-0 form_button btn-login-m mt-20 text-center mb-20">
                      <NavLink to="/logout">{t("logout")}</NavLink>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      {/* </div> */}
    </>
  );
};

export default MobileMenuDropDown;
