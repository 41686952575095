import SliderSlick from "react-slick";
function ProductsWeUseHome(props) {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-right btn-next"}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={"fa-2x far fa-long-arrow-left btn-prev"}
        onClick={onClick}
      />
    );
  }

  let initialLength;
  initialLength =
    props.product_we_use.products.length < 4
      ? props.product_we_use.products.length
      : 4;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: initialLength,
    slidesToScroll: initialLength,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mb-80 pweUseWrapper">
      <div className="font-b text-center text-capitalize mt-4 mb-5 h4 pweUse">
        {props.product_we_use.title}
      </div>
      <SliderSlick {...settings}>
        {props.product_we_use.products.map((product, i) => {
          return (
            <div className="vs-team text-center" key={i}>
              <div
                className="vs-team-img"
                style={{ width: "200px", height: "200px" }}
              >
                <a href="/products-we-use">
                  <img
                    src={
                      process.env.REACT_APP_API_BASIC_URL + "/" + product.image
                    }
                    alt="Team Member Image"
                    style={{ width: "200px", height: "200px" }}
                  />
                </a>
              </div>
              <div className="vs-team-content"></div>
            </div>
          );
        })}
      </SliderSlick>

      <div className="font-l text-center mt-4 pweDescription">
        {props.product_we_use.description}
      </div>
    </div>
  );
}

export default ProductsWeUseHome;
