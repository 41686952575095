export const API_BASE_PATH = `${process.env.REACT_APP_API_BASIC_URL}/`;
export const BACKEND_BASE_PATH = `${process.env.REACT_APP_API_BASIC_URL}`;
export const WEBSITE_BASE_PATH = `${process.env.REACT_APP_WEBSITE_PATH}`;

export const FB_APP_ID = `${process.env.REACT_APP_FB_APP_ID}`;
export const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_GOOGLE_APP_CLIENT_ID}`;

export const ACCESS_TOKEN = process.env.REACT_APP_API_ACCESS_TOKEN;
export const ENCR_KEY = process.env.REACT_APP_ENCR_KEY;
export const G_MAP_KEY = process.env.REACT_APP_G_MAPS_KEY;

export const LOCAL_SESSION_TOKEN_NAME = "localSessionToken";
export const AUTH_SESSION_NAME = "userSession";
export const LOAD_MORE_LIMIT = 24;
export const SORT_BY = "5";
export const MASTER_COUNTRY_ID = "224";

export const ADDRESS_TYPES = [
  {
    label: "Home",
    id: "1",
    icon: "uil uil-home-alt",
  },
  {
    label: "Office",
    id: "2",
    icon: "uil uil-building",
  },
  {
    label: "Others",
    id: "3",
    icon: "uil uil-map-marker-alt",
  },
];

export const SHIPPING_TYPES = [
  {
    label: "Delivery",
    id: "1",
  },
  {
    label: "Pickup From Store",
    id: "2",
  },
];

export const SHIPPING_METHODS = [
  {
    label: "One hour",
    id: "1",
  },
  {
    label: "Four hour",
    id: "2",
  },
  {
    label: "Same day",
    id: "3",
  },
  {
    label: "Next day",
    id: "4",
  },
  {
    label: "Pickup From Store",
    id: "5",
  },
];
export const PAYMENT_METHODS = [
  {
    label: "Cash On Delivery",
    id: "1",
  },
  {
    label: "Credit/Debit Card",
    id: "2",
  },
];
