

function Devider() {
  return (
    
      
    <a href="#" className="scrollToTop icon-btn bg-theme"><i className="far fa-arrow-up"></i></a>
    
  );
}

export default Devider;
