import { ENCR_KEY } from "./config";

const CryptoJS = require("crypto-js");

export const encryptData = (data) => {
  console.log(ENCR_KEY);
  try {
    const encTxt = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      ENCR_KEY
    ).toString();
    return encTxt.replace(/\//g, "DoooD");
  } catch (error) {
    console.log(" encode error ", error);
  }
};

export const decryptData = (ciphertext) => {
  if (!ciphertext) return {};
  const newCtext = ciphertext.replace(/DoooD/g, "/");
  const bytes = CryptoJS.AES.decrypt(newCtext, ENCR_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const trimString = (message, length = 55) => {
  if (message && message.length > length) {
    return message.substring(0, length) + "...";
  } else return message;
};
export const trimStringSmall = (message, length = 12) => {
  if (message && message.length > length) {
    return message.substring(0, length) + "...";
  } else return message;
};

export const trimStringExtraSmall = (message, length = 8) => {
  if (message && message.length > length) {
    return message.substring(0, length) + "...";
  } else return message;
};
