import Preloader from "../../layout/preloader/Preloader";
import React, { createRef, useEffect } from "react";
// import loginImage from "../assets/images/dark-logo.svg";
import loginImage from "../../../assets/img/loginImage.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
// import siteLogo from "../assets/images/logo.png";
// import darkLogo from "../assets/images/dark-logo.svg";
import { useCookies } from "react-cookie";
import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { useState } from "react";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
} from "../../../auth";
// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

// cookies.set("countryName", "Qatar");
const emailData = cookies.get("handstoneEmail") || "";
const passwordData = cookies.get("handstonePassword") || "";

const Login = (props) => {
  const { t } = useTranslation();

  // const navigate = useNavigate();
  let navigate = useNavigate();
  let location = useLocation();
  // const { from } = location.state;
  let from = location.state?.from || "/home";
  // let phone = location.state?.phone || "";
  let email = location.state?.email || "";
  // navigate(from, { replace: true });
  // console.log(navigate);
  // console.log(location);
  const inputRef = createRef();
  const [formData, setFormData] = useState({
    email: emailData || email,
    password: passwordData,
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordIconClass, setPasswordIconClass] = useState("fa fa-eye-slash");
  const [confirmPasswordIconClass, setConfirmPasswordIconClass] =
    useState("fa fa-eye-slash");
  const [loader, setLoader] = useState(false);
  const [guestLoader, setGuestLoader] = useState(false);
  // const [cookies, setCookie] = useCookies(["name"]);

  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const handlePasswordView = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordIconClass("fa fa-eye");
    } else {
      setPasswordType("password");
      setPasswordIconClass("fa fa-eye-slash");
    }
  };

  const [rememberCheckBox, setRememberCheckBox] = useState(false);

  const handleCheckbox = (e) => {
    if (e.target.name == "rememberme") {
      setRememberCheckBox(!rememberCheckBox);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/profile");
    }
    inputRef && inputRef.current && inputRef.current.focus();
  }, [rememberCheckBox, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateOptions = {
      email: {
        required: true,
        type: "email|mobile",
      },
      password: {
        required: true,
        type: "password",
        min: "6",
        max: "16",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      setLoader(true);
      const res = await loginAction(formData);
      if (res) {
        if (res.status === 200) {
          if (rememberCheckBox == true) {
            console.log("Here here");
            cookies.set("handstoneEmail", formData.email);
            cookies.set("handstonePassword", formData.password);
          }
          setLoader(false);
          // let redirUrl = "/account-details";
          console.log(res.data.value);
          //   if (props.location.state && props.location.state.callBackUrl) {
          //     redirUrl = props.location.state.callBackUrl;
          //   }
          setUserSession(res.data.value);
          setTimeout(() => {
            toast.success("Logged In Successfully");
            window.location.href = from;
            // navigate(redirUrl);
          }, 1000);

          // window.location.reload(false);
        } else {
          setLoader(false);
          setErrorResponse(res.status);
          toast.error("Invalid Username Or Password");
        }
        setLoader(false);
      } else {
        toast.error("Invalid Username Or Password");
      }
    } else {
      setLoader(false);
      setErrorState(errors);
      toast.error("Invalid Username Or Password");
    }
  };
  const guestRegister = async (e) => {
    e.preventDefault();
    setGuestLoader(true);
    const guestData = {
      fullName: "GUEST",
      email: "",
      mobileNumber: "",
      password: "",
      userType: 2,
    };

    const guestRegRes = await registerAction(guestData);
    console.log(guestRegRes);
    if (guestRegRes) {
      if (guestRegRes && guestRegRes.user_id) {
        setUserSession(guestRegRes);

        let redirUrl = "/";
        // if (props) {
        //   console.log(props);
        //   if (props.location.state && props.location.state.callBackUrl) {
        //     redirUrl = props.location.state.callBackUrl;
        //   }
        // }
        setTimeout(() => {
          setGuestLoader(false);
          toast.success("Guest Logged In Successfully");
          // navigate(redirUrl);
          window.location.href = from;
        }, 1000);
      } else {
        toast.error("Guest registration failed!");
        setGuestLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="signUp-wrapper user-form-wrap space pb-5">
        <div className="bgcircle"></div>

        <div className="container">
          <div className="row justify-content-center auth_log_wrapper">
            <div className="col-sm-12 col-lg-7   col-xl-7">
              <form
                action="#"
                className="signUp-form bg-light-theme  pt-5 pb-5  pr-4 pl-4 input-white"
              >
                <h2 className="form-title text-center">{t("login")}</h2>
                <div className="row pb-3">
                  <div className="col-xs-12 col-sm-12">
                    {errorResponse && (
                      <p className="text-danger text-center">{errorResponse}</p>
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                    <InputGroupComponent className="form-group pos_rel login_group_wrapper">
                      <div className="login_icon_email">
                        <i className="fa fa-user"></i>
                        {/* <i className="fa fa-user-alt"></i> */}
                      </div>

                      <InputComponent
                        id="email"
                        name="email"
                        type="email"
                        placeHolder={t("email")}
                        inputClassName="form-control lgn_input"
                        isInvalid={errorState.email ? true : false}
                        onChange={handleInputChange}
                        value={formData.email}
                      />
                      <i className="uil uil-envelope lgn_icon"></i>
                      <InvalidFeedback>{errorState.email}</InvalidFeedback>
                    </InputGroupComponent>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                    <InputGroupComponent className="form-group pos_rel login_group_wrapper ">
                      <div className="login_icon_email">
                        <i className="fa fa-lock"></i>
                      </div>

                      <InputComponent
                        id="password"
                        name="password"
                        type={passwordType}
                        placeHolder={t("password")}
                        inputClassName="form-control"
                        isInvalid={errorState.password ? true : false}
                        onChange={handleInputChange}
                        value={formData.password}
                      />
                      <i
                        className={passwordIconClass}
                        onClick={handlePasswordView}
                      ></i>
                      <i className="uil uil-padlock lgn_icon"></i>
                      <InvalidFeedback>{errorState.password}</InvalidFeedback>
                    </InputGroupComponent>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-lg-6 remember_wrap">
                    <input
                      type="checkbox"
                      name="rememberme"
                      id="rememberme"
                      //   value={formData.termPrivacy}
                      checked={rememberCheckBox}
                      onChange={handleCheckbox}
                    />{" "}
                    <label for="rememberme">{t("remember_me")}</label>
                  </div>
                  <div className="col-sm-12 col-lg-6 forgotpassword_btn">
                    <Link className="" to="/forgot-password">
                      {t("forgot_password")}
                    </Link>
                  </div>
                </div>

                <div className="mb-0 login_btn_wrap">
                  {/* <button
                        className="vs-btn vs-style1  border-0 form_button"
                        type="submit"
                      >
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-shape"></span>{" "}
                        <span className="vs-btn-shape"></span>
                        <span className="vs-btn-text">Create an Account</span>
                      </button> */}
                  <ButtonComponent
                    className="vs-btn vs-style1   form_button"
                    type="submit"
                    text={t("login")}
                    loader={loader}
                    onClick={handleSubmit}
                  />

                  <ButtonComponent
                    className="vs-btn vs-style1   form_button"
                    onClick={guestRegister}
                    // to="/"
                    text={t("continue_as_guest")}
                    loader={guestLoader}
                    // {guestLoader ? (
                    //   <span className="spinner-border" />
                    // ) : (
                    //   "Continue as Guest"
                    // )}
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-12 col-lg-4 col-xl-4">
              <div className="mb-0  text-center login_right_wrap">
                <div className="  login_right_text pt-4 pb-1 pl-5 pr-5 d-none d-lg-block">
                  <h2>{t("new_here")}</h2>
                  <h4>{t("fillup_information")}</h4>
                  <button>
                    {/* <a href="/register">{t("signup")}</a> */}
                    <Link
                      state={{
                        from: from,
                      }}
                      to="/register"
                    >
                      {t("signup")}
                    </Link>
                  </button>
                </div>
                <div className="bottom-links login_right_img pt-3 d-none d-lg-block">
                  <img src={loginImage} alt="" />
                </div>
                <div className="bottom-links pt-3 mt-5 d-block d-lg-none">
                  {t("fillup_information")}{" "}
                  {/* <a href="/register">{t("signup")}</a> */}
                  <Link
                    state={{
                      from: from,
                    }}
                    to="/register"
                  >
                    {t("signup")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
