import { GET_WORKING_HOURS, GET_ERROR } from "../type";
import axios from "axios";
import { useSelector } from "react-redux";
import cookies from "js-cookie";
export const getAllWorkingHours = (language) => async (dispatch) => {
  // const currentLanguageCode = cookies.get("i18next") || "en";
  // console.log(currentLanguageCode);

  // const languages = useSelector((state) => state.allLangages.langages);
  // const projectLanguage = languages.find((l) => l.code === currentLanguageCode);
  const abortCont = new AbortController();
  let isMounted = true;
  const config = {
    headers: {
      lang: language,
    },
  };
  try {
    const { error, isLoading, data } = await axios.get("home", config, {
      signal: abortCont.signal,
    });
    dispatch({
      type: GET_WORKING_HOURS,
      payload: data.data.value.working_hours,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "Error " + e,
    });
  }
};
