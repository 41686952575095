import giftBanner from "../../../assets/img/gift_banner.png";
import preBanner from "../../../assets/img/pre_banner.png";
import customBanner from "../../../assets/img/custom_banner.png";
import useGetAuthData from "../../../hooks/useGetAuthData";
import Preloader from "../../layout/preloader/Preloader";
import React, { useState, useRef } from "react";
import { createRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartAction,
  updateCart,
} from "../../../redux/actions/cartActions";
import { langagesAction } from "../../../redux/actions/langagesAction";

import {
  InputComponent,
  InvalidFeedback,
  InputGroupComponent,
  ButtonComponent,
} from "../../form_element";
import { validateForm } from "../../../services/FormValidation";
import { encryptData } from "../../../helpers/helperFunctions";
import {
  isAuthenticated,
  loginAction,
  registerAction,
  setUserSession,
  getUserSession,
} from "../../../auth";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";

// import { decryptData } from "../../../helpers/helperFunctions";
import { AUTH_SESSION_NAME } from "../../../helpers/config";
import { toast } from "react-toastify";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next, { t } from "i18next";
import PrePackageGiftItemComponent from "./PrePackageGiftItemComponent";
import CustomePackageGiftItemComponent from "./CustomePackageGiftItemComponent";
import Cart from "../cart/Cart";
const Gifts = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from || "/home";
  const [addToCartLoader, setAddToCartLoader] = useState(false);

  const userSession = getUserSession();

  const languages = useSelector((state) => state.allLangages.langages);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const inactiveLanguage = languages.find(
    (l) => l.code !== currentLanguageCode
  );

  const { error, isLoading, data } = useGetAuthData("gifts");
  const inputRef = createRef();
  const [loader, setLoader] = useState(false);
  const [emptydata, setEmptydata] = useState(0);
  const [serviceArray, setServiceArray] = useState([]);
  const [giftType, setGiftType] = useState(1);
  const [giftPackageId, setGiftPackageId] = useState(0);
  const [selectedOption, setSelectedOption] = useState(false);

  const { giftPage, serviceList } = data;
  // console.log(giftPackageId);
  const [formData, setFormData] = useState({
    quantity: 1,
    date: "",
    booking_slot: "",
    type: 1,
    gift_type: giftType,
    serviceId: serviceArray,
    gift_prepackage_id: giftPackageId,
    gift_from: "",
    gift_to: "",
    gift_phone: "",
    gift_cake_massage: "",
    gift_card_mesage: "",
    gift_comments: "",
    gift_address: "",
  });
  const [errorState, setErrorState] = useState({});
  const [errorResponse, setErrorResponse] = useState("");
  const accordElem = useRef(null);

  const [activeTab, setActiveTab] = useState(1);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setGiftType(tab);
    setFormData({
      ...formData,
      gift_type: tab,
    });
  };
  const toggleAccordion = (tab) => {
    setGiftType(tab);
    setFormData({
      ...formData,
      gift_type: tab,
    });
    // setTimeout(() => {
    //   accordElem.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
    // }, 400);
    const toggleAccordion = () => {
      setTimeout(() => {
        window.scrollTo(0, 550);
      }, 100);
    };
  };
  useEffect(() => {
    setFormData({
      ...formData,
      serviceId: serviceArray,
      gift_prepackage_id: giftPackageId,
    });
    // if (!isAuthenticated()) {
    //   navigate("/login", {
    //     replace: true,
    //     state: {
    //       from,
    //     },
    //   });
    // }
    // inputRef && inputRef.current && inputRef.current.focus();
  }, [serviceArray, giftPackageId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const updatePrepackageId = (val) => {
    setGiftPackageId(val);
    console.log(val);
    // setFormData({
    //   ...formData,
    //   gift_prepackage_id: val,
    //   serviceId: serviceArray,
    // });
  };
  const makeItSingleArray = (val) => {
    // console.log(val);
    let serviceIds = [];
    val.forEach((element, index) => {
      element.forEach((elementData, ik) => {
        serviceIds.push(elementData);
      });
    });
    return serviceIds;
  };
  const updateCustompackageId = (val) => {
    setServiceArray(makeItSingleArray(val));
    // console.log(serviceArray);
    // setFormData({
    //   ...formData,
    //   serviceId: makeItSingleArray(val),
    // });
    // setSelectedOption(val);
  };
  const updateEmptyData = (val) => {
    setEmptydata(val);
    setServiceArray([]);
    // setFormData({
    //   ...formData,
    //   serviceId: [],
    // });
  };
  const handleInputChange = (e) => {
    setErrorResponse("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrorState({
      ...errorState,
      [e.target.name]: "",
    });
  };

  const cartItemCountState = useSelector((state) => state.cartItemCountReducer);

  const findCommonElements3 = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item));
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    const validateOptions = {
      gift_from: {
        required: false,
        type: "text",
      },
      gift_to: {
        required: false,
        type: "text",
      },
      gift_cake_massage: {
        required: false,
        type: "text",
      },
      gift_phone: {
        required: false,
        type: "text",
      },
      gift_card_massage: {
        required: false,
        type: "text",
      },
      gift_comments: {
        required: false,
        type: "text",
      },
      gift_address: {
        required: false,
        type: "text",
      },
    };

    const { isValid, errors } = validateForm(validateOptions, formData);
    if (isValid) {
      if (formData.type === 1) {
        if (formData.gift_type === 1) {
          if (
            formData.gift_prepackage_id === "" ||
            formData.gift_prepackage_id === 0
          ) {
            toast.error("Please choose atleast one package");
            return false;
          }
        } else if (formData.gift_type === 2) {
          if (formData.serviceId === null || formData.serviceId == "") {
            toast.error("Please choose atleast one package");
            return false;
          } else {
            if (!findCommonElements3(formData.serviceId, serviceList)) {
              toast.error("Please choose atleast one package");
              return false;
            }
          }
        }
      }
      setLoader(true);
      setAddToCartLoader(true);
      setFormData({
        ...formData,
        // serviceId: serviceIds,
        quantity:
          (cartItemCountState && cartItemCountState[formData.serviceId]) || 1,
      });

      const addToCartResponse = await addToCartAction(formData);
      if (addToCartResponse) {
        setAddToCartLoader(false);
        setLoader(false);
        if (addToCartResponse.value) {
          dispatch(updateCart(addToCartResponse));
          setFormData({
            ...formData,
            serviceId: [],
          });
          setServiceArray([]);
          setFormData({
            ...formData,
            gift_prepackage_id: 0,
          });
          setGiftPackageId(0);
          setFormData({
            ...formData,
            gift_from: "",
            gift_to: "",
            gift_phone: "",
            gift_cake_massage: "",
            gift_card_mesage: "",
            gift_comments: "",
            gift_address: "",
          });
          document.getElementById("gift_form").reset();
          setSelectedOption(!selectedOption);
          toast.success("Add to cart successfully");
        }
      }
      setEmptydata(1);
    } else {
      setLoader(false);
      setErrorState(errors);
    }
  };
  const packageListItems = [
    {
      lang: "en",
      values: [
        {
          title: "Predefined Package",
          id: 1,
        },
        {
          title: "Custom Package",
          id: 2,
        },
      ],
    },
    {
      lang: "ar",
      values: [
        {
          title: "Predefined Package",
          id: 1,
        },
        {
          title: "Custom Package",
          id: 2,
        },
      ],
    },
  ];
  const packageList = packageListItems.filter(
    (item) => item.lang === currentLanguageCode
  );
  // console.log(packageList);
  return (
    <>
      <div className="gift-wrapper  user-form-wrap space pb-5">
        <div className="bgcircle"></div>
        <div className="gift-wrapper-data">
          <div className="container">
            <div className="gift_head">
              <div className="row gift_head_row">
                <div className="col-xs-12 col-md-12 col-lg-6 gift_head_text">
                  <div className="gift_head_left">
                    {isLoading ? (
                      <Preloader />
                    ) : (
                      <>
                        <h1>
                          <span>{giftPage.title}</span>
                        </h1>
                        {/* <h4>{giftPage.subtitle}</h4> */}
                        <p>{giftPage.short_description}</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6 gift_head_image">
                  {isLoading ? (
                    <></>
                  ) : (
                    <>
                      <img
                        src={
                          process.env.REACT_APP_API_BASIC_URL +
                          "/" +
                          giftPage.image
                        }
                        alt=""
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gift_content_data">
        <div className="container ">
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 main_nav">
              <form id="gift_form" key={selectedOption}>
                <div className="accordion_wrapper">
                  <section className="gift_mobile_wrapper hide-l  show-m">
                    <div className="container">
                      <Accordion className="accordionWrapper">
                        <AccordionItem>
                          <AccordionHeader
                            className="prePackageAccordionWrapper wrapper_head mb-0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleAccordion(1);
                            }}
                          >
                            <h3 className={`accordion-title`}>
                              {t("pre_defined")}
                            </h3>
                          </AccordionHeader>

                          <AccordionBody>
                            <div className="accordion-body">
                              <Row>
                                <Col sm="12">
                                  <div className="pre-contents">
                                    <>
                                      {isLoading ? (
                                        <Preloader />
                                      ) : (
                                        <>
                                          <PrePackageGiftItemComponent
                                            giftPackageId={giftPackageId}
                                            updatePrepackageId={
                                              updatePrepackageId
                                            }
                                            prePackages={data.prePackages}
                                          />
                                        </>
                                      )}
                                    </>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionHeader
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleAccordion(2);
                            }}
                            className="customPackageAccordionWrapper wrapper_head nnn"
                            ashik={"sdasdasdas"}
                          >
                            <h3 className={`accordion-title`}>
                              {t("custom_package")}
                            </h3>
                          </AccordionHeader>

                          <AccordionBody>
                            <div className="accordion-body">
                              <div className="custome-contents">
                                <>
                                  {isLoading ? (
                                    <Preloader />
                                  ) : (
                                    <>
                                      <CustomePackageGiftItemComponent
                                        updateCustompackageId={
                                          updateCustompackageId
                                        }
                                        customePackages={data.customPackages}
                                        emptydata={emptydata}
                                        updateEmptyData={updateEmptyData}
                                      />
                                    </>
                                  )}
                                </>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </section>
                </div>
                <div className="tab_wrapper hide-m show-l">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 1 })}
                        onClick={() => {
                          toggle(1);
                        }}
                      >
                        <img
                          className="gift_parent_image"
                          src={preBanner}
                          alt=""
                        />
                        <h4>{t("pre_defined")}</h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          toggle(2);
                        }}
                      >
                        <img
                          className="gift_parent_image"
                          src={customBanner}
                          alt=""
                        />
                        <h4> {t("custom_package")}</h4>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} id="myTabContent">
                    <TabPane tabId={1}>
                      <Row>
                        <Col sm="12">
                          <div className="pre-contents">
                            <>
                              {isLoading ? (
                                <Preloader />
                              ) : (
                                <>
                                  <PrePackageGiftItemComponent
                                    giftPackageId={giftPackageId}
                                    updatePrepackageId={updatePrepackageId}
                                    prePackages={data.prePackages}
                                  />
                                </>
                              )}
                            </>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div className="custome-contents">
                        <>
                          {isLoading ? (
                            <Preloader />
                          ) : (
                            <>
                              <CustomePackageGiftItemComponent
                                updateCustompackageId={updateCustompackageId}
                                customePackages={data.customPackages}
                                emptydata={emptydata}
                                updateEmptyData={updateEmptyData}
                              />
                            </>
                          )}
                        </>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </form>
              <Row className="contact_card_data">
                <div className="col-xs-12 col-sm-12 col-lg-12">
                  <h2>{t("contact")}</h2>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <label>{t("from")}</label>
                    <InputComponent
                      // inputRef={inputRef}
                      id="from"
                      name="gift_from"
                      type="text"
                      // placeHolder={t("from")}
                      inputClassName="form-control"
                      isInvalid={errorState.gift_from ? true : false}
                      onChange={handleInputChange}
                      value={formData.gift_from}
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>{errorState.lastName}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <label>{t("to")}</label>
                    <InputComponent
                      // inputRef={inputRef}
                      id="to"
                      name="gift_to"
                      type="text"
                      // placeHolder={t("to")}
                      inputClassName="form-control"
                      isInvalid={errorState.gift_to ? true : false}
                      onChange={handleInputChange}
                      value={formData.gift_to}
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>{errorState.gift_to}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                  <InputGroupComponent className="form-group pos_rel">
                    <label>{t("gift_phone")}</label>
                    <InputComponent
                      // inputRef={inputRef}
                      id="to"
                      name="gift_phone"
                      type="text"
                      // placeHolder={t("gift_phone")}
                      inputClassName="form-control"
                      isInvalid={errorState.gift_phone ? true : false}
                      onChange={handleInputChange}
                      value={formData.gift_phone}
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>{errorState.gift_phone}</InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <label>{t("gift_cake_message")}</label>
                    <InputComponent
                      // inputRef={inputRef}
                      id="cake_message"
                      name="gift_cake_massage"
                      type="text"
                      // placeHolder={t("gift_cake_message")}
                      inputClassName="form-control"
                      isInvalid={errorState.gift_cake_massage ? true : false}
                      onChange={handleInputChange}
                      value={formData.gift_cake_massage}
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>
                      {errorState.gift_cake_massage}
                    </InvalidFeedback>
                  </InputGroupComponent>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                  <InputGroupComponent className="form-group pos_rel">
                    <label>{t("gift_card_message")}</label>
                    <InputComponent
                      // inputRef={inputRef}
                      id="gift_card_mesage"
                      name="gift_card_mesage"
                      type="text"
                      // placeHolder={t("gift_card_message")}
                      inputClassName="form-control"
                      isInvalid={errorState.gift_card_mesage ? true : false}
                      onChange={handleInputChange}
                      value={formData.gift_card_mesage}
                    />
                    <i className="uil uil-user-circle lgn_icon"></i>
                    <InvalidFeedback>
                      {errorState.gift_card_mesage}
                    </InvalidFeedback>
                  </InputGroupComponent>
                </div>

                <div className="col-sm-12">
                  <div className="form-group pos_rel">
                    <label>{t("gift_comments")} </label>
                    <textarea
                      className="form-control"
                      // placeholder={t("gift_comments")}
                      value={formData.gift_comments}
                      isInvalid={errorState.gift_comments ? true : false}
                      rows="2"
                      name={"gift_comments"}
                      onChange={handleInputChange}
                    />
                    <InvalidFeedback>
                      {errorState.gift_comments}
                    </InvalidFeedback>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group pos_rel">
                    <label>{t("gift_address")} </label>
                    <textarea
                      className="form-control"
                      // placeholder={t("gift_address")}
                      value={formData.gift_address}
                      isInvalid={errorState.gift_address ? true : false}
                      rows="4"
                      name={"gift_address"}
                      onChange={handleInputChange}
                    />
                    <InvalidFeedback>{errorState.gift_address}</InvalidFeedback>
                  </div>
                </div>
                <div className="col-sm-12 address-btns mt-15">
                  <ButtonComponent
                    className="vs-btn vs-style1   form_button"
                    type="submit"
                    text={t("add_to_cart")}
                    loader={loader}
                    onClick={handleSubmit}
                  />
                </div>
              </Row>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 cart_wrapper">
              <Cart />
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gifts;
