import service1 from "../../../assets/img/service/service1.png";
import service2 from "../../../assets/img/service/service2.png";
import service3 from "../../../assets/img/service/service3.png";
import { t } from "i18next";
import { Link } from "react-router-dom";

function HomeAbout(props) {
  return (
    <section className="highlited-service-c-m container">
      <div className="user-select-none feature_item_wrapper  container-no-m">
        <div className="row text-center justify-content-center">
          <div
            className="col-lg-12 col-xl-12 col-sm-12 text-center wow fadeInUp container-m container-no-l"
            data-wow-delay="0.3s"
          >
            <div className="txt-container d-flex">
              <h3 className="font-b featured-title text-center">
                {props.title}
              </h3>
              <div className="content-container widget_title featured-description text-center">
                {props.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
